/**
 * /auth/login
 * Màn hình đăng nhập
 */
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Space,
  Spin,
  Typography,
  notification,
} from "antd";
import React, { useState, useEffect } from "react";
import banner_footer from "assets/img/login-pages/footer.png";
import logo from "assets/img/login-pages/logo-viete-vuong-2-20210913030905 3.png";
import { AccountType, GetAccountSuccess, PayloadType } from "type";
import { useDispatch } from "react-redux";
import { accountLogin, forgotPass, resetPass } from "features/accountSlice";
import { useHistory, useLocation, useParams } from "react-router";
import * as yup from "yup";
import moment from "moment";
import _, { values } from "lodash";
import { useForm } from "antd/lib/form/Form";
import queryString from "query-string";
const { Title, Text, Link } = Typography;
interface FormAccountType {
  email: string;
  password: string;
  remember: boolean;
}
const ResetPassword = () => {
  const [form] = useForm();
  const location = useLocation();
  const [isLoadingforgotPass, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    localStorage.clear();
  }, []);
  let forgotPassSchema = yup.object().shape({
    password: yup
      .string()
      .required("Mật khẩu mới không được để trống!")
      .test(
        "password",
        "Mật khẩu phải dài ít nhất 8 kí tự bao gồm chữ thường, chữ hoa , số và kí tự đặc biệt!",
        (password) => {
          const reg =
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/g;
          return reg.test(password);
        }
      ),
    newPassword: yup
      .string()
      .test("validate-newpass", "Mật khẩu không khớp !", (newPass) => {
        return newPass === form.getFieldValue("password");
      }),
  });
  const yupSync = {
    async validator({ field }: any, value: any) {
      await forgotPassSchema.validateSyncAt(field, { [field]: value });
    },
  };
  function handleChangePassworld(values: FormAccountType) {
    setIsLoading(true);
    const payload: PayloadType = {
      query: location.search,
      body: {
        password: _.get(values, "password", ""),
      },
      callback: {
        success(values: GetAccountSuccess) {
          setIsLoading(false);
          notification.success({
            message: "Thành công!",
            description: "Thay đổi mật khẩu thành công!",
          });
          history.push("/admin/login");
        },
        failed() {
          notification.error({
            message: "Lỗi xác thực !",
          });
          setIsLoading(false);
        },
      },
    };
    dispatch(resetPass(payload));
  }
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "#F0F2F5",
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          zIndex: 1,
          width: "100%",
          height: "100%",
        }}
      >
        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            backgroundImage: `url(${banner_footer})`,
            backgroundPosition: "left bottom",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            width: "100vw",
            height: "30vh",
            minHeight: 300,
          }}
        />
      </div>
      <div
        style={{
          position: "absolute",
          zIndex: 2,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Row>
          <Col span={24}>
            <Space direction="vertical" align="center" size={"small"}>
              <img
                src={logo}
                alt="logo"
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
              <Title
                style={{
                  textTransform: "uppercase",
                  color: "#1890FF",
                }}
              >
                Tiếng anh đồng hành
              </Title>
              <Card
                size="default"
                style={{
                  width: 430,
                  paddingTop: 0,
                }}
                bordered={true}
              >
                <Form
                  form={form}
                  name="normal_login"
                  className="login-form"
                  size={"large"}
                  onFinish={(values) => {
                    handleChangePassworld(values);
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: 16,
                    }}
                  >
                    <Space align="center">
                      <Text type="secondary" style={{ marginTop: 0 }}>
                        Vui lòng nhập mật khẩu mới
                      </Text>
                    </Space>
                  </div>
                  <Form.Item name="password" rules={[yupSync]}>
                    <Input.Password
                      // prefix={<LockOutlined className="site-form-item-icon" />}
                      type="password"
                      placeholder="Nhập mật khẩu"
                      visibilityToggle={{
                        visible: false,
                      }}
                    />
                  </Form.Item>
                  <Form.Item name="newPassword" rules={[yupSync]}>
                    <Input.Password
                      // prefix={<LockOutlined className="site-form-item-icon" />}
                      type="password"
                      placeholder="Nhập mật khẩu"
                      visibilityToggle={{
                        visible: false,
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                      style={{
                        width: "100%",
                      }}
                      loading={isLoadingforgotPass}
                    >
                      Xác nhận thay đổi
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
              {/* <Card style={{
                width: 430
              }} >
                <Space direction="vertical" size={"middle"} >
                  <Text type="secondary" style={{ textAlign: "center" }}>
                    Vui lòng đăng nhập để sử dụng hệ thống
                  </Text>
                  <Input
                    placeholder="Nhập tài khoản"
                    allowClear
                    onChange={() => {}}
                  />
                  <Input.Password placeholder="Nhập mật khẩu" />
                  <div>
                    <Checkbox onChange={() => {}}>Lưu mật khẩu</Checkbox>
                    <Link href="#">Quên mật khẩu khẩu ?</Link>
                  </div>
                  <div style={{
                    display: "flex",
                    justifyContent: 'space-between'
                  }}>
                    <Button type="default" size="large" style={{width: 150}}  >Hủy bỏ</Button>
                    <Button type="primary" size="large" style={{width: 150}}>Đăng nhập</Button>
                  </div>
                </Space>
              </Card> */}
            </Space>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ResetPassword;
