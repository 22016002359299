/**
 * Màn hình thêm mới + cập nhật trường
 */
import HeaderbackTitle from "pages/components/HeaderBackTitle";
import { Dispatch, SetStateAction, useState, useEffect, memo } from "react";
import {
  Layout,
  Button,
  Form,
  Space,
  Select,
  notification,
  Input,
} from "antd";
import * as yup from "yup";
import TextArea from "antd/lib/input/TextArea";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  AwardType,
  DistrictType,
  PayloadType,
  ProviceType,
  SelectOptionType,
} from "type";
import queryString from "query-string";
import _ from "lodash";
import { getProvinces } from "features/provinceSlice";
import { getDistricts } from "features/districtSlice";
import { getAwards } from "features/awardSlice";
import { createSchool, getSchool, setFilter, setQuery, updateSchool } from "features/schoolSlice";
import { checkAnyCheckObject } from "utils";
import { useHistory, useParams } from "react-router";
const { Content } = Layout;
interface CUFormStateType {
  type: "create" | "update" | "list";
  isOpen: boolean;
  tab: "school" | "classroom";
  idUpdate?: string;
}
interface SchoolFormType {
  form: CUFormStateType;
  setForm: Dispatch<SetStateAction<CUFormStateType>>;
}
interface FormValueType {
  name: string;
  province: string;
  district: string;
  award: string;
  address: string;
  representative: string;
  email: string;
  phoneNumber: string;
  notes: string;
}
const { useForm } = Form;
interface FormType {
  name: "create" | "update";
  id: string | null;
}
function getFormType(history: any, param: any): FormType {
  if (history?.location?.pathname === "/admin/create-school-organization") {
    return {
      name: "create",
      id: null,
    };
  }
  if (history?.location?.pathname.includes("/admin/update-school-organization")) {
    const { id } = param;
    return {
      name: "update",
      id,
    };
  }
  return {
    name: "create",
    id: null,
  };
}
function CUSchool() {
  const history = useHistory();
  const dispatch = useDispatch();
  const param = useParams();
  const [form] = useState<FormType>(getFormType(history, param))
  const [formCreate] = useForm();
  const [formUpdate] = useForm();
  const { queryProvince } = useSelector((state: any) => state?.provinceReducer);
  const { queryDistrict } = useSelector((state: any) => state?.districtReducer);
  const { queryAward } = useSelector((state: any) => state?.awardReducer);
  const { school, query, filters } = useSelector((state: any) => state?.schoolReducer);
  const [provinceOptions, setProvinceOptions] = useState<SelectOptionType[]>(
    []
  );
  const [districtOptions, setDistrictOptions] = useState<SelectOptionType[]>(
    []
  );
  const [awardOptions, setAwardOptions] = useState<SelectOptionType[]>([]);

  const [provinceOptionV, setProvinceOptionV] = useState<SelectOptionType>();
  const [districtOptionV, setDistrictOptionV] = useState<SelectOptionType>();
  let accountSchema = yup.object().shape({
    name: yup.string().required("Tên trường không được để trống!"),
    province: yup.string().required("Vui lòng chọn tỉnh!"),
    district: yup.string().required("Vui lòng chọn quận/huyện!"),
    award: yup.string().required("Vui lòng chọn xã/phường!"),
    address: yup.string().required("Địa chỉ không được để trống!"),
    notes: yup.string().required("Ghi chú không được để trống!"),
    representative: yup
      .string()
      .required("Người đại diện không được để trống!"),
    email: yup
      .string()
      .required("Email người đại diện không được để trống!")
      .email("Email không đúng định dạng!"),
    phoneNumber: yup
      .string()
      .length(10, "Số điện thoại bao gồm 10 số!")
      .required("Số điện thoại người đại diện không được để trống!")
      .matches(
        /0[1-9][0-9]{8}/g,
        "Vui lòng nhập đúng định dạng số điện thoại!"
      ),
  });
  const yupSync = {
    async validator({ field }: any, value: any) {
      await accountSchema.validateSyncAt(field, { [field]: value });
    },
  };
  //Lấy danh sách địa chỉ - tỉnh/quận/xã
  function handleGetProvinceAddress() {
    const payload: PayloadType = {
      query: queryString.stringify(queryProvince),
      callback: {
        success(values) {
          if (_.isArray(values) && values.length > 0) {
            setProvinceOptions(
              values.map((item: ProviceType) => {
                return {
                  label: _.get(item, "province_name", ""),
                  value: _.get(item, "province_id", ""),
                };
              })
            );
          }
        },
        failed(errorMessage) {
          notification.error({
            message: "Lấy danh sách tỉnh thất bại!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getProvinces(payload));
  }
  function handleGetDistrictAddress() {
    const payload: PayloadType = {
      query: queryString.stringify({
        ...queryDistrict,
        province_id: _.get(provinceOptionV, "value", ""),
      }),
      callback: {
        success(values) {
          if (_.isArray(values) && values.length > 0) {
            setDistrictOptions(
              values.map((item: DistrictType) => {
                return {
                  label: _.get(item, "district_name", ""),
                  value: _.get(item, "district_id", ""),
                };
              })
            );
          }
        },
        failed(errorMessage) {
          notification.error({
            message: "Lấy danh sách Quận/Huyện thất bại!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getDistricts(payload));
  }
  function handleGetAwardAddress() {
    const payload: PayloadType = {
      query: queryString.stringify({
        ...queryAward,
        district_id: _.get(districtOptionV, "value", ""),
      }),
      callback: {
        success(values) {
          if (_.isArray(values) && values.length > 0) {
            // setAwardOptionV({
            //   label: _.get(values[0], "ward_name", ""),
            //   value: _.get(values[0], "ward_id", ""),
            // });
            setAwardOptions(
              values.map((item: AwardType) => {
                return {
                  label: _.get(item, "ward_name", ""),
                  value: _.get(item, "ward_id", ""),
                };
              })
            );
          }
        },
        failed(errorMessage) {
          notification.error({
            message: "Lấy danh sách Xã/Phường thất bại!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getAwards(payload));
  }
  //Load lại query của danh sách 
  function resetQuery() {
    dispatch(setQuery({
      ...query,
      page: 1,
      limit: 10,
    }));
    dispatch(setFilter({
      ...filters,
      page: 1,
      limit: 10,
    }));
  }
  useEffect(() => {
    handleGetProvinceAddress();
  }, [queryProvince]);
  useEffect(() => {
    if (!!provinceOptionV) handleGetDistrictAddress();
  }, [provinceOptionV]);
  useEffect(() => {
    if (!!districtOptionV) handleGetAwardAddress();
  }, [districtOptionV]);
  const getNameByID = (list: SelectOptionType[], id: string) => {
    const result = list.find((item: SelectOptionType) => item.value === id);
    return _.get(result, "label", id);
  };
  const handleCreateSchool = (values: FormValueType) => {
    const {
      name,
      province,
      district,
      award,
      address,
      representative,
      email,
      phoneNumber,
      notes
    } = values;
    const provinceName = getNameByID(provinceOptions, province);
    const districtName = getNameByID(districtOptions, district);
    const awardName = getNameByID(awardOptions, award);
    const payload: PayloadType = {
      body: {
        name,
        province: provinceName,
        district: districtName,
        award: awardName,
        address,
        representative,
        email,
        phoneNumber,
        notes
      },
      callback: {
        success(values) {
          notification.success({
            message: "Tạo mới trường học thành công!",
          });
          resetQuery();
          history.push("/admin/organization");
          formCreate.resetFields([
            name,
            province,
            district,
            award,
            address,
            representative,
            email,
            phoneNumber,
          ]);
        },
        failed(errorMessage) {
          notification.error({
            message: "Tạo mới trường học thất bại!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(createSchool(payload));
  };
  //Gán dữ liệu cho form update
  useEffect(() => {
    if (!!school) {
      formUpdate.setFieldValue("name", school.name);
      formUpdate.setFieldValue("province", school.province);
      formUpdate.setFieldValue("district", school.district);
      formUpdate.setFieldValue("award", school.award);
      formUpdate.setFieldValue("address", school.address);
      formUpdate.setFieldValue("representative", school.representative);
      formUpdate.setFieldValue("email", school.email);
      formUpdate.setFieldValue("phoneNumber", school.phoneNumber);
      formUpdate.setFieldValue("notes", school.notes);
    }
  }, [school]);

  /**
   * 1. Lấy thông tin trường học
   * 2. Lấy danh sách các huyện dựa vào tên tỉnh
   * 3. Lấy danh sách các xã dựa vào tên huyện
   */
  function handleGetSchool() {
    const payload: PayloadType = {
      params: form?.id ?? "",
      callback: {
        success(values: any) {
          if (!!values?.province) {
            const payloadProvince: PayloadType = {
              query: queryString.stringify({
                province_name: values.province,
              }),
              callback: {
                success(provinces) {
                  const provinceID =
                    _.isArray(provinces) && provinces.length > 0
                      ? _.get(provinces[0], "province_id", "")
                      : "";
                  if (!!provinceID) {
                    // Lấy danh sách của huyện trong tỉnh đó.
                    const districtsPayload: PayloadType = {
                      query: queryString.stringify({
                        province_id: provinceID,
                      }),
                      callback: {
                        success(districts) {
                          const optionsDistrict = districts.map(
                            (item: DistrictType) => {
                              return {
                                label: item.district_name,
                                value: item.district_id,
                              };
                            }
                          );
                          const districtName = _.get(values, "district", "");
                          const district = optionsDistrict.find(
                            (districtOption: SelectOptionType) => {
                              return districtOption.label === districtName;
                            }
                          );
                          setDistrictOptions(optionsDistrict);
                          // Lấy danh sách xã
                          const districtID = !!district ? district.value : "";
                          if (!!districtID) {
                            const awardPayload: PayloadType = {
                              query: queryString.stringify({
                                district_id: districtID,
                              }),
                              callback: {
                                success(awards) {
                                  const optionsAward = awards.map(
                                    (item: AwardType) => {
                                      return {
                                        label: item.ward_name,
                                        value: item.ward_id,
                                      };
                                    }
                                  );
                                  setAwardOptions(optionsAward);
                                },
                                failed(errorMessage) { },
                              },
                            };
                            dispatch(getAwards(awardPayload));
                          }
                        },
                        failed(errorMessage) { },
                      },
                    };
                    dispatch(getDistricts(districtsPayload));
                  }
                },
                failed(errorMessage) { },
              },
            };
            dispatch(getProvinces(payloadProvince));
          }
        },
        failed(errorMessage) {
          notification.error({
            message: "Lỗi lấy thông tin trường học!",
          });
        },
      },
    };
    dispatch(getSchool(payload));
  }
  useEffect(() => {
    if (form?.name === "update") {
      handleGetSchool();
    }
  }, []);

  const handleUpdateSchool = (values: FormValueType) => {
    const newSchool = {
      name: _.get(values, "name", ""),
      province: _.get(values, "province", ""),
      district: _.get(values, "district", ""),
      award: _.get(values, "award", ""),
      address: _.get(values, "address", ""),
      representative: _.get(values, "representative", ""),
      email: _.get(values, "email", ""),
      phoneNumber: _.get(values, "phoneNumber", ""),
      notes: _.get(values, "notes", ""),
    };
    const oldSchool = {
      name: _.get(school, "name", ""),
      province: _.get(school, "province", ""),
      district: _.get(school, "district", ""),
      award: _.get(school, "award", ""),
      address: _.get(school, "address", ""),
      representative: _.get(school, "representative", ""),
      email: _.get(school, "email", ""),
      phoneNumber: _.get(school, "phoneNumber", ""),
      notes: _.get(school, "notes", ""),
    };
    if (checkAnyCheckObject(oldSchool, newSchool)) {
      const provinceName = getNameByID(provinceOptions, newSchool.province);
      const districtName = getNameByID(districtOptions, newSchool.district);
      const awardName = getNameByID(awardOptions, newSchool.award);
      const payload: PayloadType = {
        params: _.get(school, "id", ""),
        body: {
          ...newSchool,
          province: provinceName,
          district: districtName,
          award: awardName,
        },
        callback: {
          success(values) {
            notification.success({
              message: "Cập nhật trường học thành công!",
            });
            resetQuery();
            history.push("/admin/organization");
            formCreate.resetFields();
          },
          failed(errorMessage) {
            notification.error({
              message: "Cập nhật trường học thất bại!",
              description: errorMessage,
            });
          },
        },
      };
      dispatch(updateSchool(payload));
    } else {
      history.push("/admin/organization");
    }
  };
  return (
    <Content className="custom-layout-content">
      <HeaderbackTitle
        title={
          form.name === "create" ? "Thêm mới trường" : "Sửa thông tin trường"
        }
        handleBack={() => {
          history.push("/admin/organization");
        }}
        link="/admin/organization"
      />
      <Form
        form={form.name === "update" ? formUpdate : formCreate}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 8 }}
        onFinish={(values) => {
          if (form.name === "create") handleCreateSchool(values);
          else {
            handleUpdateSchool(values);
          }
        }}
        onFinishFailed={() => { }}
        autoComplete="off"
        layout="horizontal"
        style={{
          marginTop: 64,
        }}
        onChange={(e) => { }}
      >
        <Form.Item
          label="Tên trường"
          name="name"
          required
          labelAlign="right"
          rules={[yupSync]}
        >
          <Input placeholder="Nhập tên trường" allowClear />
        </Form.Item>
        <Form.Item
          label="Tỉnh"
          name="province"
          required
          labelAlign="right"
          rules={[yupSync]}
          initialValue={
            form.name === "update" ? provinceOptionV?.value : undefined
          }
        >
          <Select
            filterOption={(input, option) => {
              const label: string = option?.label ?? "";
              return label.toLowerCase().includes(input.toLowerCase());
            }}
            showSearch
            options={provinceOptions}
            placeholder="Chọn tỉnh"
            defaultValue={
              form.name === "update" ? provinceOptionV?.value : undefined
            }
            allowClear
            onChange={(values, option: any) => {
              setProvinceOptionV(option);
              formCreate.setFieldValue("district", null);
              formCreate.setFieldValue("award", null);
              formUpdate.setFieldValue("district", null);
              formUpdate.setFieldValue("award", null);
            }}
          />
        </Form.Item>
        <Form.Item
          label="Quận/Huyện"
          name="district"
          required
          labelAlign="right"
          rules={[yupSync]}
        >
          <Select
            showSearch
            filterOption={(input, option) => {
              const label: string = option?.label ?? "";
              return label.toLowerCase().includes(input.toLowerCase());
            }}
            onChange={(values, option: any) => {
              setDistrictOptionV(option);
              formCreate.setFieldValue("award", null);
              formUpdate.setFieldValue("award", null);
            }}
            options={districtOptions}
            placeholder="Chọn quận/huyện"
            allowClear
          />
        </Form.Item>
        <Form.Item
          label="Xã/Phường"
          name="award"
          required
          labelAlign="right"
          rules={[yupSync]}
        >
          <Select
            showSearch
            filterOption={(input, option) => {
              const label: string = option?.label ?? "";
              return label.toLowerCase().includes(input.toLowerCase());
            }}
            options={awardOptions}
            placeholder="Chọn xã/phường"
            allowClear
          />
        </Form.Item>
        <Form.Item
          label="Địa chỉ"
          name="address"
          required
          labelAlign="right"
          rules={[yupSync]}
        >
          <TextArea placeholder="Nhập vào địa chỉ" allowClear />
        </Form.Item>
        <Form.Item
          label="Người đại diện"
          name="representative"
          required
          labelAlign="right"
          rules={[yupSync]}
        >
          <Input placeholder="Nhập vào người đại diện" allowClear />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          required
          labelAlign="right"
          rules={[yupSync]}
        >
          <Input placeholder="Nhập vào email" allowClear />
        </Form.Item>
        <Form.Item
          label="Số điện thoại"
          name="phoneNumber"
          required
          labelAlign="right"
          rules={[yupSync]}
        >
          <Input placeholder="Nhập vào số điện thoại" allowClear />
        </Form.Item>
        <Form.Item
          label="Ghi chú"
          name="notes"
          required
          labelAlign="right"
          rules={[yupSync]}
        >
          <TextArea placeholder="Nhập vào ghi chú" allowClear />
        </Form.Item>
        <Form.Item
          wrapperCol={{
            offset: 8,
          }}
        >
          <Space>
            <Button
              type="default"
              size="middle"
              htmlType="submit"
              onClick={() => {
                history.push("/admin/organization");
              }}
            >
              Hủy
            </Button>
            <Button type="primary" size="middle" htmlType="submit">
              {form.name === "create" ? "Thêm mới" : "Lưu lại"}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Content>
  );
}

export default memo(CUSchool);
