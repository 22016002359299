import _ from "lodash";
import { SelectOptionType } from "type";

/**
 * Xoá các key có trong deleteKeys nếu values[key] là giá trị null
 * Thay thế các key có trong replaceKeys nếu values[key] là giá trị falsely
 * @param values
 * @param deleteKeys
 * @param replaceKeys
 * @returns Object
 */
export const checkBodyRequest = (
  values: Object,
  deleteKeys: string[],
  replaceKeys: string[]
) => {
  const tmp: any = { ...values };
  deleteKeys.map((key: string) => {
    if (!tmp[`${key}`]) {
      delete tmp[`${key}`];
    }
  });
  replaceKeys.map((key: string) => {
    if (tmp[`${key}`] !== null) {
      tmp[`${key}`] = null;
    }
  });
  return tmp;
};

/**
 * Kiểm tra xem có sự thay đổi nào trong các object hay không?
 * @param oldObject
 * @param newObject
 * @returns true nếu khác nhau, false nếu giống nhau
 */
const arrayEquals = (a: string[], b: string[]) => {
  if (a === b) return true;
  if (a == null || b == null) return true;
  if (a.length !== b.length) return false;
  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};
export const checkAnyCheckObject = (oldObject: any, newObject: any) => {
  const newObjectKey = Object.keys(newObject);
  return newObjectKey.some((key: string) => {
    if (Array.isArray(oldObject[`${key}`])) {
      return !arrayEquals(oldObject[`${key}`], newObject[`${key}`]);
    }
    return oldObject[`${key}`] !== newObject[`${key}`];
  });
};

/**
 * Phân quyền trên sidebar
 * @param currentAccountLogin
 * @param prop
 * @returns
 */
export const checkRole = (currentAccountLogin: any, prop: any) => {
  // return true;
  let validRole = false;
  const roles = _.get(prop, "roles", []);
  const permissions = _.get(currentAccountLogin, "roleId.permission", []);
  permissions.every((item: any) => {
    if (roles.indexOf(item) !== -1 || _.isEmpty(prop.roles)) {
      validRole = true;
      return false;
    }
    return true;
  });
  return validRole;
};
/**
 * Kiểm tra quyền
 * @param currentAccountLogin
 * @param prop
 * @returns
 */
export const checkIncludesRole = (
  currentAccountLogin: any,
  roles: string[]
) => {
  const permissions: string[] = _.get(
    currentAccountLogin,
    "roleId.permission",
    []
  );

  return roles.every((role) => permissions.includes(role));
};
