/* eslint-disable react-hooks/exhaustive-deps */
/**
 * Màn hình quản lý khóa học
 *
 */
import _ from "lodash";
import { memo, useState, useEffect } from "react";
import {
  Button,
  Layout,
  Popconfirm,
  Table,
  Typography,
  notification,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { Space } from "antd";
import CUManage from "pages/Manage/components/CUManage";
import { styled } from "styled-components";
import { useDispatch } from "react-redux";
import { deleteCourse, getCourses, setQuery } from "features/courseSlice";
import { PayloadType } from "type";
import { useSelector } from "react-redux";

import { Link, useHistory } from "react-router-dom";
import queryString from "query-string";
const { Title } = Typography;
const { Content } = Layout;
interface DataType {
  key: number;
  name: string;
  gender: string;
  dateOfBirth: string;
  email: string;
  phoneNumber: string;
  address: string;
  roleId: string;
}
const CustomPopconfirm = styled(Popconfirm)`
  .ant-popover-inner {
    max-width: 240px !important;
    background-color: red;
  }
`;
function Course() {
  const [displayCU, setDisplayCU] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const { query, courses } = useSelector((state: any) => state?.courseReducer);
  const history = useHistory();
  const dispatch = useDispatch();

  //Định nghĩa cột trong bảng
  const columns: ColumnsType<DataType> = [
    {
      title: "Tên đầy đủ",
      width: 50,
      dataIndex: "fullname",
      key: "fullname",
      align: "center",
    },
    {
      title: "Tên viết tắt",
      width: 50,
      dataIndex: "shortname",
      key: "shortname",
      align: "center",
    },
    {
      title: "Hiển thị",
      width: 50,
      dataIndex: "isVisible",
      key: "isVisible",
      align: "center",
      render(value, record, index) {
        return !!value ? "Bật" : "Tắt";
      },
    },
    {
      title: "Mô tả",
      width: 300,
      dataIndex: "summary",
      key: "summary",
    },
    {
      title: "Hành động",
      width: 50,
      dataIndex: "action",
      key: "action",
      fixed: "right",
      align: "center",
      render: (_, record: any) => (
        <Space size="middle">
          <Link
            to={"/admin/update-course/" + record?.id ?? ""}
            onClick={() => {
              setIsUpdate(true);
            }}
          >
            <a href="/admin/update-course/">Sửa</a>
          </Link>
          <CustomPopconfirm
            title={`Bạn có chắc chắn muốn xóa khóa học ${
              record?.shortname ?? ""
            }  không ?`}
            okText={"Có"}
            cancelText={"Không"}
            onConfirm={() => {
              const id = record?.id ?? "";

              if (!!id) {
                const payload: PayloadType = {
                  params: id,
                  callback: {
                    success: () => {
                      notification.success({
                        message:
                          "Xoá khoá học " +
                          (record?.name ?? "") +
                          " thành công!",
                      });
                      handleGetCourses();
                    },
                    failed(errorMessage) {
                      notification.error({
                        message:
                          "Xoá khóa học " + (record?.name ?? "") + " thất bại!",
                        description: errorMessage,
                      });
                    },
                  },
                };
                dispatch(deleteCourse(payload));
              }
            }}
          >
            <a href="##">Xoá</a>
          </CustomPopconfirm>
        </Space>
      ),
    },
  ];
  //Lấy danh sách các khóa học
  const handleGetCourses = () => {
    const payload: PayloadType = {
      query: queryString.stringify(query, {
        skipEmptyString: true,
        skipNull: true,
      }),
      callback: {
        success(values) {},
        failed(errorMessage) {},
      },
    };
    dispatch(getCourses(payload));
  };
  useEffect(() => {
    handleGetCourses();
  }, [query]);
  useEffect(() => {
    return () => {
      const pathname = history.location.pathname;
      if (pathname !== "/admin/create-course" && pathname !== "/admin/course") {
        dispatch(
          setQuery({
            page: 1,
            limit: 10,
          })
        );
      }
    };
  }, []);
  //Hiển thị màn hình thêm và cập nhật tài khoản
  if (displayCU) {
    return (
      <CUManage
        isUpdate={isUpdate}
        setDisplayCU={(e: any) => {
          setDisplayCU(e);
        }}
      />
    );
  }
  return (
    <Content className="custom-layout-content">
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
        size={"large"}
      >
        <Title level={4}>Quản lý khóa học</Title>
        <div className="space-between">
          <Title level={5}>Danh sách</Title>
          <Link
            to={"create-course"}
            style={{
              color: "white",
            }}
            onClick={() => {
              setIsUpdate(false);
            }}
          >
            <Button size="middle" type="primary" icon={<PlusOutlined />}>
              Thêm mới
            </Button>
          </Link>
        </div>
        <Table
          columns={columns}
          dataSource={
            Array.isArray(courses?.data ?? "")
              ? courses.data.filter((item: any) => {
                  return _.get(item, "status", "") !== 3;
                })
              : []
          }
          pagination={{
            total: courses.totalResults,
            showTotal: (total, range) => {
              return `Có tất cả ${total} bản ghi`;
            },
            showSizeChanger: true,
            current: _.get(query, "page", 1),
            onShowSizeChange(current, size) {
              if (query.limit !== size)
                dispatch(
                  setQuery({
                    ...query,
                    page: 1,
                    limit: size,
                  })
                );
            },
            onChange(page, pageSize) {
              if (query.page !== page)
                dispatch(
                  setQuery({
                    ...query,
                    page,
                  })
                );
            },
          }}
          scroll={{ x: 1300, y: 500 }}
        />
      </Space>
    </Content>
  );
}

export default memo(Course);
