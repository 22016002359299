/* eslint-disable react-hooks/exhaustive-deps */
/**
 * Tab Nhóm khác
 */
import React, { useState, useRef, useEffect, Fragment } from "react";
import {
  Button,
  DatePicker,
  Select,
  Table,
  Typography,
  notification,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import type { InputRef } from "antd";
import { Input, Space } from "antd";
import type { ColumnType } from "antd/es/table";
import type { FilterConfirmProps } from "antd/es/table/interface";
import moment from "moment";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  AwardType,
  ClassroomType,
  CourseType,
  DistrictType,
  PayloadType,
  ProviceType,
  SelectOptionType,
} from "type";
import {
  getAccounts,
  getClassroomAccounts,
  getClassroomAccountsSuccess,
  resetFilter,
  setAccount,
  setQuery,
} from "features/accountSlice";
import { setFilter } from "features/userScreenSlice";

import queryString from "query-string";
import _ from "lodash";
import { useHistory, useLocation } from "react-router";
import { getCourses } from "features/courseSlice";
import { checkIncludesRole, checkRole } from "utils";
import Filters from "pages/User/AllUser/Filters";
import { getSchools } from "features/schoolSlice";
import { getAwards } from "features/awardSlice";
import { getDistricts } from "features/districtSlice";
import { getClassrooms } from "features/classroomSlice";
import { DEFAULT_PROVICE_FILTER_ID } from "const";
import { getProvinces } from "features/provinceSlice";
const Highlighter = require("react-highlight-words");
const { Title } = Typography;
interface DataType {
  code: string;
  username: string;
  fullname: string;
  gender: string;
  personage: string;
  diamond: string;
  courseid: string;
  membership: string;
  parent_code: string;
  paid_expired: string;
  confirmed: string;
  avatar: string;
  createdAt: string;
  updatedAt: string;
  email: string;
  belongToSchool: boolean;
  id: string;
}
type DataIndex = keyof DataType;
interface IOtherGroup {
  tabActive: string;
}
const { Text } = Typography;
function AllUser({ tabActive }: IOtherGroup) {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [tableKey, setTableKey] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const searchInput = useRef<InputRef>(null);
  const history = useHistory();
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const searchParams = useQuery();
  const dispatch = useDispatch();
  const { isTeacher, isAdmin, query, currentAccountLogin, classroomsAccounts } =
    useSelector((state: any) => state.accountReducer);
  const [classOptions, setClassOptions] = useState<SelectOptionType[]>();
  const { queryProvince } = useSelector((state: any) => state?.provinceReducer);
  const { queryDistrict } = useSelector((state: any) => state?.districtReducer);
  const [courseOptions, setCourseOptions] = useState([]);
  const [provinceOptions, setProvinceOptions] = useState<SelectOptionType[]>(
    []
  );
  const [districtOptions, setDistrictOptions] = useState<SelectOptionType[]>(
    []
  );
  const [classSearchVal, setClassSearchVal] = useState<string>("");

  const [awardOptions, setAwardOptions] = useState<SelectOptionType[]>([]);
  const [schoolOptions, setSchoolOptions] = useState<SelectOptionType[]>([]);
  const [defaultFilter, setDefaultFilter] = useState({});
  const { filters } = useSelector((state: any) => state.userScreenReducer);
  const filterSelect = (input: string, option: any) => {
    return option?.label?.toLowerCase().includes(input.toLowerCase());
  };

  // Lấy danh sách lớp học
  function handleGetClassroom() {
    const query = queryString.stringify(
      isTeacher
        ? {
            teacherId: _.get(currentAccountLogin, "id", ""),
            populate: "schoolId,teacherId",
            className: classSearchVal,
          }
        : {
            schoolId: _.get(filters, "school.value", ""),
            className: classSearchVal,
            year: filters?.year 

          },
      {
        skipEmptyString: true,
        skipNull: true,
      }
    );
    const payload: PayloadType = {
      query,
      skipUpdateReducer: false,
      callback: {
        success(values) {
          const data = _.get(values, "data", []);
          setClassOptions(
            data.map((item: ClassroomType) => ({
              label: item.className,
              value: item.id,
            }))
          );
          if (isTeacher && _.get(data, "length", "") > 0) {
            setDefaultFilter({
              ...filters,
              province: {
                label: _.get(data, "[0].schoolId.province"),
                value: null,
              },
              district: {
                label: _.get(data, "[0].schoolId.district"),
                value: null,
              },
              award: {
                label: _.get(data, "[0].schoolId.award"),
                value: null,
              },
              school: {
                label: _.get(data, "[0].schoolId.name"),
                value: null,
              },
            });
          }
        },
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách lớp học!",
            description: errorMessage,
          });
        },
      },
    };

    if (isTeacher || _.get(filters, "school.value", "")||  filters?.year)
      dispatch(getClassrooms(payload));
  }

  //Lấy danh sách tỉnh/thành phố
  function handleGetProvinceAddress() {
    const payload: PayloadType = {
      query: queryString.stringify(queryProvince),
      skipUpdateReducer: true,
      callback: {
        success(values) {
          if (_.isArray(values) && values.length > 0) {
            setProvinceOptions(
              values.map((item: ProviceType) => {
                return {
                  label: _.get(item, "province_name", ""),
                  value: _.get(item, "province_id", ""),
                };
              })
            );
            if (!filters?.province) {
              const defaultValue = _.find(values, {
                province_id: DEFAULT_PROVICE_FILTER_ID,
              });
              dispatch(
                setFilter({
                  province: {
                    label: _.get(defaultValue, "province_name", ""),
                    value: _.get(defaultValue, "province_id", ""),
                  },
                  province_id: DEFAULT_PROVICE_FILTER_ID,
                })
              );
            }
          }
        },
        failed(errorMessage) {
          notification.error({
            message: "Lấy danh sách tỉnh thất bại!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getProvinces(payload));
  }

  function handleGetDistrictAddress() {
    const province_id = _.get(filters, "province_id", "");
    const query = queryString.stringify({
      ...queryDistrict,
      province_id: province_id,
    });
    const payload: PayloadType = {
      query,
      skipUpdateReducer: true,
      callback: {
        success(values) {
          if (_.isArray(values) && values.length > 0) {
            setDistrictOptions(
              values.map((item: DistrictType) => {
                return {
                  label: _.get(item, "district_name", ""),
                  value: _.get(item, "district_id", ""),
                };
              })
            );
          }
        },
        failed(errorMessage) {
          notification.error({
            message: "Lấy danh sách Quận/Huyện thất bại!",
            description: errorMessage,
          });
        },
      },
    };
    if (province_id) dispatch(getDistricts(payload));
  }

  function handleGetAwardAddress() {
    const district_id = _.get(filters, "district_id", "");
    const payload: PayloadType = {
      query: queryString.stringify({
        ...queryDistrict,
        district_id,
      }),
      skipUpdateReducer: true,
      callback: {
        success(values) {
          if (_.isArray(values) && values.length > 0) {
            setAwardOptions(
              values.map((item: AwardType) => {
                return {
                  label: _.get(item, "ward_name", ""),
                  value: _.get(item, "ward_id", ""),
                };
              })
            );
          }
        },
        failed(errorMessage) {
          notification.error({
            message: "Lấy danh sách Xã/Phường thất bại!",
            description: errorMessage,
          });
        },
      },
    };
    if (district_id) {
      dispatch(getAwards(payload));
    }
  }

  //Lấy danh sách nhóm người dùng nhóm tổ chức
  function handleGetData(otherQuery: any) {
    const query = {
      year: _.get(filters, "year", 2023),
      schoolId: _.get(filters, "school.value", ""),
      classroomId: _.get(filters, "classroom.value", ""),
      ...otherQuery,
    };
    dispatch(
      setFilter({
        ...filters,
        ...query,
      })
    );
  }

  useEffect(() => {
    handleGetSchools();
    return () => {
      // reset filter select when log out account
      dispatch(setFilter(null));
      dispatch(
        setQuery({
          page: 1,
          limit: 10,
        })
      );
    };
  }, []);

  useEffect(() => {
    console.log("tabActive", tabActive)
    if (tabActive === "3") {
      handleGetSchools();
    }
  }, [
    filters?.province,
    filters?.district,
    filters?.award,
    filters?.school,
    tabActive,
  ]);

  useEffect(() => {
    if (tabActive === "3") {
      if (!_.isEmpty(schoolOptions)) {
        const dataSchoolTabAll = schoolOptions
          .map((item) => item.value)
          .join(",");
        getAccountsOtherGroup({
          schoolId: filters?.school?.value || dataSchoolTabAll,
          limit: filters?.limit || query?.limit,
          page: filters?.page || query?.page,
          year: filters?.year || moment().get("year").toString(),
          classroomId: filters?.classroom?.value || undefined,
          phoneOrName: filters?.phoneOrName || undefined,
          fullname: filters?.fullname || undefined,
        });
      }
    }
  }, [
    filters?.province,
    filters?.district,
    filters?.award,
    filters?.school,
    filters?.year,
    filters?.classroom,
    schoolOptions,
    tabActive,
    filters?.page,
    filters?.limit,
    filters?.phoneOrName,
    filters?.fullname,
  ]);
  useEffect(() => {
    handleGetDistrictAddress();
  }, [filters?.province_id]);

  useEffect(() => {
    handleGetAwardAddress();
  }, [filters?.district_id]);

  useEffect(() => {
    handleGetSchools();
  }, [filters?.award_id]);

  useEffect(() => {
    handleGetClassroom();
  }, [filters?.school, filters?.year]);

  //Lấy danh sách lựa chọn cho ô select
  useEffect(() => {
    handleGetProvinceAddress();
  }, []);

  //Searc theo tên lớp học
  useEffect(() => {
    const deBounce = setTimeout(() => {
      if (classSearchVal) handleGetClassroom();
    }, 700);
    return () => {
      clearTimeout(deBounce);
    };
  }, [classSearchVal]);

  //Xử lý khi tài khoản đang đăng nhập là giáo viên
  useEffect(() => {
    if (isTeacher) {
      handleGetClassroom();
    }
  }, []);

  function getAccountsOtherGroup(otherQuery: any = {}) {
    if (
      tabActive !== "3" ||
      !checkIncludesRole(currentAccountLogin, ["get_account", "get_course"])
    )
      return;
    // handleGetCourse();
    setIsLoading(true);
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          ...query,
          year: "",
          ...otherQuery,
        },
        {
          skipEmptyString: true,
          skipNull: true,
        }
      ),
      skipUpdateReducer: false,
      callback: {
        success(values) {
          setIsLoading(false);
        },
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách!",
            description: errorMessage,
          });
          setIsLoading(false);
        },
      },
    };
    dispatch(getClassroomAccounts(payload));
  }

  function handleGetSchools(otherQuery: any = {}) {
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          province: filters?.province?.label || undefined,
          district: filters?.district?.label || undefined,
          award: filters?.award?.label || undefined,
          year: filters?.year || undefined,
          ...otherQuery,
        },
        {
          skipEmptyString: true,
          skipNull: true,
        }
      ),
      skipUpdateReducer: true,
      callback: {
        success(schools) {
          const data = _.get(schools, "data", []);
          setSchoolOptions(
            data.map((item: any) => {
              return {
                label: _.get(item, "name", ""),
                value: _.get(item, "id"),
              };
            })
          );
        },
        failed(errorMessage) {
          notification.error({
            message: errorMessage,
          });
        },
      },
    };
    console.log("url: /user/allUser")
    if (otherQuery?.province && otherQuery?.district && otherQuery?.award  && isAdmin) {
      dispatch(getSchools(payload));
    }

    if (tabActive === "3" && isAdmin) {
      dispatch(getSchools(payload));
    }
  }

  useEffect(() => {
    return () => {
      //reset query khi thoát khỏi CRUD users
      const pathname = history.location.pathname;
      if (
        pathname !== "/admin/users" &&
        !pathname.includes("/admin/user-all")
      ) {
        dispatch(
          setQuery({
            page: 1,
            limit: 10,
          })
        );
      }
    };
  }, [query, tabActive, currentAccountLogin]);

  useEffect(() => {
    //reset filter khi thay đổi tab
    //mà vẫn còn query.
    setTableKey(tableKey + 1);
    if (tabActive !== "0" && (!!query?.fullname || !!query?.phone)) {
      dispatch(
        setQuery({
          page: 1,
          limit: 10,
        })
      );
    }
  }, [tabActive]);

  // lấy nhãn
  const getPlaceholder = (key: DataIndex) => {
    if (key === "fullname") return "tên học sinh";
    if (key === "username") return "số điện thoại";
    return "";
  };
  //cột và định nghĩa ô tìm kiếm
  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Tìm kiếm theo ${getPlaceholder(dataIndex)}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Đặt lại
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns: ColumnsType<DataType> = [
    {
      title: "STT",
      width: 50,
      dataIndex: "id ",
      key: "id ",
      fixed: "left",
      render(value, record, index) {
        return (classroomsAccounts?.page - 1) * 10 + index + 1;
      },
    },
    {
      title: "Tên học sinh",
      width: 150,
      dataIndex: "fullname",
      key: "fullname",
      fixed: "left",
      ...getColumnSearchProps("fullname"),
      render(value, record: any, index) {
        return (
          <span className="hover-decoration">
            {_.get(record, "accountId.fullname", "")}
          </span>
        );
      },
    },
    {
      title: "Số điện thoại",
      width: 100,
      dataIndex: "username",
      key: "username",
      fixed: "left",
      ...getColumnSearchProps("username"),
      render: (val, record) => {
        return <>{_.get(record, "accountId.username", "")}</>;
      },
    },

    {
      title: "Tỉnh",
      width: 150,
      dataIndex: "schoolId",
      key: "schoolId",
      fixed: "left",
      render: (val, record) => {
        return <>{_.get(val, "province", "")}</>;
      },
    },
    {
      title: "Quận/huyện",
      width: 150,
      dataIndex: "schoolId",
      key: "schoolId",
      fixed: "left",
      render: (val, record) => {
        return <>{_.get(val, "district", "")}</>;
      },
    },
    {
      title: "Trường",
      width: 150,
      dataIndex: "schoolId",
      key: "schoolId",
      fixed: "left",
      render: (val, record) => {
        return <>{_.get(val, "name", "")}</>;
      },
    },
    {
      title: "Lớp ",
      width: 200,
      dataIndex: ["classroomId", "className"],
      key: "classroomId.className",
      fixed: "left",
      render(value, record:any, index) {

        return (
            <Text strong>{value}</Text>
        );
      },
    },
    {
      title: "Ghi chú ",
      width: 200,
      dataIndex: "note",
      key: "note",
      fixed: "left",
    },
  ];

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    let newQuery = {};
    switch (dataIndex) {
      case "fullname":
        newQuery = { fullname: selectedKeys[0] };
        break;
      case "username":
        newQuery = { phoneOrName: selectedKeys[0] };
        break;
      default:
        break;
    }
    dispatch(
      setFilter({
        ...query,
        ...filters,
        ...newQuery,
        page: 1,
      })
    );
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };
  useEffect(() => {}, [filters]);
  return (
    <>
      <div
        className="space-between"
        style={{
          marginTop: 24,
          marginBottom: 24,
        }}
      >
        <Title level={5}>Danh sách</Title>
        <Space
          direction="horizontal"
          size={"small"}
          className="custom-placeholder-select"
        >
          <Space
            direction="horizontal"
            size={"middle"}
            className="custom-placeholder-select"
          >
            <Select
              placeholder={"Tỉnh"}
              style={{ width: 150 }}
              size="middle"
              showSearch
              allowClear
              disabled={isTeacher}
              options={provinceOptions}
              filterOption={filterSelect}
              onChange={(value, option: any) => {
                setSchoolOptions([]);
                dispatch(
                  setFilter({
                    ...filters,
                    province: option,
                    province_id: value,
                    district: null,
                    award: null,
                    school: null,
                    classroom: null,
                  })
                );
              }}
              value={
                isAdmin
                  ? filters?.province
                  : _.get(defaultFilter, "province", null)
              }
            />
            <Select
              placeholder={"Quận/Huyện"}
              style={{ width: 150 }}
              size="middle"
              showSearch
              options={districtOptions}
              filterOption={filterSelect}
              onChange={(value, option: any) => {
                setSchoolOptions([]);
                dispatch(
                  setFilter({
                    ...filters,
                    district: option,
                    district_id: value,
                    award: null,
                    school: null,
                    classroom: null,
                  })
                );
              }}
              disabled={isTeacher || !filters?.province}
              allowClear
              value={
                isAdmin
                  ? filters?.district
                  : _.get(defaultFilter, "district", null)
              }
            />
            <Select
              placeholder={"Xã/Phường"}
              style={{ width: 150 }}
              size="middle"
              showSearch
              options={awardOptions}
              filterOption={filterSelect}
              onChange={(value, option: any) => {
                setSchoolOptions([]);
                dispatch(
                  setFilter({
                    ...filters,
                    award: option,
                    award_id: value,
                    school: null,
                    classroom: null,
                  })
                );
              }}
              disabled={isTeacher || !filters?.district}
              allowClear
              value={
                isAdmin ? filters?.award : _.get(defaultFilter, "award", null)
              }
            />
            <Select
              placeholder={"Trường"}
              style={{ width: 200 }}
              size="middle"
              showSearch
              filterOption={filterSelect}
              options={schoolOptions}
              allowClear
              onChange={(value, option: any) => {
                setClassOptions([]);
                dispatch(
                  setFilter({
                    ...filters,
                    school: option,
                    classroom: null,
                  })
                );
              }}
              disabled={isTeacher || !filters?.district}
              value={
                isAdmin ? filters?.school : _.get(defaultFilter, "school", null)
              }
            />
            <DatePicker
              placeholder={"Năm"}
              style={{ width: 100 }}
              size="middle"
              picker="year"
              disabled={isAdmin ? !filters?.school : classOptions?.length === 0}
              allowClear={false}
              defaultValue={_.get(filters, "year", moment())}
              onChange={(e: any) => {
                dispatch(
                  setFilter({
                    ...filters,
                    year: moment(e).get("year").toString(),
                    classroom: null,
                  })
                );
              }}
            />
            <Select
              placeholder={"Lớp"}
              style={{ width: 100 }}
              size="middle"
              showSearch
              filterOption={false}
              options={classOptions}
              allowClear
              onSearch={(str) => {
                setClassSearchVal(str);
              }}
              onChange={(value, option: any) => {
                dispatch(
                  setFilter({
                    ...filters,
                    classroom: option,
                  })
                );
              }}
              disabled={isAdmin ? !filters?.school : classOptions?.length === 0}
              value={filters?.classroom}
            />
          </Space>
        </Space>
      </div>
      <Table
        loading={isLoading}
        key={tableKey}
        columns={columns}
        dataSource={_.get(classroomsAccounts, "data", [])}
        pagination={{
          current: _.get(classroomsAccounts, "page", 1),
          total: _.get(classroomsAccounts, "totalResults", 0),
          showTotal: (total, range) => {
            return `Có tất cả ${total} bản ghi`;
          },
          onChange(page, pageSize) {
            dispatch(
              setFilter({
                ...query,
                ...filters,
                page: page,
                limit: pageSize,
              })
            );
          },
        }}
        scroll={{ x: 1300, y: 900 }}
      />
    </>
  );
}

export default AllUser;
