/**
 * Màn hình Thông tin tài khoản > Cài đặt cơ bản
 */
import { useState, useEffect } from "react";
import * as yup from "yup";
import {
  Avatar,
  Button,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  Radio,
  Row,
  Space,
  Typography,
  Upload,
  notification,
} from "antd";
import type { RangePickerProps } from "antd/es/date-picker";
import { UploadOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import { PayloadType } from "type";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { getUser, updateUser, uploadAvatar } from "features/userSlice";
import { useHistory } from "react-router";
const { Title } = Typography;
const UserAvatarDefault = require("assets/img/user-avatar-default.jpg");
interface UserFormType {
  name: string;
  email: string;
  roleId: string;
  address: string;
  gender: string;
  isPasswordChange: boolean;
  status: number;
  createdAt: string;
  dateOfBirth: string;
  updatedAt: string;
  id: string;
}
interface BasicSettingAccount {
  user: any;
  handleGetUserData: () => void;
}
function getImageUser() {
  const accountInfoStorage = localStorage.getItem("accountInfo");
  if (!!accountInfoStorage) {
    const accountInfo = JSON.parse(accountInfoStorage);
    if (Object.keys(accountInfo).includes("avatar"))
      return (
        process.env.REACT_APP_BASE_URL_IMAGE +
        "/" +
        _.get(accountInfo, "avatar", "")
      );
  }
  return null;
}
function BasicSettingAccount(props: BasicSettingAccount) {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [fileList, setFileList] = useState<any>([]);
  const [previewVisible, setPreviewVisible] = useState<boolean>();
  const [previewImage, setPreviewImage] = useState([]);
  const [avatarUser, setAvatarUser] = useState<string | null>(getImageUser());
  const { isAdmin, isTeacher } = useSelector(
    (state: any) => state?.accountReducer
  );
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => state.userReducer);
  let settingBasicSchema = yup.object().shape({
    name: yup
      .string()
      .required("Tên người dùng không được để trống!")
      .test((str) => {
        return true;
      }),
    dateOfBirth: yup.string().required("Ngày sinh không được để trống!"),
    gender: yup.string().required("Giới tính không được để trống!"),
    address: yup.string().required("Địa chỉ không được để trống!"),
    email: yup
      .string()
      .email("Địa chỉ email không hợp lệ!")
      .required("Địa chỉ email không được để trống!"),
    phoneNumber: yup
      .string()
      .required("Số điện thoại không được để trống!")
      .test("check-phone", "Số điện thoại không đúng định dạng!", (phone) => {
        const reg = /0[1-9][0-9]{8}/g;
        return reg.test(phone) && phone.length === 10;
      }),
    accountType: yup.string().required("Loại tài khoản không được để trống!"),
  });
  const yupSync = {
    async validator({ field }: any, value: any) {
      await settingBasicSchema.validateSyncAt(field, { [field]: value });
    },
  };
  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return current && current > moment().endOf("day");
  };

  function handleUpdateUser(values: UserFormType) {
    setIsLoading(true);
    const { address, dateOfBirth, email, gender, name, phoneNumber }: any =
      values;
    const body = {
      address,
      gender,
      name,
      phoneNumber,
      dateOfBirth: !!dateOfBirth
        ? moment(dateOfBirth).format("MM/DD/YYYY")
        : null,
    };
    const payload: PayloadType = {
      params: _.get(user, "id", ""),
      body,
      callback: {
        success(values) {
          notification.success({
            message: "Cập nhật thông tin tài khoản thành công!",
            duration: 2,
            onClose() {},
          });
          localStorage.setItem("accountInfo", JSON.stringify(values));
          setIsLoading(false);
          props.handleGetUserData();
        },
        failed(errorMessage) {
          let err = errorMessage;
          if (errorMessage?.includes("Email already taken")) {
            err = "Email này đã được đăng ký!";
          }
          notification.error({
            message: "Cập nhật thông tin tài khoản thất bại!",
            duration: 2,
            description: err,
            onClose() {
              setIsLoading(false);
            },
          });
        },
      },
    };
    dispatch(updateUser(payload));
  }
  //upload ảnh
  const handlePreview = (file: any) => {
    setPreviewImage(file?.thumbUrl ?? "");
    setPreviewVisible(true);
  };
  const handleUpload = ({ fileList }: any) => {
    if (!Array.isArray(fileList) || fileList.length === 0) return;
    setFileList(fileList);
    let formData = new FormData();
    formData.append("avatar", fileList[0].originFileObj);
    const payload: PayloadType = {
      params: _.get(user, "id", ""),
      data: formData,
      callback: {
        success(values) {
          notification.success({
            message: "upload ảnh thành công!",
          });
          const accountInfoStorage = localStorage.getItem("accountInfo");
          if (!!accountInfoStorage) {
            const accountInfo = JSON.parse(accountInfoStorage);
            if (Object.keys(accountInfo).includes("avatar"))
              accountInfo.avatar = _.get(values, "pathRelative", "");
            localStorage.setItem("accountInfo", JSON.stringify(accountInfo));
            setAvatarUser(
              `${process.env.REACT_APP_BASE_URL_IMAGE}/${_.get(
                values,
                "pathRelative",
                ""
              )}`
            );
            history.go(0);
          }
        },
        failed(errorMessage) {
          notification.success({
            message: "upload ảnh thất bại!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(uploadAvatar(payload));
  };
  return (
    <Space
      direction="vertical"
      size={"middle"}
      style={{
        width: "100%",
      }}
    >
      <Title level={4}>Cài đặt cơ bản</Title>
      <Form
        initialValues={{ remember: true }}
        onFinish={(values) => {
          handleUpdateUser(values);
        }}
        onFinishFailed={() => {}}
        autoComplete="off"
        layout="vertical"
      >
        <Row
          style={{
            minWidth: 1000,
          }}
        >
          <Col span={4}>
            <Form.Item
              label="Tên tài khoản"
              required
              name="name"
              labelAlign="left"
              rules={[yupSync]}
              initialValue={_.get(props.user, "name", "")}
            >
              <Input allowClear placeholder="Nhập tên tài khoản" />
            </Form.Item>
            <Form.Item
              required
              name="gender"
              label="Giới tính"
              rules={[yupSync]}
              initialValue={_.get(props.user, "gender", "")}
            >
              <Radio.Group>
                <Radio value="1">Nam</Radio>
                <Radio value="0">Nữ</Radio>
                <Radio value="-1">Khác</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              required
              name="dateOfBirth"
              label="Ngày sinh"
              rules={[yupSync]}
              initialValue={
                !!_.get(props.user, "dateOfBirth", "")
                  ? moment(_.get(props.user, "dateOfBirth", undefined))
                  : ""
              }
            >
              <DatePicker
                placeholder="Chọn ngày"
                style={{
                  width: "100%",
                }}
                format={"DD/MM/YYYY"}
                disabledDate={disabledDate}
              />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              required
              labelAlign="left"
              rules={[yupSync]}
              initialValue={_.get(props.user, "email", "")}
            >
              <Input allowClear disabled placeholder="Nhập Email" />
            </Form.Item>
            <Form.Item
              label="Số điện thoại"
              name="phoneNumber"
              required
              labelAlign="left"
              rules={[yupSync]}
              initialValue={_.get(props.user, "phoneNumber", "")}
            >
              <Input allowClear placeholder="Nhập số điện thoại" />
            </Form.Item>
            <Form.Item
              label="Địa chỉ"
              required
              name={"address"}
              rules={[yupSync]}
              initialValue={_.get(props.user, "address", "")}
            >
              <TextArea placeholder="Nhập địa chỉ" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Lưu lại
              </Button>
            </Form.Item>
          </Col>
          <Col
            style={{
              marginLeft: 48,
            }}
            span={8}
          >
            <Form.Item
              name="upload"
              style={{
                width: "100%",
              }}
              labelAlign="right"
              // valuePropName="fileList"
              getValueFromEvent={() => {}}
              extra=""
            >
              <Space direction="vertical">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <label>Ảnh đại diện</label>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Avatar
                    src={
                      <Image
                        src={!!avatarUser ? avatarUser : UserAvatarDefault}
                      />
                    }
                    size={72}
                  />
                </div>
                <Upload
                  fileList={fileList}
                  showUploadList={false}
                  onPreview={handlePreview}
                  onChange={handleUpload}
                  name="logo"
                  action="/upload.do"
                  beforeUpload={() => false}
                >
                  <Button icon={<UploadOutlined />}>
                    Tải lên ảnh đại diện
                  </Button>
                </Upload>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Space>
  );
}

export default BasicSettingAccount;
