import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

export const paymentSlice : any = createSlice({
  name: "paymentSlice",
  initialState: {
    payments: [],
    query: {
      page: 1,
      limit: 10,
      // year: moment().get("year"),
      // populate: 'schoolId,teacherId'
    },
    payment: {},
    filters : null,
    isUpdateForm: false,
  },
  reducers: {
    getPayments: (state, action) => {},
    getPayment: (state, action) => {},
    getPaymentsSuccess: (state, action) => {
      state.payments = action.payload;
    },
    getPaymentSuccess: (state, action) => {
      state.payment = action.payload;
    },
    setQuery: (state,action) => {
      state.query = action.payload;
    },
    setFilter: (state,action) => {
      state.filters = action.payload;
    },
    setStatePayment: (state: any,action) => {
      Object.keys(action.payload).map(key => {
        state[`${key}`] = action.payload[`${key}`]
      })
    },
    createPayment: (state,action) => {},
    updatePayment: (state,action) => {},
    deletePayment: (state,action) => {},
  },
});
export const { getPayments,getPayment,updatePayment, getPaymentsSuccess,getPaymentSuccess,setQuery,setFilter,createPayment,deletePayment,setStatePayment } = paymentSlice.actions;
export default paymentSlice.reducer;
