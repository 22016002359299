import { getAttendancesSuccess, getDiamondSuccess } from "features/diamondSlice";
import queryString from "query-string";
import { call, all, put, takeLatest } from "redux-saga/effects";
import { getClassrooms } from "sagas/classroomSaga";
import { getRoles } from "sagas/roleSaga";
import { DELETE, GET, POST, PUT } from "services/ServiceBase";
import ServiceURL from "services/ServiceURL";
import { ActionType } from "type";
var _ = require("lodash");
export function* getDiamonds(data: ActionType): any {
  const { callback, body, query, skipUpdateReducer } = data.payload;
  const url = `${ServiceURL.diamonds}?${query ?? ""}`;
  try {
    const res = yield call(GET, url, body);
    if (!!res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      if (!skipUpdateReducer)
        yield put(getDiamondSuccess(_.get(res, "data", [])));
      callback.success(_.get(res, "data", []));
    }
  } catch (error: any) {
    callback.failed(error?.message ?? "");
  }
}

export function* getAttendances(data: ActionType): any {
  const { callback, body, query} = data.payload;
  const url = `${ServiceURL.attendances}?${query ?? ""}`;
  try {
    const res = yield call(GET, url, body);
    if (!!res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      yield put(getAttendancesSuccess(_.get(res, "data", {})));
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    let err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}

export default function* diamondSaga() {
  yield takeLatest("diamondSlice/getDiamonds", getDiamonds);
  yield takeLatest("diamondSlice/getAttendances", getAttendances);
}
