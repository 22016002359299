import { notification } from "antd";
import { getClassrooms } from "features/classroomSlice";
import { getSchool } from "features/schoolSlice";
import _ from "lodash";
import CustomLabelValue from "pages/components/CustomLabelValue";
import queryString from "query-string";
import {
  Dispatch,
  Fragment,
  SetStateAction,
  memo,
  useEffect,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import { PayloadType, SchoolType, SelectOptionType } from "type";

interface CUFormStateType {
  type: "create" | "update" | "list";
  isOpen: boolean;
  tab: "school" | "classroom";
  idUpdate?: string;
}
interface RSchoolFormType {
  form: CUFormStateType;
  setForm: Dispatch<SetStateAction<CUFormStateType>>;
}
function RSchool({ dataFromList }:any) {
  const dispatch = useDispatch();
  const param = useParams();
  const [classroomsData, setClassroomsData] = useState([]);
  const [school, setSchool] = useState([]);
  const yearValue = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const year = searchParams.get("year");
  const [dataAllClass, setDataAllClass] = useState([]);
 

  function handleGetSchool() {
    const payload: PayloadType = {
      params: _.get(param, "id", ""),
      query: queryString.stringify(
        {
          year: year,
        },
        {
          skipEmptyString: true,
          skipNull: true,
        }
      ),
      skipUpdateReducer: false,
      callback: {
        success(values) {
          setSchool(values);
        },
        failed(errorMessage) {
          notification.error({
            message: errorMessage,
          });
        },
      },
    };
    dispatch(getSchool(payload));
  }
  function handleClassrooms() {
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          populate: "schoolId,teacherId",
          schoolId: _.get(param, "id", ""),
          year: year,
          page: 1,
          limit: 9999,
        },
        {
          skipEmptyString: true,
          skipNull: true,
        }
      ),
      skipUpdateReducer: false,
      callback: {
        success(values) {
          setClassroomsData(values);
        },
        failed(errorMessage) {
          notification.error({
            message: errorMessage,
          });
        },
      },
    };
    dispatch(getClassrooms(payload));
  }



  const rows: SelectOptionType[] = [
    {
      label: "Tên trường",
      value: _.get(dataFromList, "name", ""),
    },
    {
      label: "Số lớp",
      value: _.get(dataFromList, "classThisYear", 0),
    },
    {
      label: "Số học sinh",
      value: _.get(dataFromList, "numberOfAccount.Fee", 0) + _.get(dataFromList, "numberOfAccount.Free", 0) ,
    },
    {
      label: "Số HS được hỗ trợ",
      value: _.get(dataFromList, "numberOfAccount.Free", 0),
    },
    {
      label: "Số HS mua gói học",
      value: _.get(dataFromList, "numberOfAccount.Fee", 0),
    },
    {
      label: "Tỉnh",
      value: _.get(dataFromList, "province", ""),
    },
    {
      label: "Quận/Huyện",
      value: _.get(dataFromList, "district", ""),
    },
    {
      label: "Xã Phường",
      value: _.get(dataFromList, "award", ""),
    },
    {
      label: "Người đại diện",
      value: _.get(dataFromList, "representative", ""),
    },
    {
      label: "Email",
      value: _.get(dataFromList, "email", ""),
    },
    {
      label: "Số điện thoại",
      value: _.get(dataFromList, "phoneNumber", ""),
    },
    {
      label: "Địa chỉ ",
      value: _.get(dataFromList, "address", ""),
    },
    {
      label: "Ghi chú",
      value: _.get(dataFromList, "notes", ""),
    },
  ];
 
  return (
    <Fragment>
      {rows.map((row: SelectOptionType) => {
        return (
          <div
            style={{
              display: "flex",
              marginTop: 28,
              marginBottom: 28,
            }}
          >
            <label
              style={{
                display: "inline-block",
                textAlign: "left",
                minWidth: "150px",
              }}
            >
              {row.label}
            </label>
            <span>:</span>
            <span
              style={{
                display: "inline-block",
                marginLeft: 20,
                fontWeight: 500,
              }}
            >
              {row.value}
            </span>
          </div>
        );
      })}
    </Fragment>
  );
}

export default memo(RSchool);
