/**
 * Màn hình Quản trị - Thêm/Sửa tài khoản quản trị
 */
import _ from "lodash";
import {
  Layout,
  Typography,
  Button,
  Form,
  Input,
  Space,
  Radio,
  DatePicker,
  Select,
  Tooltip,
  notification,
} from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import * as yup from "yup";
import moment from "moment";
import type { RangePickerProps } from "antd/es/date-picker";
import TextArea from "antd/lib/input/TextArea";
import { useDispatch } from "react-redux";
import { PayloadType, SchoolType } from "type";
import queryString from "query-string";
import { useSelector } from "react-redux";
import { getRoles } from "features/roleSlice";
import { createUser, getUser, setQuery, updateUser } from "features/userSlice";
import ResetPassModal from "pages/Manage/components/ResetPassModal";
import { checkBodyRequest } from "utils";
import { getSchools } from "features/schoolSlice";
const { Content } = Layout;
const { Title } = Typography;
interface TooltipType {
  name: string;
  isOpen: boolean;
}
interface UserFormType {
  name: string;
  email: string;
  roleId: string;
  address: string;
  gender: string;
  isPasswordChange: boolean;
  status: number;
  createdAt: string;
  dateOfBirth: string;
  updatedAt: string;
  id: string;
}
function CUManage(props: any) {
  const [newPass, setNewPass] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const roleReducer = useSelector((state: any) => state.roleReducer);
  const [accountTypeOptions, setAccountTypeOptions] = useState<any[]>([]);
  const [schoolOptions, setSchoolOptions] = useState<any[]>([]);
  const [searchSchoolVal, setSearchSchoolVal] = useState<string | null>();
  const [schoolVal, setSchoolVal] = useState<string>();
  const [isRoleTeacher, setIsRoleTeacher] = useState<boolean>(false);
  const [notificationModal, setNotificationModal] = useState(false);
  const { user } = useSelector((state: any) => state.userReducer);
  const dispatch = useDispatch();



  function handleGetSchools() {
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          name: searchSchoolVal,
          page: 1,
          limit: 99999,

        },
        {
          skipEmptyString: true,
          skipNull: true,
        }
      ),
      skipUpdateReducer: true,
      callback: {
        success(schools) {
          const data = _.get(schools, "data", []);
          setSchoolOptions(
            data.map((item: SchoolType) => {
              return {
                label: [item.name,item.province,item.district,item.award].join(" - "),
                value: item.id,
              };
            })
          );
        },
        failed(errorMessage) {
          notification.error({
            message: errorMessage,
          });
        },
      },
    };
    dispatch(getSchools(payload));
  }
  useEffect(() => {
    const roleUserActive = accountTypeOptions.find((item:any)=> item.value === user?.roleId )?.name; 
    if( roleUserActive === "teacher") {
      setIsRoleTeacher(true)
      
    }

  }, [accountTypeOptions]);

  useEffect(() => {
   handleGetSchools();
  }, [searchSchoolVal]);




  

  function handleGetUser(id: string) {
    const payload: PayloadType = {
      params: id,
      body: {},
      callback: {
        success(values) { },
        failed(errorMessage) {
          notification.error({
            message: "Lấy thông tin người dùng thất bại!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getUser(payload));
  }
  useEffect(() => {
    if (!!props?.isUpdate) {
      handleGetUser(user?.id ?? "");
    }
  }, [props.displayCU]);

  let accountSchema = props?.isUpdate
    ? yup.object().shape({
      name: yup
        .string()
        .required("Tên người dùng không được để trống!")
        .test((str) => {
          return true;
        }),
      dateOfBirth: yup.string().required("Ngày sinh không được để trống!"),
      gender: yup.string().required("Giới tính không được để trống!"),
      email: yup
        .string()
        .email("Địa chỉ email không hợp lệ!")
        .required("Địa chỉ email không được để trống!"),
      phoneNumber: yup
        .string()
        .required("Số điện thoại không được để trống!")
        .test(
          "check-phone",
          "Số điện thoại không đúng định dạng!",
          (phone) => {
            const reg = /0[1-9][0-9]{8}/g;
            return reg.test(phone) && phone.length === 10;
          }
        ),
      address: yup.string().required("Địa chỉ không được để trống!"),
      schoolId: yup.string().test("schoolId","Trường không được để trống!",(schoolId) => {
        if (isRoleTeacher)
        return !!schoolId;
      return true;
      }),
      accountType: yup
        .string()
        .required("Loại tài khoản không được để trống!"),
    })
    : yup.object().shape({
      password: yup
        .string()
        .required("Mật khẩu mới không được để trống!")
        .test(
          "password",
          "Mật khẩu phải dài ít nhất 8 kí tự bao gồm chữ thường, chữ hoa , số và kí tự đặc biệt  ",
          (password) => {
            const reg =
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/g;
            return reg.test(password);
          }
        ),
      passwordConfirm: yup
        .string()
        .required("Vui lòng xác nhận lại mật khẩu!")
        .test(
          "check-confirm-pass",
          "Mật khẩu không khớp!",
          (newConfirmPass) => newConfirmPass === newPass
        ),
      name: yup
        .string()
        .required("Tên người dùng không được để trống!")
        .test((str) => {
          return true;
        }),
      dateOfBirth: yup.string().required("Ngày sinh không được để trống!"),
      gender: yup.string().required("Giới tính không được để trống!"),
      email: yup
        .string()
        .email("Địa chỉ email không hợp lệ!")
        .required("Địa chỉ email không được để trống!"),
      phoneNumber: yup
        .string()
        .required("Số điện thoại không được để trống!")
        .test(
          "check-phone",
          "Số điện thoại không đúng định dạng!",
          (phone) => {
            const reg = /0[1-9][0-9]{8}/g;
            return reg.test(phone) && phone.length === 10;
          }
        ),
      address: yup.string().required("Địa chỉ không được để trống!"),
      schoolId: yup.string().test("schoolId","Trường không được để trống!",(schoolId) => {
        if (isRoleTeacher)
        return !!schoolId;
      return true;
      }),
      accountType: yup
        .string()
        .required("Loại tài khoản không được để trống!"),
    });
  const yupSync = {
    async validator({ field }: any, value: any) {
      await accountSchema.validateSyncAt(field, { [field]: value });
    },
  };
  //Gọi api lấy role admin và giáo viên
  function handleGetRole() {
    const payload: PayloadType = {
      query: queryString.stringify(_.get(roleReducer, "query", {})),
      body: {},
      callback: {
        success(values) {
          const rAdmin = values.data.find((role: any) => {
            return role.name === "admin";
          });
          const rTeacher = values.data.find((role: any) => {
            return role.name === "teacher";
          });
          setAccountTypeOptions([
            {
              label: "Admin",
              value: rAdmin?.id ?? "",
              ...rAdmin,
            },
            {
              label: "Giáo viên",
              value: rTeacher?.id ?? "",
              ...rTeacher,
            },
          ]);
        },
        failed(errorMessage) {
          notification.error({
            message: errorMessage,
          });
        },
      },
    };
    dispatch(getRoles(payload));
  }
  useEffect(() => {
    handleGetRole();
  }, [roleReducer?.query]);
  //Không cho phép chọn ngày sinh sau ngày hiện tại
  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return current && current > moment().endOf("day");
  };
  //Danh sách quản lý bật tắt các tooltip của input
  const [openTooltip, setOpenTooltip] = useState<TooltipType[]>([
    {
      name: "email",
      isOpen: false,
    },
  ]);
  const handleToggleTooltip = (name: string) => {
    const tooltip = [...openTooltip];
    tooltip.some((item, index) => {
      if (item?.name === name) {
        tooltip[index].isOpen = !tooltip[index]?.isOpen;
      }
      return item?.name === name;
    });
    setOpenTooltip(tooltip);
  };
  // reset query danh sách khi thêm mới/ cập nhật thành công
  function resetQuery() {
    dispatch(setQuery({
      page: 1,
      limit: 10,
    }))
  }
  //Gọi API cập nhật tài khoản quản trị
  function handleUpdateUser(values: UserFormType) {
    setIsLoading(true);
    const { address, dateOfBirth, email, gender, name, schoolId, notes, phoneNumber }: any =
      values;
      const body = {
        address,
        email,
        gender,
        schoolId, notes,
        name,
        phoneNumber,
        roleId: _.get(values, "accountType", ""),
        dateOfBirth: !!dateOfBirth
          ? moment(dateOfBirth).format("MM/DD/YYYY")
          : null,
      }
      if (!isRoleTeacher) delete body['schoolId'];
    const payload: PayloadType = {
      params: _.get(user, "id", ""),
      body: checkBodyRequest(
        body,
        ["address"],
        []
      ),
      callback: {
        success(values) {
          notification.success({
            message: "Cập nhật thông tin tài khoản thành công!",
            duration: 2,
            onClose() {
              props.setDisplayCU(false);
              setIsLoading(false);
            },
          });
          resetQuery();
        },
        failed(errorMessage) {
          let err = errorMessage;
          if (errorMessage?.includes("Email already taken")) {
            err = "Email này đã được đăng ký!";
          }
          notification.error({
            message: "Cập nhật thông tin tài khoản thất bại!",
            duration: 2,
            description: err,
            onClose() {
              setIsLoading(false);
            },
          });
        },
      },
    };
    dispatch(updateUser(payload));
  }
  
  //Gọi API tạo mới tài khoản quản trị
  function handleCreateUser(values: UserFormType) {
    setIsLoading(true);
    const {
      address,
      dateOfBirth,
      email,
      gender,
      name,
      password,
      phoneNumber,
      schoolId,
      notes,
    }: any = values;
    const body = {
      address,
      email,
      gender,
      name,
      password,
      phoneNumber,
      schoolId,
      notes,
      roleId: _.get(values, "accountType", ""),
      dateOfBirth: !!dateOfBirth
        ? moment(dateOfBirth).format("MM/DD/YYYY")
        : null,
    }
    if (!isRoleTeacher) delete body['schoolId'];
    const payload: PayloadType = {
      body,
      callback: {
        success(values) {
          notification.success({
            message: "Thêm mới tài khoản thành công!",
            duration: 2,
            onClose() {
              props.setDisplayCU(false);
              setIsLoading(false);
            },
          });
          resetQuery();
        },
        failed(errorMessage) {
          let err = errorMessage;
          if (errorMessage?.includes("Email already taken")) {
            err = "Email này đã được đăng ký!";
          }
          notification.error({
            message: "Thêm mới tài khoản thất bại!",
            duration: 2,
            description: err,
            onClose() {
              setIsLoading(false);
            },
          });
        },
      },
    };
    dispatch(createUser(payload));
  }
  return (
    <Content className="custom-layout-content">
      <Title level={4}>
        <Link
          to={"/admin/administrations"}
          onClick={() => {
            if (!!props?.setDisplayCU) {
              props.setDisplayCU(false);
            }
          }}
        >
          <ArrowLeftOutlined
            style={{ marginRight: 12, color: "black" }}
            size={13}
          />
        </Link>
        {!props?.isUpdate ? "Thêm mới tài khoản" : "Chỉnh sửa tài khoản"}
      </Title>
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 8 }}
        onFinish={(values: UserFormType) => {
          props?.isUpdate ? handleUpdateUser(values) : handleCreateUser(values);
        }}
        onFinishFailed={() => { }}
        autoComplete={"off"}
        layout="horizontal"
        style={{
          marginTop: 64,
        }}
      >
        <Form.Item
          label="Tên tài khoản"
          name="name"
          required
          labelAlign="right"
          rules={[yupSync]}
          initialValue={_.get(user, "name", "")}
        >
          <Input allowClear placeholder="Nhập vào tên tài khoản" />
        </Form.Item>
        {props.isUpdate ? (
          <Form.Item label="Mật khẩu" name="password" labelAlign="right">
            <a
              style={{
                textDecoration: "underline",
              }}
              onClick={() => {
                setNotificationModal(true);
              }}
            >
              Đặt lại mật khẩu
            </a>
            <ResetPassModal
              notificationModal={notificationModal}
              setNotificationModal={setNotificationModal}
            />
          </Form.Item>
        ) : (
          <>
            <Form.Item
              label="Mật khẩu"
              name="password"
              labelAlign="right"
              required
              rules={[yupSync]}
            >
              <Input.Password
                onChange={(e) => {
                  setNewPass(e.target.value);
                }}
                allowClear
                placeholder="Nhập mật khẩu"
              />
            </Form.Item>
            <Form.Item
              label="Xác nhận mật khẩu"
              name="passwordConfirm"
              required
              labelAlign="right"
              rules={[yupSync]}
            >
              <Input.Password allowClear placeholder="Xác nhận lại mật khẩu" />
            </Form.Item>
          </>
        )}
        <Form.Item
          name="gender"
          label="Giới tính"
          required
          rules={[yupSync]}
          initialValue={_.get(user, "gender", "")}
        >
          <Radio.Group>
            <Radio value="1">Nam</Radio>
            <Radio value="0">Nữ</Radio>
            <Radio value="-1">Khác</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="dateOfBirth"
          label="Ngày sinh"
          required
          rules={[yupSync]}
          initialValue={
            !!_.get(user, "dateOfBirth", "")
              ? moment(_.get(user, "dateOfBirth", ""))
              : ""
          }
        >
          <DatePicker
            placeholder="Chọn ngày"
            style={{
              width: "100%",
            }}
            format={"DD/MM/YYYY"}
            defaultValue={undefined}
            disabledDate={disabledDate}
          />
        </Form.Item>
        <Form.Item
          style={{
            height: 0,
            margin: 0,
          }}
        >
          <Tooltip
            open={
              !props?.isUpdate
                ? openTooltip.find((tooltip) => {
                  return tooltip?.name === "email";
                })?.isOpen
                : true
            }
            color="blue"
            placement="right"
            title="Email sẽ được dùng thay cho tên đăng nhập"
          >
            <div style={{ width: "200%" }}></div>
          </Tooltip>
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          required
          labelAlign="right"
          rules={[yupSync]}
          initialValue={_.get(user, "email", "")}
        >
          <Input
            onFocus={() => {
              handleToggleTooltip("email");
            }}
            onBlur={() => {
              handleToggleTooltip("email");
            }}
            allowClear
            placeholder="Nhập vào email"
            disabled={!!props.isUpdate}
          />
        </Form.Item>
        <Form.Item
          label="Số điện thoại"
          name="phoneNumber"
          labelAlign="right"
          required
          rules={[yupSync]}
          initialValue={_.get(user, "phoneNumber", "")}
        >
          <Input allowClear placeholder="Nhập số điện thoại" />
        </Form.Item>
        <Form.Item
          required
          label="Địa chỉ"
          name={"address"}
          rules={[yupSync]}
          initialValue={_.get(user, "address", "")}
        >
          <TextArea
            placeholder="Nhập địa chỉ"
            // showCount={true}
            maxLength={100}
          />
        </Form.Item>
        <Form.Item
          label="Loại tài khoản"
          required
          name={"accountType"}
          rules={[yupSync]}
          initialValue={_.get(user, "roleId", null)}
        >
          <Select
            style={{ width: 240 }}
            onChange={(value,option) => {
              const roleName = _.get(option,"name","");
              setIsRoleTeacher(roleName === 'teacher');
             }}
            options={accountTypeOptions}
            placeholder={"Chọn loại tài khoản"}
          />
        </Form.Item>
        {
          isRoleTeacher && (
            <Form.Item
          label="Trường"
          name="schoolId"
          required
          labelAlign="right"
          rules={[yupSync]}
          initialValue={_.get(user, "schoolId", null)}
        >
          <Select
            allowClear
            showSearch
            onSearch={(str: string) => {
              setSearchSchoolVal(str);
            }}
            options={schoolOptions}
            placeholder={"Chọn trường"}
            size="middle"
            onChange={(val) => {
              setSchoolVal(val);
            }}
            filterOption={false}
          />
        </Form.Item>
          )
        }
        <Form.Item
          label="Ghi chú"
          name={"notes"}
          initialValue={_.get(user, "notes", "")}
        >
          <TextArea
            placeholder="Nhập ghi chú"
            // showCount={true}
            maxLength={100}
          />
        </Form.Item>
        <Form.Item
          wrapperCol={{
            offset: 8,
          }}
        >
          <Space>
            <Button
              type="default"
              size="middle"
              htmlType="submit"
              onClick={() => {
                props.setDisplayCU(false);
              }}
            >
              Hủy
            </Button>
            <Button
              type="primary"
              size="middle"
              htmlType="submit"
              loading={isLoading}
            >
              {props?.isUpdate ? "Lưu lại" : "Thêm mới"}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Content>
  );
}

export default CUManage;
