/* eslint-disable react-hooks/exhaustive-deps */
/**
 * Màn hình quản trị tổ chức lớp
 */
import {
  Button,
  DatePicker,
  Divider,
  Popconfirm,
  Typography,
  notification,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Space } from "antd";
import React, {
  memo,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { styled } from "styled-components";
import { useSelector } from "react-redux";
import { PayloadType } from "type";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import _ from "lodash";
import {
  deleteClassroom,
  getClassrooms,
  setFilter,
} from "features/classroomSlice";
import moment from "moment";
import { useHistory, useLocation, useParams } from "react-router";
import { getClassroomAccounts } from "features/accountSlice";
interface DataType {
  key: number;
  classroomName: string;
  teacher: string;
  school: string;
  numberHS: number;
}

const { Title } = Typography;
const CustomPopconfirm = styled(Popconfirm)`
  .ant-popover-inner {
    max-width: 240px !important;
    background-color: red;
  }
`;
interface CUFormStateType {
  type: "create" | "update" | "list";
  isOpen: boolean;
  tab: "school" | "classroom";
  idUpdate?: string;
}
interface ClassroomFormType {
  form: CUFormStateType;
  setForm: Dispatch<SetStateAction<CUFormStateType>>;
  setYear: Dispatch<SetStateAction<number>>;
  dataFromList: any;
}
function Classroom({
  form,
  setForm,
  setYear,
  dataFromList,
}: ClassroomFormType) {
  const history = useHistory();
  const param = useParams();
  const yearValue: any = useParams();
  const { classrooms, filters } = useSelector(
    (state: any) => state?.classroomReducer
  );
  function getYearFromUrl(url: any) {
    const match = url.match(/[?&]year=(\d{4})/);
    return match ? match[1] : null;
  }
  const yearFromUrl = getYearFromUrl(yearValue?.id);
  const [dataAllClass, setDataAllClass] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const countTotolClass = _.get(classrooms, "data", []);
  const columns: ColumnsType<DataType> = [
    {
      title: "Tên lớp",
      width: 20,
      dataIndex: "className",
      key: "className",
      fixed: "left",
    },
    {
      title: "Giáo viên",
      width: 100,
      dataIndex: ["teacherId", "name"],
      key: "teacherName",
      fixed: "left",
      className: "",
    },
    {
      title: "Số HS ",
      width: 20,
      dataIndex: "userNumber",
      key: "userNumber",
      fixed: "left",
      align: "center",
      render(value, record, index) {
        return (
          parseInt(_.get(record, "numberOfFeeAccount", "0")) +
          parseInt(_.get(record, "numberOfFreeAccount", "0"))
        );
      },
    },
    {
      title: "Số HS được hỗ trợ",
      width: 20,
      dataIndex: "numberOfFreeAccount",
      key: "numberOfFreeAccount",
      fixed: "left",
      align: "center",
    },

    {
      title: "Số HS mua gói học",
      width: 20,
      dataIndex: "numberOfFeeAccount",
      key: "numberOfFeeAccount",
      fixed: "left",
      align: "center",
    },
    {
      title: "Ghi chú",
      width: 70,
      dataIndex: ["schoolId", "name"],
      key: "schoolName",
      fixed: "left",
      align: "left",
    },
    {
      title: "Hành động",
      width: 20,
      dataIndex: "action",
      key: "action",
      align: "center",
      render: (cell, record: any) => (
        <Space size="middle">
          <Space>
            <button
              style={{
                backgroundColor: "transparent",
                border: 0,
                color: "#1890ff",
                cursor: "pointer",
              }}
              onClick={() => {
             
                history.replace(
                  `/admin/update-classroom-organization/${
                    record?.id
                  }?schoolId=${_.get(
                    dataFromList,
                    "id",
                    ""
                  )}&year=${yearFromUrl}`,
                  { state: dataFromList, year: yearFromUrl }
                );
              }}
            >
              Sửa
            </button>
            <Divider type="vertical" />
            <CustomPopconfirm
              title={`Bạn có chắc chắn muốn xóa lớp ${
                record?.classroomName ?? ""
              } không ?`}
              okText={"Có"}
              cancelText={"Không"}
              onConfirm={() => {
                handleDeleteClassroom(record?.id ?? "");
              }}
            >
              <a href="##">Xoá</a>
            </CustomPopconfirm>
          </Space>
        </Space>
      ),
    },
  ];
  //Lấy danh sách lớp
  function handleGetClassrooms() {
    setIsLoading(true);
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          year: yearFromUrl,
          populate: "schoolId,teacherId",
          schoolId: _.get(dataFromList, "id", ""),
          ...filters,
        },
        {
          skipEmptyString: true,
          skipNull: true,
        }
      ),
      skipUpdateReducer: false,
      callback: {
        success(values) {
          setIsLoading(false);
        },
        failed(errorMessage) {
          notification.error({
            message: errorMessage,
          });
        },
      },
    };
    dispatch(getClassrooms(payload));
  }

  // Lấy danh sách lựa chọn cho ô select
  useEffect(() => {
    handleGetClassrooms();
  }, [filters]);
  useEffect(() => {
    return () => {
      dispatch(
        setFilter({
          ...filters,
          page: 1,
        })
      );
    };
  }, []);
  function handleDeleteClassroom(id: string) {
    const payload: PayloadType = {
      params: id,
      callback: {
        success(values) {
          notification.success({
            message: "Xoá lớp học thành công!",
          });
          handleGetClassrooms();
        },
        failed(errorMessage) {
          notification.error({
            message: "Xoá lớp học thất bại!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(deleteClassroom(payload));
  }

  return (
    <>
      <div
        className="space-between"
        style={{
          marginTop: 24,
          marginBottom: 24,
          alignItems: "center",
        }}
      >
        <div style={{ width: "40%" }}>
          <Title
            level={5}
            style={{
              fontWeight: 500,
            }}
          >
            Danh sách
          </Title>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p style={{ fontSize: "12px" }}>
              Tổng số học sinh:{" "}
              <b>
                {_.get(dataFromList, "numberOfAccount.Fee", 0) +
                  _.get(dataFromList, "numberOfAccount.Free", 0)}
              </b>
            </p>
            <p style={{ fontSize: "12px" }}>
              Tổng số học sinh được hỗ trợ:{" "}
              <b>{_.get(dataFromList, "numberOfAccount.Free", 0)}</b>
            </p>
            <p style={{ fontSize: "12px" }}>
              Tổng số học sinh mua gói học:{" "}
              <b>{_.get(dataFromList, "numberOfAccount.Fee", 0)} </b>
            </p>
          </div>
        </div>

        <Space
          style={{ width: "60%", justifyContent: "end" }}
          direction="horizontal"
          size={"middle"}
          className="custom-placeholder-select"
        >
          <DatePicker
            placeholder={"Năm"}
            style={{ width: 100 }}
            size="middle"
            picker="year"
            allowClear={false}
            disabled
            defaultValue={moment(yearFromUrl || moment().get("year"), "YYYY")}
            onChange={(e) => {
              const nextY = !!e ? moment(e).get("year") : moment().get("year");
              setYear(nextY);
              dispatch(
                setFilter({
                  ...filters,
                  page: 1,
                  year: nextY.toString(), // Chuyển nextY sang chuỗi để đảm bảo kiểu dữ liệu
                })
              );
            }}
          />

          <Button
            type="primary"
            size="middle"
            onClick={() => {

              history.replace(
                `/admin/create-classroom-organization?schoolId=${_.get(
                  dataFromList,
                  "id",
                  ""
                )}&year=${yearFromUrl}`,
                { state: dataFromList, year: yearFromUrl }
              );
            }}
          >
            <PlusOutlined />
            Thêm mới
          </Button>
        </Space>
      </div>

      <Table
        columns={columns}
        loading={isLoading}
        dataSource={_.get(classrooms, "data", [])}
        pagination={{
          total: classrooms?.totalResults,
          current: classrooms?.page ?? 1,
          showTotal: (total, range) => {
            return `Có tất cả ${total} bản ghi`;
          },
          showSizeChanger: true,
          onChange(page, pageSize) {
            dispatch(
              setFilter({
                ...filters,
                page,
                limit: pageSize,
              })
            );
          },
        }}
        scroll={{ x: 1300, y: 500 }}
      />
    </>
  );
}

export default memo(Classroom);
