/**
 * Tabs trường
 */
import { useEffect, useRef, useState } from "react";
import {
  Button,
  DatePicker,
  Divider,
  Input,
  InputRef,
  Pagination,
  Popconfirm,
  Select,
  Typography,
  notification,
} from "antd";
import { checkAnyCheckObject } from "utils";
import { PlusOutlined } from "@ant-design/icons";
import { Space } from "antd";
import { memo, Dispatch, SetStateAction } from "react";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { styled } from "styled-components";
import { SearchOutlined } from "@ant-design/icons";
import {
  AwardType,
  DistrictType,
  PayloadType,
  ProviceType,
  SelectOptionType,
} from "type";
import queryString from "query-string";
import { useSelector } from "react-redux";
import {
  deleteSchool,
  countSchool,
  getSchools,
  setFilter,
  setQuery,
  setStateSchool,
} from "features/schoolSlice";
import { useDispatch } from "react-redux";
import { getProvinces } from "features/provinceSlice";
import _ from "lodash";
import { getDistricts } from "features/districtSlice";
import { getAwards } from "features/awardSlice";
import { useHistory } from "react-router";
import { DEFAULT_PROVICE_FILTER_ID } from "const";
import moment from "moment";
import { ColumnType } from "antd/lib/table";
import { FilterConfirmProps } from "antd/lib/table/interface";
interface DataType {
  key: number;
  schoolName: string;
  schoolAddress: string;
  numberOfClass: number;
}
const { Title } = Typography;
const CustomPopconfirm = styled(Popconfirm)`
  .ant-popover-inner {
    max-width: 240px !important;
    background-color: red;
  }
`;
interface CUFormStateType {
  type: "create" | "update" | "list";
  isOpen: boolean;
  tab: "school" | "classroom";
  idUpdate?: string;
}
interface SchoolFormType {
  form: CUFormStateType;
  setForm: Dispatch<SetStateAction<CUFormStateType>>;
}
interface DataType {
  code: string;
  username: string;
  fullname: string;
  name: string;
  gender: string;
  personage: string;
  diamond: string;
  courseid: string;
  membership: string;
  parent_code: string;
  paid_expired: string;
  confirmed: string;
  avatar: string;
  createdAt: string;
  updatedAt: string;
  email: string;
  belongToSchool: boolean;
  id: string;
}
type DataIndex = keyof DataType;
function School({ form, setForm }: SchoolFormType) {
  const history = useHistory();
  const { query, filters, schools, countSchools } = useSelector(
    (state: any) => state?.schoolReducer
  );
  const [searchedColumn, setSearchedColumn] = useState("");
  const [searchText, setSearchText] = useState("");
  const searchInput = useRef<InputRef>(null);
  const { queryProvince } = useSelector((state: any) => state?.provinceReducer);
  const { queryDistrict } = useSelector((state: any) => state?.districtReducer);
  const { queryAward } = useSelector((state: any) => state?.awardReducer);
  const [provinceOptions, setProvinceOptions] = useState<SelectOptionType[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(false);

  const [getDataDate, setGetDataDate] = useState(moment().year().toString());
  const [districtOptions, setDistrictOptions] = useState<SelectOptionType[]>(
    []
  );
  const Highlighter = require("react-highlight-words");
  const [awardOptions, setAwardOptions] = useState<SelectOptionType[]>([]);
  //cột và định nghĩa ô tìm kiếm
  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Tìm kiếm theo tên trường`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Đặt lại
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  //Cấu hình bộ lọc
  const dispatch = useDispatch();
  const columns: ColumnsType<DataType> = [
    {
      title: "STT",
      width: 15,
      dataIndex: "name",
      key: "name",
      fixed: "left",
      align: "center",
      render(value, record, index) {
        return (schools?.page - 1) * 10 + index + 1;
      },
    },
    {
      title: "Tên trường",
      width: 50,
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
      fixed: "left",
    },
    {
      title: "Số lớp",
      width: 20,
      align: "center",
      dataIndex: "classThisYear",
      key: "classThisYear",
      fixed: "left",
      render(value, record, index) {
        return _.get(record, "classThisYear", 0);
      },
    },
    {
      title: "Số hs",
      width: 20,
      align: "center",
      dataIndex: "classroomNumber",
      key: "classroomNumber",
      fixed: "left",
      render(value, record, index) {
        return (
          _.get(record, "numberOfAccount.Free", 0) +
          _.get(record, "numberOfAccount.Fee", 0)
        );
      },
    },
    {
      title: "Số HS được hỗ trợ",
      width: 20,
      align: "center",
      fixed: "left",
      render(value, record, index) {
        return _.get(record, "numberOfAccount.Free", "");
      },
    },
    {
      title: "Số HS mua gói học",
      width: 20,
      align: "center",
      fixed: "left",
      render(value, record, index) {
        return _.get(record, "numberOfAccount.Fee", "");
      },
    },
    {
      title: "Địa chỉ",
      width: 60,
      dataIndex: "address",
      key: "address",
      fixed: "left",
      className: "",
      render(value, record, index) {
        return `${_.get(record, "address", "")}, ${_.get(
          record,
          "award",
          ""
        )}, ${_.get(record, "district", "")}, ${_.get(record, "province", "")}`;
      },
    },
    {
      title: "Ghi chú",
      width: 20,
      dataIndex: "notes",
      key: "notes",
      fixed: "left",
      className: "",
    },
    {
      title: "Hành động",
      width: 20,
      dataIndex: "action",
      key: "action",
      fixed: "left",
      align: "center",
      render: (_, record: any) => (
        <Space size="small">
          <a
            onClick={() => {
              // history.push(
              //   `/admin/school-organization/${record?.id}?year=${filters?.year || moment().get('year').toString()}`
              // );
              history.push({
                pathname: `/admin/school-organization/${record?.id}?year=${filters?.year || moment().get('year').toString()}`,
                state: record
            });
            }}
          >
            Xem
          </a>
          <Divider type="vertical" />
          <a
            onClick={() => {
              history.push("/admin/update-school-organization/" + record?.id);
            }}
          >
            Sửa
          </a>
          <Divider type="vertical" />
          <CustomPopconfirm
            title={`Bạn có chắc chắn muốn xóa trường ${
              record?.schoolName ?? ""
            } không ?`}
            okText={"Có"}
            cancelText={"Không"}
            onConfirm={() => {
              handleDeleteSchool(record?.id ?? "");
            }}
          >
            <a>Xoá</a>
          </CustomPopconfirm>
        </Space>
      ),
    },
  ];
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    let newQuery = {};
    switch (dataIndex) {
      case "name":
        newQuery = { name: selectedKeys[0] };
        break;
      default:
        break;
    }
    dispatch(
      setQuery({
        ...query,
        ...newQuery,
        page: 1,
      })
    );
    setSearchedColumn(dataIndex);
  };

  //Lấy danh sách trường
  function handleGetSchools() {
    setIsLoading(true);
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          ...query,
          ...filters,
          province: filters?.province?.label || undefined,
          district: filters?.district?.label || undefined,
          award: filters?.award?.label || undefined,
          year:
            moment(filters?.year).get("year").toString() ||
            moment().year().toString(),
        },
        {
          skipEmptyString: true,
          skipNull: true,
        }
      ),
      skipUpdateReducer: false,
      callback: {
        success(values) {
          const payloadSum: PayloadType = {
            query: queryString.stringify(
              {
                ...query,
                ...filters,
                province: filters?.province?.label || undefined,
                district: filters?.district?.label || undefined,
                award: filters?.award?.label || undefined,
                year:
                  moment(filters?.year).get("year").toString() ||
                  moment().year().toString(),
              },
              {
                skipEmptyString: true,
                skipNull: true,
              }
            ),
            skipUpdateReducer: false,
            callback: {
              success(values) {
                setIsLoading(false);

              },
              failed(errorMessage) {
                notification.error({
                  message: errorMessage,
                });
                setIsLoading(false);
              },
            },
          };
          dispatch(countSchool(payloadSum));
        },
        failed(errorMessage) {
          notification.error({
            message: errorMessage,
          });
          setIsLoading(false);
        },
      },
    };
    console.log("url: /origanization/school")
    dispatch(getSchools(payload));
  }
  useEffect(() => {

    handleGetSchools();
  }, [filters, query]);

  //Lấy danh sách địa chỉ - tỉnh/quận/xã
  function handleGetProvinceAddress() {
    const payload: PayloadType = {
      query: queryString.stringify(queryProvince),
      callback: {
        success(values) {
          if (_.isArray(values) && values.length > 0) {
            setProvinceOptions(
              values.map((item: ProviceType) => {
                return {
                  label: _.get(item, "province_name", ""),
                  value: _.get(item, "province_id", ""),
                };
              })
            );
            if (!filters?.province) {
              const defaultValue = _.find(values, {
                province_id: DEFAULT_PROVICE_FILTER_ID,
              });
              dispatch(
                setFilter({
                  province: {
                    label: _.get(defaultValue, "province_name", ""),
                    value: _.get(defaultValue, "province_id", ""),
                  },
                })
              );
            }
          }
        },
        failed(errorMessage) {
          notification.error({
            message: "Lấy danh sách tỉnh thất bại!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getProvinces(payload));
  }
  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };
  function handleGetDistrictAddress() {
    const payload: PayloadType = {
      query: queryString.stringify({
        ...queryDistrict,
        province_id: _.get(filters, "province.value", ""),
      }),
      callback: {
        success(values) {
          if (_.isArray(values) && values.length > 0) {
            setDistrictOptions(
              values.map((item: DistrictType) => {
                return {
                  label: _.get(item, "district_name", ""),
                  value: _.get(item, "district_id", ""),
                };
              })
            );
          }
        },
        failed(errorMessage) {
          notification.error({
            message: "Lấy danh sách Quận/Huyện thất bại!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getDistricts(payload));
  }
  function handleGetAwardAddress() {
    const payload: PayloadType = {
      query: queryString.stringify({
        ...queryAward,
        district_id: _.get(filters, "district.value", ""),
      }),
      callback: {
        success(values) {
          if (_.isArray(values) && values.length > 0) {
            setAwardOptions(
              values.map((item: AwardType) => {
                return {
                  label: _.get(item, "ward_name", ""),
                  value: _.get(item, "ward_id", ""),
                };
              })
            );
          }
        },
        failed(errorMessage) {
          notification.error({
            message: "Lấy danh sách Xã/Phường thất bại!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getAwards(payload));
  }

  useEffect(() => {
    handleGetProvinceAddress();
    handleGetSchools();
    dispatch(
      setFilter({
        ...query,
        ...filters,
      })
    );
    return () => {
      // Đặt lại pegination nếu thoát ra khỏi tính năng CRUD tổ chức lớp học
      if (
        !history.location.pathname.includes("/admin/school-organization") &&
        history.location.pathname !== "/admin/create-school-organization" &&
        !history.location.pathname.includes("/admin/update-school-organization")
      ) {
        dispatch(
          setQuery({
            page: 1,
            limit: 10,
          })
        );
      }
    };
  }, []);

  useEffect(() => {
    if (filters?.province) handleGetDistrictAddress();
  }, [filters?.province]);

  useEffect(() => {
    if (filters?.district) handleGetAwardAddress();
  }, [filters?.district]);

  // useEffect(() => {
  //   const oldAddress = {
  //     province: query?.province,
  //     district: query?.district,
  //     award: query?.award,
  //     year: query?.year,
  //   };
  //   const newAddress = {
  //     province: filters?.province?.label ?? "",
  //     district: filters?.district?.label ?? "",
  //     award: filters?.award?.label ?? "",
  //     year: filters?.year ?? "",
  //   };
  //   dispatch(
  //     setQuery({
  //       ...query,
  //       limit: filters?.limit ?? 10,
  //       province: filters?.province?.label ?? "",
  //       district: filters?.district?.label ?? "",
  //       award: filters?.award?.label ?? "",
  //       year: filters?.year ?? "",
  //       page: checkAnyCheckObject(oldAddress, newAddress)
  //         ? 1
  //         : filters?.page ?? 1, //thay đổi lọc địa chỉ thì ở trang 1 còn vẫn phải ở trang hiện tại
  //     })
  //   );
  //   handleGetSchools();
  // }, [filters]);

  function handleDeleteSchool(id: string) {
    const payload: PayloadType = {
      params: id,
      callback: {
        success(values) {
          notification.success({
            message: "Xoá trường học thành công!",
          });
          handleGetSchools();
        },
        failed(errorMessage) {
          notification.error({
            message: "Xoá trường học thất bại!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(deleteSchool(payload));
  }
  //
  const filterSelect = (input: string, option: any) => {
    return option?.label?.toLowerCase().includes(input.toLowerCase());
  };

  return (
    <>
      <div
        className="space-between"
        style={{
          marginTop: 24,
          marginBottom: 24,
        }}
      >
        <div>
          <Title
            level={5}
            style={{
              fontWeight: 500,
            }}
          >
            Danh sách trường
          </Title>
          <div>
            <div style={{ display: "flex" }}>
              <p style={{ paddingRight: 20 }}>
                Tổng số trường: <b>{schools?.totalResults}</b>
              </p>
              <p>
                Tổng số học sinh:{" "}
                <b>
                  {_.get(countSchools, "numberOfFeeAccount", 0) +
                    _.get(countSchools, "numberOfFreeAccount", 0)}
                </b>
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ paddingRight: 20 }}>
                Tổng số học sinh được hỗ trợ:{" "}
                <b>{_.get(countSchools, "numberOfFreeAccount", 0)}</b>
              </p>
              <p>
                Tổng số học sinh mua gói học:{" "}
                <b>{_.get(countSchools, "numberOfFeeAccount", 0)}</b>
              </p>
            </div>
          </div>
        </div>

        <Space
          direction="horizontal"
          size={"middle"}
          className="custom-placeholder-select"
        >
          <Select
            placeholder={"Tỉnh"}
            style={{ width: 150 }}
            size="middle"
            showSearch
            allowClear
            options={provinceOptions}
            filterOption={filterSelect}
            onChange={(value, option: any) => {
              dispatch(
                setFilter({
                  ...filters,
                  province: option,
                  district: null,
                  award: null,
                  page: 1,
                })
              );
            }}
            value={filters?.province}
          />
          <Select
            placeholder={"Quận/Huyện"}
            style={{ width: 150 }}
            size="middle"
            showSearch
            allowClear
            options={districtOptions}
            filterOption={filterSelect}
            onChange={(value, option: any) => {
              dispatch(
                setFilter({
                  ...filters,
                  district: option,
                  award: null,
                  page: 1,
                })
              );
            }}
            value={filters?.district}
          />
          <Select
            placeholder={"Xã/Phường"}
            style={{ width: 150 }}
            size="middle"
            showSearch
            allowClear
            options={awardOptions}
            filterOption={filterSelect}
            onChange={(value, option: any) => {
              dispatch(
                setFilter({
                  ...filters,
                  award: option,
                  page: 1,
                })
              );
            }}
            value={filters?.award}
          />

          <DatePicker
            placeholder={"Năm"}
            style={{ width: 100 }}
            size="middle"
            picker="year"
            allowClear={false}
            defaultValue={
              _.get(filters, "year")
                ? moment().set("year", _.get(filters, "year"))
                : moment().startOf("year")
            }
            onChange={(e) => {
              dispatch(
                setFilter({
                  ...filters,
                  year: moment(e).get("year").toString(),
                })
              );
            }}
          />
          <Button
            type="primary"
            size="middle"
            onClick={() => {
              history.push("/admin/create-school-organization");
            }}
          >
            <PlusOutlined />
            Thêm mới
          </Button>
        </Space>
      </div>

      <Table
         loading={isLoading}
        columns={columns}
        dataSource={_.get(schools, "data", [])}
        pagination={false}
        scroll={{ x: 1200, y: 500 }}
      />
      <div style={{ display: "flex", float: "right", marginTop: 10 }}>
        <Pagination
          total={schools?.totalResults}
          current={schools?.page ?? 1}
          pageSize={filters?.limit ?? 10}
          showTotal={(total, range) => {
            return `Có tất cả ${total} bản ghi`;
          }}
          showSizeChanger={false}
          onChange={(page, pageSize) => {
            dispatch(
              setFilter({
                ...filters,
                page,
                limit: _.isNaN(pageSize) ? 99999 : pageSize,
              })
            );
          }}
          pageSizeOptions={["10", "20", "50", "Tất cả"]}
        />
        &emsp;
        <Select
          defaultValue={filters?.limit ?? 10}
          onChange={(value) => {
            dispatch(
              setFilter({
                ...filters,
                limit: value,
              })
            );
          }}
          style={{ width: 110 }}
        >
        
          <Select.Option value={10}>10 / page</Select.Option>
          <Select.Option value={20}>20 / page</Select.Option>
          <Select.Option value={50}>50 / page</Select.Option>
          <Select.Option value={100}>100 / page</Select.Option>
          <Select.Option value={99999}>Tất cả / page</Select.Option>
        </Select>
      </div>
      {/* <Select
        defaultValue={filters?.limit ?? 10}
        onChange={(value) => {
          dispatch(
            setFilter({
              ...filters,
              limit: value,
            })
          );
        }}
      >
        <Select.Option value={10}>10</Select.Option>
        <Select.Option value={20}>20</Select.Option>
        <Select.Option value={50}>50</Select.Option>
        <Select.Option value={100}>100</Select.Option>
        <Select.Option value={99999}>Tất cả</Select.Option>
      </Select> */}
    </>
  );
}

export default memo(School);
