/**
 * Modal chuyển lớp
 */
import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Select,
  Space,
  notification,
} from "antd";
import { setPassByAdmin } from "features/userSlice";
import _ from "lodash";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { PayloadType } from "type";
import * as yup from "yup";
interface ResetPassFormType {
  password: "";
  confirmPass: "";
}
interface ModalProps {
  changeClass: boolean;
  setChangeClass: (values: boolean) => void;
}
const ChangeClassModal = (props: ModalProps) => {
  const { user } = useSelector((state: any) => state.userReducer);
  const [newPass, setNewPass] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const handleCancel = () => {
    props.setChangeClass(false);
  };

  let accountSchema = yup.object().shape({
    password: yup
      .string()
      .required("Mật khẩu mới không được để trống!")
      .test(
        "password",
        "Mật khẩu phải dài ít nhất 8 kí tự bao gồm chữ thường, chữ hoa , số và kí tự đặc biệt!",
        (password) => {
          const reg =
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/g;
          return reg.test(password);
        }
      ),
    passwordConfirm: yup
      .string()
      .required("Vui lòng xác nhận lại mật khẩu!")
      .test(
        "check-confirm-pass",
        "Mật khẩu không khớp!",
        (newConfirmPass) => newConfirmPass === newPass
      ),
  });
  const yupSync = {
    async validator({ field }: any, value: any) {
      await accountSchema.validateSyncAt(field, { [field]: value });
    },
  };
  const handleUpdatePassword = (values: any) => {
    setIsLoading(true);
    const payload: PayloadType = {
      body: {
        userId: _.get(user, "id", ""),
        password: _.get(values, "password", ""),
      },
      callback: {
        success(values) {
          notification.success({
            message:
              "Cập nhật mật khẩu cho tài khoản " +
              _.get(user, "name", "") +
              " thành công!",
          });
          handleCancel();
        },
        failed(errorMessage) {
          notification.error({
            message:
              "Cập nhật mật khẩu cho tài khoản " +
              _.get(user, "name", "") +
              " không thành công!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(setPassByAdmin(payload));
  };

  return (
    <>
      <Modal
        width={500}
        title="Xoá học sinh"
        open={props.changeClass}
        onCancel={handleCancel}
        footer={false}
      >
        <Form
          labelCol={{ span: 6 }} 
          labelAlign="right"
          onFinish={(values: ResetPassFormType) => {
            handleUpdatePassword(values);
          }}
          autoComplete="off"
          layout="horizontal"
        >
          <Form.Item label="Tỉnh" name="password" required rules={[yupSync]}>
            <Select
              placeholder="Chọn nhóm tài khoản"
              style={{ width: "100%" }}
              options={[]}
            />
          </Form.Item>

          <Form.Item
            label="Quận/Huyện"
            name="password"
            required
            rules={[yupSync]}
          >
            <Select
              placeholder="Chọn nhóm tài khoản"
              style={{ width: "100%" }}
              options={[]}
            />
          </Form.Item>

          <Form.Item
            label="Xã/Phường"
            name="password"
            required
            rules={[yupSync]}
          >
            <Select
              placeholder="Chọn nhóm tài khoản"
              style={{ width: "100%" }}
              options={[]}
            />
          </Form.Item>

          <Form.Item label="Trường" name="password" required rules={[yupSync]}>
            <Select
              placeholder="Chọn nhóm tài khoản"
              style={{ width: "100%" }}
              options={[]}
            />
          </Form.Item>

          <Form.Item label="Lớp" name="password" required rules={[yupSync]}>
            <Select
              placeholder="Chọn nhóm tài khoản"
              style={{ width: "100%" }}
              options={[]}
            />
          </Form.Item>
          <Form.Item
            style={{
              marginBottom: 0,
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Space>
              <Button type="default" size="large" onClick={handleCancel}>
                Hủy
              </Button>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                loading={isLoading}
              >
                Xác nhận
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ChangeClassModal;
