import {
  accountLoginSuccess,
  getAccountsSuccess,
  getClassroomAccountsSuccess,
  setAccount,
} from "features/accountSlice";
import { call, put, takeLatest } from "redux-saga/effects";
import { DELETE, GET, POST, PUT } from "services/ServiceBase";
import ServiceURL from "services/ServiceURL";
import { ActionType } from "type";
var _ = require("lodash");
export function* loginRequest(data: ActionType): any {
  const url = ServiceURL.logIn;
  const { callback, body } = data.payload;
  try {
    const res = yield call(POST, url, body);
    if (!!res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      
      yield put(accountLoginSuccess(_.get(res, "data.data.user", {})));
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    callback.failed(error?.message ?? "");
  }
}
export function* createAccount(data: ActionType): any {
  const { callback, body } = data.payload;
  const url = `${ServiceURL.classroomAccount}`;
  try {
    const res = yield call(POST, url, body);
    if (!!res?.response?.data?.code && !!res?.response?.data?.message) {
      callback.failed(res?.response?.data?.message);
    } else {
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    let err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}

export function* createUserAccount(data: ActionType): any {
  const { callback, body } = data.payload;
  const url = `${ServiceURL.account}`;
  try {
    const res = yield call(POST, url, body);
    if (!!res?.response?.data?.code && !!res?.response?.data?.message) {
      callback.failed(res?.response?.data?.message);
    } else {
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    let err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}
export function* updateClassroomAccount(data: ActionType): any {
  const { callback, body, params } = data.payload;
  const url = `${ServiceURL.classroomAccount}/${params}`;
  try {
    const res = yield call(PUT, url, body);
    if (!!res?.response?.data?.code && !!res?.response?.data?.message) {
      callback.failed(res?.response?.data?.message);
    } else {
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    let err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}

export function* deleteClassroomAccount(data: ActionType): any {
  const { callback, body, params } = data.payload;
  const url = `${ServiceURL.classroomAccount}/${params}`;
  try {
    const res = yield call(DELETE, url, body);
    if (!!res?.response?.data?.code && !!res?.response?.data?.message) {
      callback.failed(res?.response?.data?.message);
    } else {
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    let err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}

export function* updateAccount(data: ActionType): any {
  const { callback, body, params } = data.payload;
  const url = `${ServiceURL.account}/${params}`;
  try {
    const res = yield call(PUT, url, body);
    if (!!res?.response?.data?.code && !!res?.response?.data?.message) {
      callback.failed(res?.response?.data?.message);
    } else {
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    let err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}
export function* changePassword(data: ActionType): any {
  const { callback, body, params } = data.payload;
  const url = `${ServiceURL.changepass}/${params}`;
  try {
    const res = yield call(POST, url, body);
    if (!!res?.code && !!res?.message) {
      callback.failed(res.message);
    } else {
      callback.success(res);
    }
  } catch (error: any) {
    let err = error?.response?.data?.message || error?.message;
    if (error?.response?.data?.code === 400) {
      callback.failed("Mật khẩu cũ không chính xác!");
      return;
    }
    callback.failed(err);
  }
}
export function* getAccounts(data: ActionType): any {
  const { callback, body, params, query, skipUpdateReducer } = data.payload;
  const url = `${ServiceURL.account}?${query}`;
  try {
    const res = yield call(GET, url, body);
    if (!!res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      // if (skipUpdateReducer)
      yield put(getAccountsSuccess(_.get(res, "data", {})));
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    let err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}
export function* getAccount(data: ActionType): any {
  const { callback, body, query, params } = data.payload;
  const url = `${ServiceURL.account}/${params}?${query ?? ""}`;
  try {
    const res = yield call(GET, url, body);
    if (!!res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      yield put(setAccount(_.get(res, "data", {})));
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    let err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}
export function* getClassroomAccounts(data: ActionType): any {
  const { callback, body, query } = data.payload;
  const url = `${ServiceURL.classroomAccount}?${query}`;
  try {
    const res = yield call(GET, url, body);
    if (!!res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      // if (skipUpdateReducer)
      yield put(getClassroomAccountsSuccess(_.get(res, "data", {})));
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    let err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}
export function* forgotPass(data: ActionType): any {
  const { callback, body } = data.payload;
  const url = `${ServiceURL.forgot}`;
  try {
    const res = yield call(POST, url, body);
    if (!!res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    let err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}
export function* resetPass(data: ActionType): any {
  const { callback, body, query } = data.payload;
  const url = `${ServiceURL.resetpass}${query}`;
  try {
    const res = yield call(POST, url, body);
    if (!!res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    let err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}

export function* accountSaga() {
  yield takeLatest("accountSlice/accountLogin", loginRequest);
  yield takeLatest("accountSlice/changePassword", changePassword);
  yield takeLatest("accountSlice/getAccounts", getAccounts);
  yield takeLatest("accountSlice/getClassroomAccounts", getClassroomAccounts);
  yield takeLatest("accountSlice/forgotPass", forgotPass);
  yield takeLatest("accountSlice/resetPass", resetPass);
  yield takeLatest("accountSlice/getAccount", getAccount);
  yield takeLatest("accountSlice/updateAccount", updateAccount);
  yield takeLatest("accountSlice/createAccount", createAccount);
  yield takeLatest("accountSlice/createUserAccount", createUserAccount);
  yield takeLatest("accountSlice/updateClassroomAccount", updateClassroomAccount);
  yield takeLatest("accountSlice/deleteClassroomAccount", deleteClassroomAccount);
}
