/**
 * Màn hình thêm mới trường
 */
import HeaderbackTitle from "pages/components/HeaderBackTitle";

import React, { memo, useState, useEffect, useMemo, Fragment } from "react";
import {
  Layout,
  Button,
  Form,
  Space,
  notification,
  Input,
  DatePicker,
  Radio,
  Select,
  Row,
  Col,
  Calendar,
  Empty,
} from "antd";
import * as yup from "yup";
import { CourseType, PayloadType } from "type";
import { useDispatch } from "react-redux";
import _ from "lodash";
import {
  createCourse,
  getCourse,
  getCourses,
  updateCourse,
} from "features/courseSlice";
import { useHistory, useLocation, useParams } from "react-router";
import { useSelector } from "react-redux";

import { useForm } from "antd/lib/form/Form";
import {
  createAccount,
  getAccount,
  setQuery,
  updateAccount,
} from "features/accountSlice";
import "moment/locale/zh-cn";
import locale from "antd/es/date-picker/locale/zh_CN";
import queryString from "query-string";
import moment, { Moment } from "moment";
import { RangePickerProps } from "antd/lib/date-picker";
import { updateUser } from "features/userSlice";
import TextArea from "antd/lib/input/TextArea";
import CustomLabelValue from "pages/components/CustomLabelValue";
import RatingBox from "pages/components/RatingBox";
import { getAttendances, getDiamonds } from "features/diamondSlice";
import { getClassroom } from "features/classroomSlice";
const { Content } = Layout;
interface FormType {
  name: "create" | "update";
  id: string | null;
}
function getFormType(history: any, param: any): FormType {
  if (history?.location?.pathname === "/admin/create-user-all") {
    return {
      name: "create",
      id: null,
    };
  }
  if (history?.location?.pathname.includes("/admin/user-all")) {
    const { id } = param;
    return {
      name: "update",
      id,
    };
  }
  return {
    name: "create",
    id: null,
  };
}
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function ViewUser() {
  const [formUpdate] = useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const param = useParams();
  const searchParams = useQuery();
  const [form, setForm] = useState<FormType>(getFormType(history, param));
  const { query, filters, attendances, diamonds } = useSelector(
    (state: any) => state.diamondReducer
  );
  const [dataAttendsAll, setDataAttendsAll] = useState([]);
  const [diamond, setDiamond] = useState([]);
  const [attendanceFilter, setDataAttendFilter] = useState({});
  const [diamondFilter, setDiamondFilter] = useState({});
  const [account, setAccount] = useState<any>({});
  const [course, setCourse] = useState<any>({});
  const [classroom, setClassroom] = useState<any>({});
  const [tabDiamond, settabDiamond] = useState(0);
  function handleGetAccount() {
    const payload: PayloadType = {
      params: _.get(param, "id", ""),
      callback: {
        success(account) {
          setAccount(account);
        },
        failed(errorMessage) {
          console.log("errorMessage", errorMessage);
        },
      },
    };
    
    dispatch(getAccount(payload));
  }
  const classroomIdactive = searchParams.get("classroomId");
  function handleGetClassroom() {
    const payload: PayloadType = {
      params: classroomIdactive,
      callback: {
        success(classrooms) {
          setClassroom(classrooms);

          const payload: PayloadType = {
            query: queryString.stringify(
              {
                courseId: _.get(classrooms, "courseId.id", ""),
              },
              {
                skipEmptyString: true,
                skipNull: true,
              }
            ),
            callback: {
              success(diamond) {
                setDiamond(diamond);
              },
              failed(errorMessage) {
                console.log("errorMessage", errorMessage);
              },
            },
          };
          
            dispatch(getDiamonds(payload));
        },
        failed(errorMessage) {
          console.log("errorMessage", errorMessage);
        },
      },
    };
    dispatch(getClassroom(payload));
  }

  function handleGetAttendances(query: any) {
    const payload: PayloadType = {
      query: queryString.stringify(query, {
        skipEmptyString: true,
        skipNull: true,
      }),
      callback: {
        success(account) {},
        failed(errorMessage) {
          console.log("errorMessage", errorMessage);
        },
      },
    };
    dispatch(getAttendances(payload));
  }
 
  useEffect(() => {
    handleGetAccount();
  }, []);
  useEffect(() => {
    handleGetClassroom();
  }, [account]);

  function convertTimestampToAttendanceData(data: any) {
    const studentAttendance: any = {};

    data.forEach((item: any) => {
      const timestamp = item.date;
      const date = new Date(timestamp * 1000);
      const dateString = date.toISOString().slice(0, 10); // Lấy ngày tháng năm dưới dạng YYYY-MM-DD
      studentAttendance[dateString] = true; // Thiết lập status cho ngày này là true
    });

    return studentAttendance;
  }

  function generateNewData(existingData: any, year: any, month: any) {
    const daysInMonth = new Date(year, month, 0).getDate();
    const newData: any = {};

    for (let day = 1; day <= daysInMonth; day++) {
      const date = `${year}-${month.toString().padStart(2, "0")}-${day
        .toString()
        .padStart(2, "0")}`;
      newData[date] = existingData[date] || false;
    }

    return newData;
  }

  useEffect(() => {
    handleGetAttendances({
      month: moment().month() + 1,
      year: new Date().getFullYear(),
      accountId: _.get(param, "id", ""),
    });
    console.log("searchParams");
  }, [searchParams]);

  useEffect(() => {
    const attendanceData = convertTimestampToAttendanceData(attendances);
    const year = new Date().getFullYear();
    const month = new Date().getMonth() + 1; // Lấy tháng hiện tại (tính từ 0)
    setDataAttendsAll(generateNewData(attendanceData, year, month));
  }, [attendances]);

  const ChooseBg = (index: any) => {
    if (index === 0) {
      return "#B7EB8F";
    } else if (index === 1) {
      return "#D9F7BE";
    } else if (index === 2) {
      return "#F6FFED";
    } else {
      return "#fff";
    }
  };

  const ChooseDiamond = (index: any = 0) => {
    return _.get(diamond, `data[${index}].data`, []);
  };
  const handleChangeAttendance = (e: any) => {
    const yearActive = moment(e).get("year");
    const monthActive = moment(e).get("month") + 1;
    handleGetAttendances({
      month: monthActive,
      year: yearActive,
      accountId: _.get(param, "id", ""),
    });
  };

  console.log("classroom", classroom);
  const dateCellRender = (date: Moment) => {
    const dateString: any = date.format("YYYY-MM-DD");
    const status = dataAttendsAll[dateString]; // Sử dụng newData thay vì studentAttendance
    const backgroundColor = status ? "rgb(183, 235, 143)" : "transparent"; // Màu xanh nếu có chuyên cần, trong suốt nếu không
    const cellStyle = {
      width: "100%",
      height: "100%",
      backgroundColor: backgroundColor,
    };

    return (
      <div style={cellStyle}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            justifyContent: "center",
          }}
        >
          {date.date()}
        </div>
      </div>
    );
  };

  return (
    <Content className="custom-layout-content">
      <HeaderbackTitle
        title={"Thông tin tài khoản học sinh "}
        handleBack={() => {}}
        link={`/admin/users?tabActive=3&schoolId=${searchParams.get(
          "schoolId"
        )}&classroomId=${searchParams.get(
          "classroomId"
        )}&year=${searchParams.get("year")}&province=${searchParams.get(
          "province"
        )}&district=${searchParams.get("district")}&award=${searchParams.get(
          "award"
        )}`}
      />

      <Row>
        <Col span={12}>
          <CustomLabelValue
            alignX={150}
            label="Học và tên"
            value={_.get(account, "fullname", `${searchParams.get("name")}`)}
          />
          <CustomLabelValue
            alignX={150}
            label="Số điện thoại"
            value={_.get(account, "username", `${searchParams.get("phone")}`)}
          />
          <CustomLabelValue
            alignX={150}
            label="Tổng số kim cương"
            value={_.get(account, "diamond", `${searchParams.get("diamond")}`)}
            fontW="700"
            color="red"
          />
        </Col>
      </Row>

      <Row>
        <Col span={11}>
          <div
            className="flex space-between center view-user"
            style={{ alignItems: "center" }}
          >
            <b style={{ position: "absolute", top: 15 }}>Bảng chuyên cần</b>
            <Calendar
              dateCellRender={dateCellRender}
              onChange={(e) => handleChangeAttendance(e)}
            />
          </div>
        </Col>
        <Col span={1}></Col>
        <Col span={12}>
          <div
            className="flex space-between center"
            style={{ alignItems: "center" }}
          >
            <b>Bảng xếp hạng kim cương</b>
            <div>
              <Button
                className={tabDiamond === 0 ? "activeDiamond" : ""}
                onClick={() => settabDiamond(0)}
                style={{ marginRight: 10 }}
              >
                {" "}
                {!_.isEmpty(classroom)
                  ? `Lớp ${_.get(classroom, "courseId.fullname", "")}`
                  : "Không có lớp"}
              </Button>
              <Button
                className={tabDiamond === 1 ? "activeDiamond" : ""}
                style={{ marginRight: 10 }}
                onClick={() => settabDiamond(1)}
              >
                Tuần này
              </Button>
              <Button
                className={tabDiamond === 2 ? "activeDiamond" : ""}
                onClick={() => settabDiamond(2)}
              >
                Hệ thống
              </Button>
            </div>
          </div>
          <div
            style={{ height: "560px", overflowY: "auto", marginTop: "20px" }}
          >
            {!_.isEmpty(ChooseDiamond(tabDiamond)) ? (
              <>
                {ChooseDiamond(tabDiamond).map((item: any, index: any) => {
                  return (
                    <RatingBox
                      image={`${process.env.REACT_APP_BASE_URL_IMAGE}/${item?.avatar}`}
                      name={item?.fullname}
                      position={index + 1}
                      total={item?.diamond}
                      bg={ChooseBg(index)}
                    />
                  );
                })}
              </>
            ) : (
              <Empty
                style={{ marginTop: "250px" }}
                description={
                  <div>
                    <span>Học sinh này chưa đăng nhập vào hệ thống.</span>{" "}
                    <br />
                  </div>
                }
              />
            )}
          </div>
        </Col>
      </Row>
    </Content>
  );
}

export default memo(ViewUser);
