import { notification } from "antd";
import {
  getClassroomSuccess,
  getClassroomsSuccess,
} from "features/classroomSlice";
import queryString from "query-string";
import { call, all, put, takeLatest } from "redux-saga/effects";
import { getRoles } from "sagas/roleSaga";
import { DELETE, GET, POST, PUT } from "services/ServiceBase";
import ServiceURL from "services/ServiceURL";
import { ActionType } from "type";
var _ = require("lodash");
export function* getClassrooms(data: ActionType): any {
  const { callback, body, query } = data.payload;
  const url = `${ServiceURL.classroom}?${query ?? ""}`;
  try {
    const res = yield call(GET, url, body);
    if (!!res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      callback.success(_.get(res, "data", []));
      yield put(getClassroomsSuccess(_.get(res, "data", [])));
    }
  } catch (error: any) {
    let err = error?.response?.data?.message || error?.message;

    callback.failed(err);
  }
}
export function* getClassroom(data: ActionType): any {
  const { callback, body, query, params } = data.payload;
  const url = `${ServiceURL.classroom}/${params}?${query ?? ""}`;
  try {
    const res = yield call(GET, url, body);
    if (!!res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      yield put(getClassroomSuccess(_.get(res, "data", {})));
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    let err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}
export function* createClassroom(data: ActionType): any {
  const { callback, body } = data.payload;
  const url = ServiceURL.classroom;
  try {
    const res = yield call(POST, url, body);
    if (!!res?.response?.data?.code && !!res?.response?.data?.message) {
      callback.failed(res?.response?.data?.message);
    } else {
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    let err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}
export function* updateClassroom(data: ActionType): any {
  const { callback, body, params } = data.payload;
  const url = `${ServiceURL.classroom}/${params}`;
  try {
    const res = yield call(PUT, url, body);
    if (!!res?.response?.data?.code && !!res?.response?.data?.message) {
      callback.failed(res?.response?.data?.message);
    } else {
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    let err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}
export function* deleteClassroom(data: ActionType): any {
  const { callback, body, params } = data.payload;
  const url = `${ServiceURL.classroom}/${params}`;
  try {
    const res = yield call(DELETE, url, body);
    if (!!res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      callback.success(res);
    }
  } catch (error: any) {
    callback.failed(error?.message ?? "");
  }
}
export default function* classroomSaga() {
  yield takeLatest("classroomSlice/getClassrooms", getClassrooms);
  yield takeLatest("classroomSlice/getClassroom", getClassroom);
  yield takeLatest("classroomSlice/createClassroom", createClassroom);
  yield takeLatest("classroomSlice/updateClassroom", updateClassroom);
  yield takeLatest("classroomSlice/deleteClassroom", deleteClassroom);
}
