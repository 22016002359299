import { Typography } from "antd";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
const { Title } = Typography;
interface RatingBoxType {
  position: string;
  image: string;
  name: string;
  total: number;
  bg?: string;
}
function formatNumber(number:number) {
  if (number >= 1000) {
    const formattedNumber = (number / 1000).toFixed(2);
    return formattedNumber + "K";
  } else {
    return number.toString();
  }
}
function RatingBox({ position, image, name, total, bg }: RatingBoxType) {
  return (
    <div
      style={{
        background: bg ? bg : "#fff",
        height: 72,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 15,
        borderRadius: "10px",
        marginTop: 10,
        border: "1px solid rgba(0, 0, 0, 0.06)"

      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p style={{marginBottom: 0}}># {position}</p>
        <img
          src={image}
          alt={name}
          style={{ width: 50, height: 50, margin: "0px 15px" , borderRadius: "50%"}}
        />
        <p style={{marginBottom: 0, fontWeight: 500}}>{name}</p>
      </div>
      <div>
        <img src={require("assets/img/ruby.png")} alt="Kim cương" />
        <span style={{ fontWeight: 700, color: "red" }}>{formatNumber(total)}</span>
      </div>
    </div>
  );
}

export default RatingBox;
