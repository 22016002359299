import { Card } from "antd";
import { QuestionTopic } from "type";

interface PopoverCourseType {
  total: number;
  questions: QuestionTopic[];
  unanswered: number;
}
function PopoverCourse({ questions }: PopoverCourseType) {
  return (
    <Card
      title="Chi tiết bài học"
      bordered={false}
      style={{
        padding: 0,
      }}
    >
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          maxWidth: "580px",
        }}
      >
        {questions.map((question: QuestionTopic, index: number) => {
          return (
            <div
              style={{
                width: 64,
                height: 64,
                backgroundColor: question.answered ? "#B7EB8F" : "transparent",
                borderRadius: "50%",
                marginRight:  8,
                marginBottom: 8,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid #eee",
              }}
            >
              {index + 1}
            </div>
          );
        })}
      </div>
    </Card>
  );
}

export default PopoverCourse;
