/**
 * Màn hình thêm mới trường
 */
import HeaderbackTitle from "pages/components/HeaderBackTitle";
import React, {
  Dispatch,
  SetStateAction,
  memo,
  useState,
  useEffect,
} from "react";
import {
  Layout,
  Button,
  Form,
  Space,
  Select,
  Upload,
  notification,
  Input,
  DatePicker,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import * as yup from "yup";
import TextArea from "antd/lib/input/TextArea";
import {
  AccountType,
  CourseType,
  PayloadType,
  SchoolType,
  SelectOptionType,
} from "type";
import queryString from "query-string";
import { getSchool, getSchools } from "features/schoolSlice";
import { useDispatch } from "react-redux";
import _, { values } from "lodash";
import { getUsers } from "features/userSlice";
import { RangePickerProps } from "antd/lib/date-picker";
import moment from "moment";
import {
  createClassroom,
  setFilter,
  setQuery,
  updateClassroom,
} from "features/classroomSlice";
import { getRoles, setRole } from "features/roleSlice";
import { useSelector } from "react-redux";
import { getClassroom } from "features/classroomSlice";
import { useForm } from "antd/lib/form/Form";
import { checkAnyCheckObject } from "utils";
import { useHistory, useLocation, useParams } from "react-router";
import { getCourses } from "features/courseSlice";
const { Content } = Layout;
interface CUFormStateType {
  type: "create" | "update" | "list";
  isOpen: boolean;
  tab: "school" | "classroom";
  idUpdate?: string;
}
interface FormClassroom {
  year: string;
  schoolId: string;
  courseId: string;
  className: string;
  teacherId: string;
}
interface FormType {
  name: "create" | "update";
  id: string | null;
}

function getFormType(history: any, param: any): FormType {
  if (history?.location?.pathname === "/admin/create-classroom-organization") {
    return {
      name: "create",
      id: null,
    };
  }
  if (
    history?.location?.pathname.includes("/admin/update-classroom-organization")
  ) {
    const { id } = param;
    return {
      name: "update",
      id,
    };
  }
  return {
    name: "create",
    id: null,
  };
}
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
function getYearFromUrl(url: any) {
  const match = url.match(/[?&]year=(\d{4})/);
  return match ? match[1] : null;
}
function CUSchool() {
  const dispatch = useDispatch();
  const [formUpdate] = useForm();
  const [formCreate] = useForm();
  const history = useHistory();
  const location = useLocation();

  const param = useParams();
  const yearValue: any = useParams();


  const searchParams = useQuery();
  const schoolId = searchParams.get("schoolId");
  const dataFromList = _.get(location, "state.state", {});
  const yearFromUrl = _.get(location, "state.year", "");
  const [form, setForm] = useState<FormType>(getFormType(history, param));
  const [searchVal, setSearchVal] = useState("");
  const [teacherSearchVal, setTeacherSearchVal] = useState("");
  const [schoolVal, setSchoolVal] = useState([]);
  const [courseSearchVal, setCourseSearchVal] = useState("");
  const [schoolOptions, setSchoolOptions] = useState<SelectOptionType[]>([]);
  const [courseOptions, setCourseOptions] = useState([]);
  const [teacherOptions, setTeacherOptions] = useState([]);

  const { role } = useSelector((state: any) => state.roleReducer);
  const { classroom, query, filtes } = useSelector(
    (state: any) => state.classroomReducer
  );
  //Load lại query của danh sách
  function resetQuery() {
    dispatch(
      setQuery({
        ...query,
        page: 1,
        limit: 10,
      })
    );
    dispatch(
      setFilter({
        ...filtes,
        page: 1,
        limit: 10,
      })
    );
  }
  //Gọi api lấy role admin và giáo viên
  function handleGetRole() {
    const payload: PayloadType = {
      query: queryString.stringify({
        name: "teacher",
      }),
      callback: {
        success(values) {
          const data = _.get(values, "data", []);
          if (data.length > 0) {
            dispatch(setRole(_.get(data[0], "id", "")));
          }
        },
        failed(errorMessage) {
          notification.error({
            message: "Lấy quyền của giáo viên thất bại!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getRoles(payload));
  }
  useEffect(() => {
    handleGetRole();
    handleGetTeachers();
  }, [role]);
  // Lấy danh sách trường
  // function handleGetSchools() {
  //   const payload: PayloadType = {
  //     query: queryString.stringify(
  //       {
  //         name: searchVal,
  //       },
  //       {
  //         skipEmptyString: true,
  //         skipNull: true,
  //       }
  //     ),
  //     skipUpdateReducer: true,
  //     callback: {
  //       success(schools) {
  //         const data = _.get(schools, "data", []);
  //         setSchoolOptions(
  //           data.map((item: SchoolType) => {
  //             return {
  //               label: `${item.name} - ${item?.district ?? ""}, ${item?.province ?? ""}`,
  //               value: item.id,
  //             };
  //           })
  //         );
  //       },
  //       failed(errorMessage) {
  //         notification.error({
  //           message: errorMessage,
  //         });
  //       },
  //     },
  //   };
  //   dispatch(getSchools(payload));
  // }
  // useEffect(() => {
  //   const delayBounce = setTimeout(handleGetSchools, 700);
  //   return () => clearTimeout(delayBounce);
  // }, [searchVal]);
  //Lấy danh sách khóa học
  function handleGetCourse() {
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          page: 1,
          limit: 10,
          isVisible: true, // Trạng thái bật
          fullname: courseSearchVal,
        },
        {
          skipEmptyString: true,
          skipNull: true,
        }
      ),
      skipUpdateReducer: true,
      callback: {
        success(values) {
          setCourseOptions(
            _.get(values, "data", []).map((course: CourseType) => ({
              label: course.fullname,
              value: course.id,
            }))
          );
        },
        failed(errorMessage) {},
      },
    };
    dispatch(getCourses(payload));
  }
  //Lấy danh sách giáo viên
  function handleGetTeachers() {
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          name: teacherSearchVal,
          roleId: role,
          page: 1,
          limit: 10,
          schoolId: schoolId,
        },
        {
          skipEmptyString: true,
          skipNull: true,
        }
      ),
      skipUpdateReducer: true,
      callback: {
        success(teachers) {
          const data = _.get(teachers, "data", []);
          setTeacherOptions(
            data.map((item: AccountType) => {
              return {
                label: `${item.name} - ${item.phoneNumber}`,
                value: item.id,
              };
            })
          );
        },
        failed(errorMessage) {},
      },
    };
    dispatch(getUsers(payload));
  }
  useEffect(() => {
    const delayBounce = setTimeout(() => {
      if (!!role) {
        handleGetTeachers();
      }
    }, 700);
    return () => clearTimeout(delayBounce);
  }, [teacherSearchVal]);

  useEffect(() => {
    const delayBounce = setTimeout(() => {
      if (!!role) {
        handleGetCourse();
      }
    }, 700);
    return () => clearTimeout(delayBounce);
  }, [courseSearchVal]);

  //minDate
  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Không được chọn trong khoảng thời gian đc chỉ định
    return current && current < moment().clone().subtract(1, "year");
  };
  let accountSchema = yup.object().shape({
    year: yup.string().required("Năm học không được để trống!"),
    teacherId: yup.lazy((value) => {
      switch (typeof value) {
        case "object":
          return yup.object().required("Giáo viên không được để trống!");
        case "string":
          return yup.string().required("Giáo viên không được để trống!");
        default:
          return yup.string().required("Giáo viên không được để trống!");
      }
    }),
    schoolId: yup.lazy((value) => {
      switch (typeof value) {
        case "object":
          return yup.object().required("Vui lòng chọn trường!");
        case "string":
          return yup.string().required("Vui lòng chọn trường!");
        default:
          return yup.object().required("Vui lòng chọn trường!");
      }
    }),
    courseId: yup.lazy((value) => {
      switch (typeof value) {
        case "object":
          return yup.object().required("Vui lòng chọn khoá học!");
        case "string":
          return yup.string().required("Vui lòng chọn khoá học!");
        default:
          return yup.object().required("Vui lòng chọn khoá học!");
      }
    }),
    className: yup.string().required("Tên lớp không được để trống!"),
  });
  const yupSync = {
    async validator({ field }: any, value: any) {
      await accountSchema.validateSyncAt(field, { [field]: value });
    },
  };
  function handleCreateClassroom(values: FormClassroom) {
    const payload: PayloadType = {
      body: {
        ...values,
        schoolId:
          _.get(values, "schoolId.value") || _.get(values, "schoolId", ""),
        year: moment(_.get(values, "year", moment())).get("year"),
      },
      callback: {
        success(values) {
          notification.success({
            message: "Tạo mới lớp thành công!",
          });
          resetQuery();
          history.push({
            pathname:
              `/admin/school-organization/${_.get(dataFromList, "id", "")}?year=${yearFromUrl}&tab=2`,
            state: dataFromList,
          });
        },
        failed(errorMessage) {
          if (errorMessage?.includes("duplicate key")) {
            notification.error({
              message: "Tạo mới lớp thất bại!",
              description: "Tên lớp học đã tồn tại!",
            });
            return;
          }
          notification.error({
            message: "Tạo mới lớp thất bại!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(createClassroom(payload));
  }
  //==========CẬP NHẬT========
  function handleGetSchool(id: string | null) {
    const payload: PayloadType = {
      params: id || "",
      query: queryString.stringify({}),
      callback: {
        success(values: SchoolType) {
          setSchoolOptions([
            {
              ...values,
              label: values.name,
              value: values.id,
            },
          ]);
          if (form.name === "update")
            formUpdate.setFieldValue("schoolId", {
              label: _.get(values, "name", ""),
              value: _.get(values, "id", ""),
            });
          else
            formCreate.setFieldValue("schoolId", {
              label: _.get(values, "name", ""),
              value: _.get(values, "id", ""),
            });
        },
        failed(errorMessage) {},
      },
    };
    dispatch(getSchool(payload));
  }
  function handleGetClassroom(id: string) {
    const payload: PayloadType = {
      params: id,
      query: queryString.stringify({
        populate: "schoolId,teacherId,courseId",
      }),
      callback: {
        success(values) {},
        failed(errorMessage) {},
      },
    };
    dispatch(getClassroom(payload));
  }
  useEffect(() => {
    if (form.name === "update") {
      handleGetClassroom(form?.id ?? "");
    }
    handleGetCourse();
    handleGetSchool(schoolId);
  }, []);

  useEffect(() => {
    if (!!classroom) {
      formUpdate.setFieldValue("className", _.get(classroom, "className", ""));
      formUpdate.setFieldValue(
        "year",
        moment(_.get(classroom, "year", moment().get("year")))
      );
      formUpdate.setFieldValue("teacherId", {
        label: _.get(classroom, "teacherId.name", ""),
        value: _.get(classroom, "teacherId.id", ""),
      });
      formUpdate.setFieldValue("courseId", {
        label: _.get(classroom, "courseId.fullname", ""),
        value: _.get(classroom, "courseId.id", ""),
      });
    }
  }, [classroom]);

  function handleUpdateClassroom(values: FormClassroom) {
    const oldValue = {
      year: _.get(classroom, "year", ""),
      schoolId: _.get(classroom, "schoolId.id", ""),
      className: _.get(classroom, "className", ""),
      teacherId: _.get(classroom, "teacherId.id", ""),
      courseId: _.get(classroom, "courseId.id", ""),
    };
    const newValue = {
      ...values,
      year: moment(_.get(values, "year", "")).get("year").toString(),
      schoolId: _.get(values, "schoolId.value", ""),
      teacherId: _.get(values, "teacherId.value", ""),
      courseId: _.get(values, "courseId.value", ""),
    };
    if (checkAnyCheckObject(oldValue, newValue)) {
      const payload: PayloadType = {
        params: _.get(classroom, "id", ""),
        body: newValue,
        callback: {
          success(values) {
            notification.success({
              message: "Cập nhật lớp học thành công!",
            });
            resetQuery();
            history.push({
              pathname:
                `/admin/school-organization/${_.get(dataFromList, "id", "")}?year=${yearFromUrl}&tab=2`,
              state: dataFromList,
            });
          },
          failed(errorMessage) {
            if (errorMessage?.includes("duplicate key")) {
              notification.error({
                message: "Cập nhật lớp học thất bại!",
                description: "Tên lớp học đã tồn tại!",
              });
              return;
            }
            notification.error({
              message: "Cập nhật lớp học thất bại!",
              description: errorMessage,
            });
          },
        },
      };
      dispatch(updateClassroom(payload));
    } else {
      history.push("/admin/organization");
    }
  }
  return (
    <Content className="custom-layout-content">
      <HeaderbackTitle
        title={form.name === "create" ? "Thêm mới lớp" : "Sửa thông tin lớp"}
        handleBack={() => {
          // history.push("/admin/organization");
          history.goBack();
        }}
        link={"/admin/organization"}
      />
      <Form
        form={form.name === "update" ? formUpdate : formCreate}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 8 }}
        initialValues={{ remember: true }}
        onFinish={(values) => {
          if (form.name === "create") handleCreateClassroom(values);
          else handleUpdateClassroom(values);
        }}
        onFinishFailed={() => {}}
        autoComplete="off"
        layout="horizontal"
        style={{
          marginTop: 64,
        }}
      >
        <Form.Item
          label="Năm học"
          name="year"
          required
          labelAlign="right"
          rules={[yupSync]}
        >
          <DatePicker
            picker="year"
            style={{
              width: "100%",
            }}
            disabledDate={disabledDate}
            placeholder="Chọn năm"
          />
        </Form.Item>
        <Form.Item
          label="Trường"
          name="schoolId"
          required
          labelAlign="right"
          rules={[yupSync]}
          // initialValue={schoolVal}

          style={{
            position: "relative",
          }}
        >
          <Select
            placeholder="Chọn trường"
            showSearch
            disabled
            onSearch={(str) => {
              setSearchVal(str);
            }}
            onChange={(e, option) => {
              formUpdate.setFieldValue("schoolId", option);
            }}
            options={schoolOptions}
            onClear={() => {
              setTeacherSearchVal("");
            }}
            filterOption={false}
          />
        </Form.Item>
        <Form.Item
          label="Tên lớp"
          required
          name={"className"}
          labelAlign="right"
          rules={[yupSync]}
        >
          <Input placeholder="Nhập tên lớp" />
        </Form.Item>
        <Form.Item
          label="Khóa học"
          required
          name={"courseId"}
          labelAlign="right"
          rules={[yupSync]}
        >
          <Select
            options={courseOptions}
            placeholder="Chọn khoá học"
            allowClear
            showSearch
            onSearch={(str) => {
              setCourseSearchVal(str);
            }}
            onClear={() => {
              setCourseSearchVal("");
            }}
            onChange={(e, option) => {
              formUpdate.setFieldValue("courseId", option);
            }}
            filterOption={false}
          />
        </Form.Item>
        <Form.Item
          label="Giáo viên"
          name="teacherId"
          required
          labelAlign="right"
          rules={[yupSync]}
        >
          <Select
            options={teacherOptions}
            placeholder="Chọn giáo viên"
            allowClear
            showSearch
            onSearch={(str) => {
              setTeacherSearchVal(str);
            }}
            onClear={() => {
              setTeacherSearchVal("");
            }}
            onChange={(e, option) => {
              formUpdate.setFieldValue("teacherId", option);
            }}
            filterOption={false}
          />
        </Form.Item>
        <Form.Item
          wrapperCol={{
            offset: 8,
          }}
        >
          <Space>
            <Button
              type="default"
              size="middle"
              htmlType="submit"
              onClick={() => {
                history.push("/admin/organization");
              }}
            >
              Hủy
            </Button>
            <Button type="primary" size="middle" htmlType="submit">
              {form.name === "create" ? "Thêm mới" : "Lưu lại"}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Content>
  );
}

export default memo(CUSchool);
