import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { reducer } from "features";
import rootSaga from "sagas";
const { createLogger } = require("redux-logger");
const saga = createSagaMiddleware();
const logger = createLogger({
  collapsed: true,
});
const store = configureStore({
  reducer,
  middleware: [saga,logger],
});
saga.run(rootSaga);

export default store;
//Lấy Rootstate và Appdispatch
export type RootState = ReturnType<typeof store.getState>;
export type Appdispatch = typeof store.dispatch;
