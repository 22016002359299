/**
 * Màn hình thêm mới trường
 */
import HeaderbackTitle from "pages/components/HeaderBackTitle";
import { Dispatch, SetStateAction, memo, useState, useEffect } from "react";
import {
  Layout,
  Button,
  Form,
  Space,
  Select,
  Upload,
  notification,
  Input,
  DatePicker,
  Radio,
  Row,
  Col,
  Checkbox,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import * as yup from "yup";
import TextArea from "antd/lib/input/TextArea";
import { CourseType, PayloadType, SelectOptionType } from "type";
import { useDispatch } from "react-redux";
import _ from "lodash";
import {
  createPayment,
  getPayment,
  setQuery,
  updatePayment,
} from "features/paymentSlice";
import { useHistory, useParams } from "react-router";
import { useSelector } from "react-redux";
import { useForm } from "antd/lib/form/Form";
import { getCourses } from "features/courseSlice";
import queryString from "query-string";
import { checkAnyCheckObject, checkBodyRequest } from "utils";
const { Content } = Layout;
interface FormType {
  name: "create" | "update";
  id: string | null;
}
function getFormType(history: any, param: any): FormType {
  if (history?.location?.pathname === "/admin/create-payment") {
    return {
      name: "create",
      id: null,
    };
  }
  if (history?.location?.pathname.includes("/admin/update-payment")) {
    const { id } = param;
    return {
      name: "update",
      id,
    };
  }
  return {
    name: "create",
    id: null,
  };
}
function CUPayment() {
  const [isLoading, setIsLoading] = useState(true);
  const [formValue] = useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const param = useParams();
  const [courseOtions, setCourseOptions] = useState<SelectOptionType[]>([]);
  const [courseSearchVal, setCourseSearchVal] = useState("");
  const [isUnlimited, setIsUnlimited] = useState(false);
  const [form] = useState<FormType>(getFormType(history, param));
  const { payment } = useSelector((state: any) => state?.paymentReducer);
  //khởi tạo dữ liệu form update
  useEffect(() => {
    if (form.name === "update") {
      const payload: PayloadType = {
        params: form?.id ?? "",
        query: queryString.stringify({
          populate: "courseIds",
        }),
        callback: {
          success(values) {
            if (
              form.name === "update" &&
              _.get(values, "duration", "") === -1
            ) {
              setIsUnlimited(true);
            }
            setIsLoading(false);
          },
          failed(errorMessage) {
            history.push("/admin/payment");
          },
        },
      };
      dispatch(getPayment(payload));
    }
  }, []);
  let accountUpdateSchema = yup.object().shape({
    code: yup.string().required("Mã gói học không được để trống!"),
    name: yup.string().required("Tên gói học không được để trống!"),
    enName: yup.string().required("Tên tiếng anh không được để trống!"),
    courseIds: yup
      .array()
      .required("Khóa học không được để trống!")
      .test(
        "check-empty-array",
        "Khóa học không được để trống!",
        (courseIds) => {
          return Array.isArray(courseIds) && courseIds.length > 0;
        }
      ),
    duration: yup
      .string()
      .required("Thời hạn không được để trống!")
      .test("check-num", "Thời hạn tháng phải là số dương!", (duration) => {
        const durNum = parseInt(duration);
        return durNum > 0;
      }),
    price: yup
      .string()
      .required("Giá khóa học không được để trống!")
      .test("check-num", "Giá tiền phải là số dương!", (price) => {
        const prNum = parseInt(price);
        return prNum > 0;
      }),
  });
  const yupSync = {
    async validator({ field }: any, value: any) {
      await accountUpdateSchema.validateSyncAt(field, { [field]: value });
    },
  };
  // Đặt lại pagination của danh sách
  function resetQuery() {
    dispatch(setQuery({
      page: 1,
      limit: 10,
    }))
  }
  const handleCreatePayment = (values: any) => {
    var urlencoded = new URLSearchParams();
    urlencoded.append("code", _.get(values, "code", ""));
    urlencoded.append("name", _.get(values, "name", ""));
    urlencoded.append("enName", _.get(values, "enName", ""));
    urlencoded.append(
      "duration",
      !!_.get(values, "duration", "") ? _.get(values, "duration", "") : -1
    );
    urlencoded.append("price", _.get(values, "price", ""));
    urlencoded.append("isActive", _.get(values, "isActive", ""));
    values.courseIds.map((item: SelectOptionType) => {
      urlencoded.append("courseIds[]", item.value);
    });
    const payload: PayloadType = {
      body: urlencoded,
      callback: {
        success(values) {
          resetQuery();
          notification.success({
            message: "Tạo mới gói thanh toán thành công!",
          });
          history.push("payment");
        },
        failed(errorMessage) {
          notification.error({
            message: "Tạo mới gói thanh toán thất bại!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(createPayment(payload));
  };
  const handleUpdatePayment = (values: any) => {
    const oldValue = {
      ...payment,
      courseIds: _.get(payment, "courseIds", []).map(
        (item: CourseType) => item.id
      ),
    };
    const newValue = {
      ...values,
      courseIds: _.get(values, "courseIds", []).map(
        (item: SelectOptionType) => item.value
      ),
      duration:
        _.get(values, "duration", "") === ""
          ? -1
          : _.get(values, "duration", ""),
    };
    if (checkAnyCheckObject(oldValue, newValue)) {
      var urlencoded = new URLSearchParams();
      urlencoded.append("code", _.get(values, "code", ""));
      urlencoded.append("name", _.get(values, "name", ""));
      urlencoded.append("enName", _.get(values, "enName", ""));
      urlencoded.append(
        "duration",
        !!_.get(values, "duration", "") ? _.get(values, "duration", "") : -1
      );
      urlencoded.append("price", _.get(values, "price", ""));
      urlencoded.append("isActive", _.get(values, "isActive", ""));
      values.courseIds.map((item: SelectOptionType) => {
        urlencoded.append("courseIds[]", item.value);
      });
      const payload: PayloadType = {
        params: form?.id ?? "",
        body: urlencoded,
        callback: {
          success(values) {
            notification.success({
              message: "Cập nhật gói thanh toán thành công!",
            });
            resetQuery();
            history.push("/admin/payment");
          },
          failed(errorMessage) {
            notification.error({
              message: "Cập nhật gói thanh toán thất bại!",
              description: errorMessage,
            });
          },
        },
      };
      // 
      // return;
      dispatch(updatePayment(payload));
    } else {
      history.push("/admin/payment");
    }
  };
  //Lấy danh sách các khóa học
  const handleGetCourses = () => {
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          fullname: courseSearchVal,
          page: 1,
          limit: 10,
        },
        {
          skipEmptyString: true,
          skipNull: true,
        }
      ),
      skipUpdateReducer: true,
      callback: {
        success(values) {
          const data = _.get(values, "data", []);
          let courseOption: any = [];
          if (form.name === "update") {
            // Lấy các khóa học của gói thanh toán hiện tại thêm vào option
            _.get(payment, "courseIds", []).map((item: CourseType) => {
              courseOption.push({
                label: item.fullname,
                value: item.id,
              });
            });
          }
          //Thêm các gói trong danh sách vừa gọi API vào option
          data.map((course: CourseType) => {
            if (
              !courseOption.some((i: SelectOptionType) => i.value === course.id) // Chưa có thì thêm vào
            ) {
              courseOption.push({
                label: course.fullname,
                value: course.id,
              });
            }
          });
          setCourseOptions(courseOption);
        },
        failed(errorMessage) { },
      },
    };
    dispatch(getCourses(payload));
  };
  useEffect(() => {
    const deBounce = setTimeout(handleGetCourses, 700);
    return () => {
      clearTimeout(deBounce);
    };
  }, [courseSearchVal]);
  if (isLoading && form.name === "update") {
    return <>Loading...</>;
  }
  return (
    <Content className="custom-layout-content">
      <HeaderbackTitle
        title={
          form.name === "create"
            ? "Thêm mới gói thanh toán"
            : "Sửa gói thanh toán"
        }
        handleBack={() => {
          // setForm({
          //   type: "list",
          //   isOpen: false,
          //   tab: "classroom",
          // });
        }}
        link="/admin/payment"
      />
      <Form
        form={formValue}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 8 }}
        onFinish={(values) => {
          if (form.name === "create") {
            handleCreatePayment(values);
          } else handleUpdatePayment(values);
        }}
        onFinishFailed={() => { }}
        autoComplete="off"
        layout="horizontal"
        style={{
          marginTop: 64,
        }}
      >
        <Form.Item
          label="Mã gói"
          name="code"
          required
          labelAlign="right"
          rules={[yupSync]}
          initialValue={
            form.name === "update" ? _.get(payment, "code", "") : undefined
          }
        >
          <Input placeholder="Nhập mã gói" />
        </Form.Item>
        <Form.Item
          label="Tên gói học"
          name="name"
          required
          labelAlign="right"
          rules={[yupSync]}
          initialValue={
            form.name === "update" ? _.get(payment, "name", "") : undefined
          }
        >
          <Input placeholder="Nhập tên gói học" />
        </Form.Item>
        <Form.Item
          label="Tên tiếng Anh"
          name="enName"
          required
          labelAlign="right"
          rules={[yupSync]}
          initialValue={
            form.name === "update" ? _.get(payment, "enName", "") : undefined
          }
        >
          <Input placeholder="Nhập tên gói học" />
        </Form.Item>
        <Form.Item
          label="Khóa học"
          name="courseIds"
          required
          labelAlign="right"
          rules={[yupSync]}
          initialValue={
            form.name === "update"
              ? _.get(payment, "courseIds", []).map((item: CourseType) => {
                return {
                  label: item.fullname,
                  value: item.id,
                };
              })
              : undefined
          }
        >
          <Select
            showSearch
            onSearch={(str) => {
              setCourseSearchVal(str);
            }}
            mode="multiple"
            onChange={(value, option) => {
              formValue.setFieldValue("courseIds", option);
            }}
            options={courseOtions}
            placeholder="Chọn khóa học"
            filterOption={false}
          />
        </Form.Item>
        <Form.Item required label="Thời hạn (tháng)" labelAlign="right">
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                name={"duration"}
                noStyle
                rules={isUnlimited ? [] : [yupSync]}
                initialValue={
                  form.name === "update" &&
                    _.get(payment, "duration", "") !== -1
                    ? _.get(payment, "duration", "")
                    : undefined
                }
              >
                <Input
                  type="number"
                  disabled={isUnlimited}
                  placeholder="Nhập số tháng"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item noStyle>
                <Checkbox
                  defaultChecked={
                    form.name === "update" &&
                      _.get(payment, "duration", "") === -1
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    setIsUnlimited(e.target.checked);
                    formValue.setFieldValue("duration", "");
                    formValue.validateFields(["duration"]);
                  }}
                >
                  Không giới hạn
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item required label="Giá (VNĐ)" labelAlign="right">
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                required
                name="price"
                noStyle
                rules={[yupSync]}
                initialValue={
                  form.name === "update"
                    ? _.get(payment, "price", "")
                    : undefined
                }
              >
                <Input type="number" placeholder="Nhập vào giá" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="isActive"
                noStyle
                initialValue={
                  form.name === "update"
                    ? _.get(payment, "isActive", "")
                    : false
                }
              >
                <Checkbox
                  defaultChecked={
                    form.name === "update"
                      ? _.get(payment, "isActive", "")
                      : false
                  }
                  onChange={(e) => {
                    formValue.setFieldValue("isActive", e?.target?.checked);
                  }}
                >
                  Hoạt động
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item
          wrapperCol={{
            offset: 8,
          }}
        >
          <Space>
            <Button
              type="default"
              size="middle"
              htmlType="submit"
              onClick={() => {
                history.push("/admin/payment");
              }}
            >
              Hủy
            </Button>
            <Button type="primary" size="middle" htmlType="submit">
              {form.name === "create" ? "Thêm mới" : "Cập nhật"}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Content>
  );
}

export default memo(CUPayment);
