import { createSlice } from "@reduxjs/toolkit";
import { DEFAULT_PROVICE_FILTER_ID } from "const";
import _ from "lodash";
const initialState : any = {
  account: {},
  currentAccountLogin: {},
  accounts: [],
  userAccount: [],
  classroomsAccounts: [],
  query: {
    page: 1,
    limit: 10,
  },
  queryOtherGroup: {
    page: 1,
    limit: 10,
  },
  queryClassroom: {
    page: 1,
    limit: 10,
  },
  filters: null,
  //permission
  isAdmin: false,
  isTeacher: false,
}
export const accountSlice = createSlice({
  name: "accountSlice",
  initialState: initialState,
  reducers: {
    forgotPass: (state, action) => {},
    getAccounts: (state, action) => {},
    getAccount: (state, action) => {},
    getClassroomAccounts: (state, action) => {},
    getClassroomAccountsSuccess: (state, action) => {
      state.classroomsAccounts = action.payload;
    },
    getAccountsSuccess: (state, action) => {
      state.accounts = action.payload;
    },
    setQuery: (state, action) => {
      state.query = action.payload;
    },
    setQueryOtherGroup: (state, action) => {
      state.queryOtherGroup = action.payload;
    },
    setAccount: (state, action) => {
      state.account = action.payload;
    },
    createAccount: (state, action) => {
      state.account = action.payload;
    },

    createUserAccount: (state, action) => {
      state.userAccount = action.payload;
    },
    updateClassroomAccount: (state, action) => {
      state.account = action.payload;
    },

    deleteClassroomAccount: (state, action) => {
      state.account = action.payload;
    },
    updateAccount: (state, action) => {
      state.account = action.payload;
    },
    setQueryClassroom: (state, action) => {
      state.queryClassroom = action.payload;
    },
    accountLogin: (state, action) => {},
    accountLoginSuccess: (state, action) => {
      
      state.account = action.payload;
      state.currentAccountLogin = action.payload;
    },
    changePassword: (state, action) => {},
    setFilter: (state, action) => {
      state.filters = action.payload;
    },
    setRole: (state, action) => {
      state.isAdmin = _.get(action, "payload.isAdmin", "");
      state.isTeacher = _.get(action, "payload.isTeacher", "");
    },
    resetPass: (state, action) => {},
    resetFilter: (state, action) => {
      state.filters = {
        province_id : DEFAULT_PROVICE_FILTER_ID
      }
    },
  },
});
export const {
  accountLogin,
  accountLoginSuccess,
  changePassword,
  getAccounts,
  getAccountsSuccess,
  setQuery,
  setQueryOtherGroup,
  forgotPass,
  resetPass,
  setFilter,
  getClassroomAccounts,
  getClassroomAccountsSuccess,
  setAccount,
  setRole,
  getAccount,
  createAccount,
  updateClassroomAccount,
  deleteClassroomAccount,
  createUserAccount, 
  updateAccount,
  resetFilter
} = accountSlice.actions;
export default accountSlice.reducer;
