import accountReducer from "features/accountSlice";
import userReducer from "features/userSlice";
import roleReducer from "features/roleSlice";
import schoolReducer from "features/schoolSlice";
import classroomReducer from "features/classroomSlice";
import provinceReducer from "features/provinceSlice";
import districtReducer from "features/districtSlice";
import awardReducer from "features/awardSlice";
import courseReducer from "features/courseSlice";
import paymentReducer from "./paymentSlice";
import organizationReducer from "features/organizationSlice";
import userScreenReducer from "features/userScreenSlice";
import diamondReducer from "features/diamondSlice"

export const reducer = {
  accountReducer,
  userReducer,
  roleReducer,
  schoolReducer,
  classroomReducer,
  provinceReducer,
  districtReducer,
  awardReducer,
  courseReducer,
  paymentReducer,
  organizationReducer,
  userScreenReducer, 
  diamondReducer
};
