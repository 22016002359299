import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

export const courseSlice : any = createSlice({
  name: "courseSlice",
  initialState: {
    courses: [],
    query: {
      page: 1,
      limit: 10,
      // year: moment().get("year"),
      // populate: 'schoolId,teacherId'
    },
    course: {},
    filters : null,
    isUpdateForm: false,
  },
  reducers: {
    getCourses: (state, action) => {},
    getCourse: (state, action) => {},
    getCoursesSuccess: (state, action) => {
      state.courses = action.payload;
    },
    getCourseSuccess: (state, action) => {
      state.course = action.payload;
    },
    setQuery: (state,action) => {
      state.query = action.payload;
    },
    setFilter: (state,action) => {
      state.filters = action.payload;
    },
    setStateCourse: (state: any,action) => {
      Object.keys(action.payload).map(key => {
        state[`${key}`] = action.payload[`${key}`]
      })
    },
    createCourse: (state,action) => {},
    updateCourse: (state,action) => {},
    deleteCourse: (state,action) => {},
  },
});
export const { getCourses,getCourse,updateCourse, getCoursesSuccess,getCourseSuccess,setQuery,setFilter,createCourse,deleteCourse,setStateCourse } = courseSlice.actions;
export default courseSlice.reducer;
