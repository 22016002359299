import { createSlice } from "@reduxjs/toolkit";

export const diamondSlice: any = createSlice({
  name: "diamondSlice",
  initialState: {
    attendances: [],
    diamonds: [],
    query: {},
    filters: null,
  },
  reducers: {
    getDiamonds: (state, action) => {},
    getDiamondSuccess: (state, action) => {
      state.diamonds = action.payload;
    },
    getAttendances: (state, action) => {},
    getAttendancesSuccess: (state, action) => {
      state.attendances = action.payload;
    },
    setQuery: (state, action) => {
      state.query = action.payload;
    },
    setFilter: (state, action) => {
      state.filters = action.payload;
    },
  },
});
export const {
  getDiamonds,
  getDiamondSuccess,
  getAttendances,
  getAttendancesSuccess,
  setQuery,
  setFilter
} = diamondSlice.actions;
export default diamondSlice.reducer;
