import React, { memo, useEffect } from "react";
import { Layout } from "antd";
import SideBar from "components/Sidebar/Sidebar";
import AdminHeader from "components/Headers/AdminHeader";
import { Redirect, Route } from "react-router";
import routes from "routes";
import { Content } from "antd/lib/layout/layout";
import { useDispatch } from "react-redux";
import { accountLoginSuccess } from "features/accountSlice";
const getRoutes = (routes: any) => {
  return routes.map((prop: any, key: any) => {
    if (prop.collapse) {
      return getRoutes(prop.views);
    }
    if (prop.layout === "/admin") {
      return (
        <Route
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
        />
      );
    } else {
      return null;
    }
  });
};
const Admin: React.FC = () => {
  
  if (!localStorage.getItem("accountInfo") || !localStorage.getItem("tokens")) {
    return <Redirect to="/auth/login" />;
  }
  
  return (
    <Layout>
      <AdminHeader />
      <Layout
        style={{
          height: "calc(100vh)",
        }}
      >
        <SideBar />
        <Content
          style={{
            marginLeft: 24,
            marginTop: 16,
            marginRight: 24,
            backgroundColor: 'white'
          }}
        >
          {getRoutes(routes.routesSM)}
        </Content>
      </Layout>
    </Layout>
  );
};

export default memo(Admin);
