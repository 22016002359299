/* eslint-disable react-hooks/exhaustive-deps */
/**
 * Tab Nhóm khác
 */
import { useState, useRef, useEffect } from "react";
import { Button, Table, Typography, notification } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import type { InputRef } from "antd";
import { Input, Space } from "antd";
import type { ColumnType } from "antd/es/table";
import type { FilterConfirmProps } from "antd/es/table/interface";
import moment from "moment";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { PayloadType } from "type";
import { getAccounts, setQueryOtherGroup, setQuery } from "features/accountSlice";
import queryString from "query-string";
import _ from "lodash";
import { useHistory } from "react-router";
import Filters from "../AllUser/Filters";
const Highlighter = require("react-highlight-words");
const { Title } = Typography;
interface DataType {
  code: string;
  username: string;
  fullname: string;
  gender: string;
  personage: string;
  diamond: string;
  courseid: string;
  membership: string;
  parent_code: string;
  paid_expired: string;
  confirmed: string;
  avatar: string;
  createdAt: string;
  updatedAt: string;
  email: string;
  belongToSchool: boolean;
  id: string;
}
type DataIndex = keyof DataType;
interface IOtherGroup {
  tabActive: string;
}
function OtherGroup({ tabActive }: IOtherGroup) {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [tableKey, setTableKey] = useState<number>(50);
  const searchInput = useRef<InputRef>(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { queryOtherGroup, accounts,query } = useSelector((state: any) => state.accountReducer);
  //Lấy danh sách tài khoản
  function getAccountsOtherGroup() {
    if (tabActive !== "1") return;
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          ...queryOtherGroup,
          belongToSchool: false,
          year: "",
        },
        {
          skipEmptyString: true,
          skipNull: true,
        }
      ),
      skipUpdateReducer: false,
      callback: {
        success(values) { },
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getAccounts(payload));
  }
  useEffect(() => {
    getAccountsOtherGroup();
    return () => {
      //reset query khi thoát khỏi CRUD users
      const pathname = history.location.pathname;
      if (pathname !== '/admin/users' && !pathname.includes('/admin/user-all')) {
        dispatch(setQueryOtherGroup({
          page: 1,
          limit: 10,
        }));
      }
    }
  }, [queryOtherGroup, tabActive]);
  useEffect(() => {
    //reset filter khi thay đổi tab
    //mà vẫn còn query.
    setTableKey(tableKey + 1);
    if (tabActive !== '0' && (!!queryOtherGroup?.fullname || !!queryOtherGroup?.phone)) {
      dispatch(setQueryOtherGroup({
        page: 1,
        limit: 10,
      }))
    }
  }, [tabActive])
  // lấy nhãn
  const getPlaceholder = (key: DataIndex) => {
    if (key == 'fullname') return "tên học sinh";
    if (key == 'username') return "số điện thoại";
    return "";
  }
  //cột và định nghĩa ô tìm kiếm
  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Tìm kiếm theo ${getPlaceholder(dataIndex)}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Đặt lại
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns: ColumnsType<DataType> = [
    {
      title: "Tên học sinh",
      width: 150,
      dataIndex: "fullname",
      key: "fullname",
      fixed: "left",
      ...getColumnSearchProps("fullname"),
    },
    {
      title: "Số điện thoại",
      width: 100,
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      fixed: "left",
      ...getColumnSearchProps("username"),
      render: (val, record) => {
        return (
          <>{!!val ? val.replace(/(\d{4})(\d{3})(\d{3})/, "$1 $2 $3") : ""}</>
        );
      },
    },
    {
      title: "Ngày tạo",
      width: 200,
      dataIndex: "createdAt",
      key: "createdAt",
      fixed: "left",
      render(value, record, index) {
        return moment(value).format("DD/MM/YYYY");
      },
    },
  ];
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    let newQuery = {}
    switch (dataIndex) {
      case 'fullname':
        newQuery = { fullname: selectedKeys[0] }
        break;
      case 'username':
        newQuery = { phone: selectedKeys[0] }
        break;

      default:
        break;
    }
    dispatch(
      setQueryOtherGroup({
        ...queryOtherGroup,
        ...newQuery,
        page: 1,
      })
    );
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };
  return (
    <>
      <div
        className="space-between"
        style={{
          marginTop: 24,
          marginBottom: 24,
        }}
      >
        <Title level={5}>Danh sách</Title>
      </div>
      <Table
        key={tableKey}
        columns={columns}
        dataSource={_.get(accounts, "data", [])}
        pagination={{
          current: _.get(accounts, "page", 1),
          total: _.get(accounts, "totalResults", 0),
          showTotal: (total, range) => {
            return `Có tất cả ${total} bản ghi`;
          },
          onChange(page, pageSize) {
            dispatch(
              setQueryOtherGroup({
                ...queryOtherGroup,
                page: page,
                limit: pageSize,
              })
            );
          },
        }}
        scroll={{ x: 1300, y: 900 }}
      />
    </>
  );
}

export default OtherGroup;
