/**
 * Màn hình thêm mới trường
 */
import HeaderbackTitle from "pages/components/HeaderBackTitle";
import React, { memo, useState, useEffect, useMemo, Fragment } from "react";
import {
  Layout,
  Button,
  Form,
  Space,
  notification,
  Input,
  DatePicker,
  Radio,
  Spin,
} from "antd";
import * as yup from "yup";
import { CourseType, PayloadType } from "type";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { createCourse, getCourses, updateCourse } from "features/courseSlice";
import { useHistory, useLocation, useParams } from "react-router";
import { useSelector } from "react-redux";
import { useForm } from "antd/lib/form/Form";
import {
  createAccount,
  getAccount,
  setQuery,
  updateAccount,
} from "features/accountSlice";
import queryString from "query-string";
import moment from "moment";
import { RangePickerProps } from "antd/lib/date-picker";
import { updateUser } from "features/userSlice";
import TextArea from "antd/lib/input/TextArea";
const { Content } = Layout;

function getFormType(history: any, param: any): any {
  if (history?.location?.pathname.includes("admin/student-update")) {
    const { id } = param;
    return {
      name: "update",
      id,
    };
  }
  
}
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function UStudent() {
  const [formUpdate] = useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const param = useParams();
  const searchParams = useQuery();
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState<any>(getFormType(history, param));
  const { query } = useSelector((state: any) => state.accountReducer);
  const [courseOptions, setCourseOptions] = useState([]);
  const [paidDataCourse, setPaidDataCourse] = useState([]);
  const [newPass, setNewPass] = useState("");
  const [courseList, setCourseList] = useState([]);
  //Lấy danh sách khóa học
  function handleGetCourse() {
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          isVisible: true,
        },
        {
          skipEmptyString: true,
          skipNull: true,
        }
      ),
      skipUpdateReducer: true,
      callback: {
        success(values) {
          const options = _.get(values, "data", []).map(
            (course: CourseType) => ({
              label: course.fullname,
              value: [course?.id ?? null, course?.moodleCourseId ?? null],
            })
          );
          setCourseOptions(options);
          handleGetAccount(options);
          setCourseList(_.get(values, "data", []));
        },
        failed(errorMessage) {},
      },
    };
    dispatch(getCourses(payload));
  }
  useEffect(() => {
    setIsLoading(true); 
    handleGetCourse();
  }, [form]);
  function handleGetAccount(options: any) {
      const payload: PayloadType = {
        params: form?.id ?? "",
        callback: {
          success(account) {
            let paidData = _.get(account, "paid_data", "[]");
            paidData = !!paidData ? JSON.parse(paidData) : [];
            //Lấy ra các khoá học có trong danh sách khoá học mặc định
            formUpdate.setFieldValue(
              "paid_data",
              options.map((option: any) => {
                const paid = _.reduce(
                  paidData,
                  (p: any, pI: any) => {
                    const isP = option.value.includes(
                      _.get(pI, "course_id", "")
                    );
                    // Chưa tìm thấy khoá học
                    // Hoặc tìm thấy khoá học rồi nhưng time lớn hơn
                    if (
                      (isP && !p) ||
                      (isP && _.get(pI, "time", -1) < p?.time)
                    ) {
                      p = pI;
                    }
                    return p;
                  },
                  null
                );

                const time = _.get(paid, "time", -1);
                const name = _.get(option, "label", "");
                const course_id = _.get(option, "value[0]", "");

                formUpdate.setFieldValue(course_id, {
                  name,
                  time: time > 0 ? moment.unix(time) : undefined,
                });
                return {
                  course_id,
                  name,
                  time: time > 0 ? moment.unix(time) : undefined,
                };
              })
            );
            formUpdate.setFieldValue(
              "fullname",
              _.get(account, "fullname", "")
            );
            formUpdate.setFieldValue(
              "parent_code",
              _.get(account, "parent_code", "")
            );
            formUpdate.setFieldValue("gender", _.get(account, "gender", ""));
            setPaidDataCourse(paidData);
            setIsLoading(false); 
          },
          failed(errorMessage) {
            setIsLoading(false); 
            // history.push("/admin/course");
          },
        },
      };
      dispatch(getAccount(payload));
  }
  let accountUpdateSchema = useMemo(() => {
    if (form.name === "create") {
      return yup.object().shape({
        fullname: yup.string().required("Tên học sinh không được để trống!"),
        password: yup
          .string()
          .matches(/^[0-9]+$/, "Mật khẩu chỉ bao gồm chữ số!")
          .min(8, "Mật khẩu phải dài ít nhất 8 chữ số!")
          .nullable(),
        passwordConfirm: yup
          .string()
          .required("Vui lòng xác nhận lại mật khẩu!")
          .test(
            "check-confirm-pass",
            "Mật khẩu không khớp!",
            (newConfirmPass) => {
              return newConfirmPass === newPass;
            }
          ),
        phoneNumber: yup
          .string()
          .required("Số điện thoại không được để trống!")
          .test(
            "check-phone",
            "Số điện thoại không đúng định dạng!",
            (phone) => {
              const reg = /0[1-9][0-9]{8}/g;
              return reg.test(phone) && phone.length === 10;
            }
          ),
      });
    }
    return yup.object().shape({
      fullname: yup.string().required("Tên học sinh không được để trống!"),
      password: yup
        .string()
        .matches(/^[0-9]+$/, "Mật khẩu chỉ bao gồm chữ số!")
        .min(8, "Mật khẩu phải dài ít nhất 8 chữ số!")
        .nullable(),
      parent_code: yup
        .string()
        .required("Mã phụ huynh không được để trống!")
        .min(4, "Mã phụ huynh phải dài ít nhất 4 chữ số!")
        .matches(/^[0-9]+$/, "Mã phụ huynh chỉ bao gồm chữ số!"),
      gender: yup.string().required("Giới tính không được để trống!"),
    });
  }, [newPass, form.name]);
  const yupSync = useMemo(() => {
    return {
      async validator({ field }: any, value: any) {
        await accountUpdateSchema.validateSyncAt(field, { [field]: value });
      },
    };
  }, [accountUpdateSchema]);

  //reset lại pagination của danh sách
  function resetQuery() {
    dispatch(
      setQuery({
        ...query,
        page: 1,
        limit: 10,
      })
    );
  }
  function onSubmit(values: any) {
   

    const newPaidData = paidDataCourse
      .map((paid: any) => {
        const courseId = _.get(paid, "course_id", "");
        //Trường hợp courseId cách nhau bằng dấu phẩy.
        if (courseId.includes(",")) {
          return null;
        }
        const courseOption = _.find(courseOptions, (course: any) => {
          return course.value.includes(courseId);
        });
        const courseOptionId = _.get(courseOption, "value[0]", "");
        const time = _.get(values, `[${courseOptionId}].time`, -1);

        return {
          // ...paid,
          course_id: courseOptionId,
          time: time > 0 ? moment(time).unix() : null,
        };
      })
      .filter((course: any) => !!_.get(course, "time", ""));

    //Xử lý nhưng course có ở ds khoá học nhưng
    //không có trong paid_data.
    courseList.map((course: any) => {
      const hasCourse = _.some(newPaidData, {
        course_id: _.get(course, "id", ""),
      });
      const time = _.get(values, `${course?.id ?? ""}.time`, -1);
      if (!hasCourse && time > 0) {
        newPaidData.push({
          course_id: course?.id ?? "",
          time: moment(time).unix(),
        });
      }
    });
    const fullname = _.get(values, "fullname", "");
    const password = _.get(values, "password", "");
    const body: any = {
      firstname: _.get(fullname.split(" "), "[0]", ""),
      lastname: _.get(fullname.split(" "), "[1]", ""),
      fullname,
      parent_code: _.get(values, "parent_code", ""),
      gender: _.get(values, "gender", ""),
      paid_data: JSON.stringify(newPaidData),
    };
    if (!!password) {
      body["password"] = password;
    }
    if (!body.firstname) {
      delete body["firstname"];
    }
    if (!body.lastname) {
      delete body["lastname"];
    }
    const payload: PayloadType = {
      params: form?.id ?? "",
      body,
      callback: {
        success(values) {
          notification.success({
            message: "Cập nhật thông tin tài khoản học sinh thành công!",
          });
          resetQuery();
          history.push("/admin/student");
        },
        failed(errorMessage) {
          notification.error({
            message: "Cập nhật thông tin tài khoản học sinh thất bại!",
            description: errorMessage,
          });
        },
      },
    };

    dispatch(updateAccount(payload));
  }
  //minDate
  // const disabledDate: RangePickerProps["disabledDate"] = (current) => {
  //   // Không được chọn trong khoảng thời gian đc chỉ định
  //   return current && current < moment().startOf("day");
  // };
  return (
    <>
    {!isLoading ? (
    <Content className="custom-layout-content">
      <HeaderbackTitle
        title="Sửa tài khoản người dùng"
        handleBack={() => {
          // setForm({
          //   type: "list",
          //   isOpen: false,
          //   tab: "classroom",
          // });
        }}
        link="/admin/student"
      />
      <Form
        colon={false}
        form={ formUpdate}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 10 }}
        onFinish={(values) => {
          onSubmit(values);
        }}
        onFinishFailed={() => {}}
        autoComplete={"off"}
        layout="horizontal"
        style={{
          marginTop: 64,
        }}
      >
        <Form.Item
          label="Tên học sinh"
          name="fullname"
          required
          labelAlign="right"
          rules={[yupSync]}
        >
          <Input placeholder="Nhập tên học sinh" />
        </Form.Item>

        {_.isArray(formUpdate.getFieldValue("paid_data")) &&
          formUpdate.getFieldValue("paid_data").map((paid: any) => {
            const course_id = _.get(paid, "course_id", "");
            return (
              <Form.Item
                label={<span></span>}
                wrapperCol={{
                  span: 10,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <label
                    style={{
                      position: "absolute",
                      right: "100%",
                      top: "20%",
                      verticalAlign: "center",
                      minWidth: "100px",
                      marginBottom: 0,
                      paddingRight: 8,
                      textAlign: "end",
                    }}
                  >
                    Khoá học{" "}
                    <span
                      style={{
                        color: "red",
                      }}
                    >
                      *
                    </span>
                  </label>
                  <Form.Item
                    noStyle
                    name={[course_id, "name"]}
                    label="Khoá học"
                    required
                    labelAlign="right"
                    style={{
                      display: "inline-block",
                      // width: "calc(50% - 8px)",
                    }}
                  >
                    <Input disabled placeholder="Nhập khoá học" />
                  </Form.Item>
                  <label
                    style={{
                      minWidth: "100px",
                      marginBottom: 0,
                      paddingRight: 4,
                      textAlign: "end",
                    }}
                  >
                    Ngày hết hạn{" "}
                    
                  </label>
                  <Form.Item
                    noStyle
                    name={[course_id, "time"]}
                    labelAlign="right"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: `Ngày hết hạn ${name} không được để trống!`,
                    //   },
                    // ]}
                    style={{
                      display: "inline-block",
                      // width: "calc(50% - 8px)",
                      margin: "0 16px",
                    }}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      // disabledDate={disabledDate}
                      format={"DD/MM/YYYY"}
                      placeholder="Chọn ngày hết hạn"
                    />
                  </Form.Item>
                </div>
              </Form.Item>
            );
          })}

        <Form.Item
          label="Mật khẩu "
          name="password"
          labelAlign="right"
          rules={[yupSync]}
        >
          <Input.Password
            placeholder="Nhập mật khẩu mới hoặc bỏ trống"
            onChange={(e) => {
              setNewPass(_.get(e, "target.value", ""));
            }}
          />
        </Form.Item>

        <Fragment>
          <Form.Item
            required
            label="Mã phụ huynh"
            name="parent_code"
            labelAlign="right"
            rules={[yupSync]}
          >
            <Input placeholder="Nhập mã phụ huynh" />
          </Form.Item>
          <Form.Item name="gender" label="Giới tính" required rules={[yupSync]}>
            <Radio.Group>
              <Radio value="0">Nam</Radio>
              <Radio value="1">Nữ</Radio>
              <Radio value="-1">Khác</Radio>
            </Radio.Group>
          </Form.Item>
        </Fragment>

        <Form.Item
          wrapperCol={{
            offset: 8,
          }}
        >
          <Space>
            <Button
              type="default"
              size="middle"
              onClick={() => {
                history.push("/admin/student");
              }}
            >
              Hủy
            </Button>
            <Button type="primary" size="middle" htmlType="submit">
              {"Lưu lại"}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Content>
    ): (
      <Spin tip="Đang cập nhật..." size="small" style={{ marginTop: "20%" }}>
        <div className="content" />
      </Spin>
    )}
    </>
  );
}

export default memo(UStudent);
