import { BASE_URL } from "services/ServiceURL";
import _ from "lodash";
import axios from "axios";

export async function refreshTokens() {
  const url = BASE_URL + "/auth/refresh-tokens";
  const tokens = JSON.parse(localStorage.getItem("tokens") || "");
  if (!!tokens) {
    const refreshToken = _.get(tokens, "refresh.token", null);
    const res = await axios
      .post(url, {
        refreshToken: refreshToken,
      })
      .then((res) => {})
      .catch((err) => {
        window.history.pushState("", "", "/auth/login");
        window.location.reload();
      });
    return _.get(res, "data", {});
  }
  return null;
  // localStorage.setItem('refreshtoken', res.data.refresh.token);
  // window.location.reload(false);
}
export default refreshTokens;
