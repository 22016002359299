import { all, fork } from "redux-saga/effects";
import { accountSaga } from "sagas/accountSaga";
import userSaga from "./userSaga";
import roleSaga from "./roleSaga";
import schoolSaga from "./schoolSaga";
import classroomSaga from "./classroomSaga";
import provinceSaga from "./provinceSaga";
import districtSaga from "./districtSaga";
import awardSaga from "./awardSaga";
import courseSaga from "./courseSaga";
import paymentSaga from "./paymentSaga";
import diamondSaga from "./diamondSaga";
function* rootSaga() {
  yield all([fork(accountSaga)]);
  yield all([fork(userSaga)]);
  yield all([fork(roleSaga)]);
  yield all([fork(schoolSaga)]);
  yield all([fork(classroomSaga)]);
  yield all([fork(provinceSaga)]);
  yield all([fork(districtSaga)]);
  yield all([fork(awardSaga)]);
  yield all([fork(courseSaga)]);
  yield all([fork(paymentSaga)]);
  yield all([fork(diamondSaga)]);
}
export default rootSaga;
