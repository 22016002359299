import React, { useState, useEffect, memo } from "react";
import routes from "routes";
import { Layout, Menu, MenuProps } from "antd";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { checkRole } from "utils";
const { Sider } = Layout;
type MenuItem = Required<any>["items"][string];

function getItem(
  label: React.ReactNode,
  key?: string | null,
  icon?: React.ReactNode,
  children?: MenuItem[] | null,
  hidden?: boolean
): MenuItem {
  if (!key?.includes("/")) {
    return {
      label,
      key,
      icon,
      children,
    } as MenuItem;
  }
  return {
    label: <Link to={key ?? ""}>{label}</Link>,
    key,
    icon,
    children,
  } as MenuItem;
}
let rootSubmenuKeys: any[] = [];
function getRoutes(routeGroup: any[], currentAccountLogin: any) {
  return routeGroup.map((route: any) => {
    let r = [];
    if (route?.hidden || !checkRole(currentAccountLogin, route)) {
      return null;
    }
    if (route?.views) {
      rootSubmenuKeys.push(route?.state ?? "");
      r = route.views.map((children: any) => {
        return getItem(
          children?.name ?? "",
          children?.layout + children?.path,
          null,
          null
        );
      });
      return getItem(
        route?.name ?? "",
        route?.state ?? "",
        route?.icon ?? "",
        r
      );
    }
    return getItem(
      route?.name ?? "",
      route?.layout + route?.path,
      route?.icon ?? "",
      null
    );
  });
}

function getCurrentSelectedKeys(routesArr: any[], pathname: any) {
  let routeTemp = null;
  const routeActive = routesArr.find((item) => {
    if (item.collapse && item.state && item.views) {
      routeTemp = [item.state];
      const result = getCurrentSelectedKeys(item.views, pathname);
      if (!!result) {
        routeTemp.unshift(...result);
        return false;
      }
      routeTemp = null;
      return false;
    }
    return (item?.layout ?? "") + item.path === pathname;
  });
  if (!!routeTemp) {
    return routeTemp;
  }
  if (!!routeActive?.parent) {
    return [routeActive.parent];
  }

  return [(routeActive?.layout ?? "") + routeActive?.path];
}
const SideBar = () => {
  const history = useHistory();
  const { currentAccountLogin } = useSelector(
    (state: any) => state?.accountReducer
  );
  const routeActive = getCurrentSelectedKeys(
    routes.routesSM,
    history.location.pathname
  );
  const items1: any = [...getRoutes(routes.routesSM, currentAccountLogin)];

  const [openKeys, setOpenKeys] = useState<string[]>(
    // currentRoute ? JSON.parse(currentRoute) : []
    routeActive
  );
  const onOpenChange: MenuProps["onOpenChange"] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };
  return (
    <>
      <Sider
        width={256}
        style={{
          backgroundColor: "white",
        }}
      >
        <Menu
          mode="inline"
          style={{ flex: 1, borderRight: 0 }}
          triggerSubMenuAction="hover"
          multiple={false}
          items={items1}
          selectedKeys={openKeys}
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          onClick={({ key, keyPath }) => {
            localStorage.setItem("currentRoute", JSON.stringify(keyPath));
            setOpenKeys(keyPath);
          }}
        />
      </Sider>
    </>
  );
};

export default memo(SideBar);
