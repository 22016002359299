/**
 * Màn hình thêm mới trường
 */
import HeaderbackTitle from "pages/components/HeaderBackTitle";
import { Dispatch, SetStateAction, memo, useState, useEffect } from "react";
import {
  Layout,
  Button,
  Form,
  Space,
  Select,
  Upload,
  notification,
  Input,
  DatePicker,
  Radio,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import * as yup from "yup";
import TextArea from "antd/lib/input/TextArea";
import { PayloadType } from "type";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { createCourse, getCourse, updateCourse } from "features/courseSlice";
import { useHistory, useParams } from "react-router";
import { useSelector } from "react-redux";
import { useForm } from "antd/lib/form/Form";
import { setQuery } from "features/courseSlice";
const { Content } = Layout;
interface FormType {
  name: "create" | "update";
  id: string | null;
}
function getFormType(history: any, param: any): FormType {
  if (history?.location?.pathname === "/admin/create-course") {
    return {
      name: "create",
      id: null,
    };
  }
  if (history?.location?.pathname.includes("/admin/update-course")) {
    const { id } = param;
    return {
      name: "update",
      id,
    };
  }
  return {
    name: "create",
    id: null,
  };
}
function CUCourse() {
  const [formUpdate] = useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const param = useParams();
  const [form, setForm] = useState<FormType>(getFormType(history, param));
  useEffect(() => {
    if (form.name === "update") {
      
      const payload: PayloadType = {
        params: form?.id ?? "",
        callback: {
          success(course) {
            formUpdate.setFieldValue("fullname", _.get(course, "fullname", ""));
            formUpdate.setFieldValue(
              "shortname",
              _.get(course, "shortname", "")
            );
            formUpdate.setFieldValue(
              "isVisible",
              _.get(course, "isVisible", "")
            );
            formUpdate.setFieldValue("summary", _.get(course, "summary", ""));
          },
          failed(errorMessage) {
            history.push("/admin/course");
          },
        },
      };
      dispatch(getCourse(payload));
    }
  }, [form]);
  let accountUpdateSchema = yup.object().shape({
    shortname: yup.string().required("Tên viết tắt không được để trống!"),
    fullname: yup.string().required("Tên đầy đủ không được để trống!"),
  });
  const yupSync = {
    async validator({ field }: any, value: any) {
      await accountUpdateSchema.validateSyncAt(field, { [field]: value });
    },
  };
  //Đặt lại pagination của danh sách
  function resetQuery(){
    dispatch(setQuery({
      page: 1,
      limit: 10,
    }))
  }
  const handleCreateCourse = (values: any) => {
    const payload: PayloadType = {
      body: {
        ...values,
        summary: !!_.get(values,"summary","") ? _.get(values,"summary",""): "",
      },
      callback: {
        success(values) {
          notification.success({
            message: "Tạo mới khoá học thành công!",
          });
          resetQuery();
          history.push("course");
        },
        failed(errorMessage) {
          notification.error({
            message: "Tạo mới khoá học thất bại!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(createCourse(payload));
  };
  const handleUpdateCourse = (values: any) => {
    const payload: PayloadType = {
      params: form?.id ?? "",
      body: values,
      callback: {
        success(values) {
          notification.success({
            message: "Cập nhật khoá học thành công!",
          });
          resetQuery();
          history.push("/admin/course");
        },
        failed(errorMessage) {
          notification.error({
            message: "Cập nhật khoá học thất bại!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(updateCourse(payload));
  };
  return (
    <Content className="custom-layout-content">
      <HeaderbackTitle
        title={form.name === "create" ? "Thêm mới khóa học" : "Sửa khóa học"}
        handleBack={() => {
          // setForm({
          //   type: "list",
          //   isOpen: false,
          //   tab: "classroom",
          // });
        }}
        link="/admin/course"
      />
      <Form
        form={form.name === "update" ? formUpdate : undefined}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 8 }}
        onFinish={(values) => {
          if (form.name === "create") {
            handleCreateCourse(values);
          } else handleUpdateCourse(values);
        }}
        onFinishFailed={() => {}}
        autoComplete="off"
        layout="horizontal"
        style={{
          marginTop: 64,
        }}
      >
        <Form.Item
          label="Tên viết tắt"
          name="shortname"
          required
          labelAlign="right"
          rules={[yupSync]}
        >
          <Input placeholder="Nhập tên viết tắt" />
        </Form.Item>
        <Form.Item
          label="Tên đầy đủ"
          name="fullname"
          required
          labelAlign="right"
          rules={[yupSync]}
        >
          <Input placeholder="Nhập tên đầy đủ" />
        </Form.Item>
        <Form.Item
          label="Hiển thị khóa học"
          name="isVisible"
          labelAlign="right"
          initialValue={true}
        >
          <Radio.Group defaultValue={true}>
            <Radio value={true}>Bật</Radio>
            <Radio value={false}>Tắt</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="Mô tả" name="summary" labelAlign="right">
          <TextArea rows={3} placeholder="Nhập mô tả" maxLength={200} />
        </Form.Item>
        <Form.Item
          wrapperCol={{
            offset: 8,
          }}
        >
          <Space>
            <Button
              type="default"
              size="middle"
              htmlType="submit"
              onClick={() => {
                history.push("/admin/course");
              }}
            >
              Hủy
            </Button>
            <Button type="primary" size="middle" htmlType="submit">
              {form.name === "create" ? "Thêm mới" : "Cập nhật"}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Content>
  );
}

export default memo(CUCourse);
