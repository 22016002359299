/**
 * Màn hình danh sách người dùng
 */
import React, { memo, useEffect, useMemo, useState } from "react";
import { Button, Layout, Tabs, TabsProps, Typography } from "antd";
import { Space } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Classroom from "pages/Organization/Classroom";
import CUSchool from "pages/Organization/School/CUSchool";
import CUClassroom from "pages/Organization/Classroom/CUClassroom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setTab } from "features/organizationSlice";
import Rschool from "pages/Organization/School/RSchool/RSchool";
import Teacher from "pages/Teacher";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import _ from "lodash";
import axios, { AxiosRequestConfig } from "axios";
import queryString from "query-string";
import moment from "moment";
import HeaderbackTitle from "pages/components/HeaderBackTitle";
var fileDownload = require("js-file-download");
const { Title } = Typography;
const { Content } = Layout;
interface CUFormStateType {
  type: "create" | "update" | "list";
  isOpen: boolean;
  tab: "school" | "classroom";
  idUpdate?: string;
}

function SchoolDetail() {
  const dispatch = useDispatch();
  const query = useQuery();
  const history = useHistory();
  const location = useLocation();

  function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

  const params = useParams();
  const [form, setForm] = useState<CUFormStateType>({
    type: "list",
    isOpen: false,
    tab: "school",
    idUpdate: "",
  });
  const [year, setYear] = useState<number>(moment().get("year"));
  const { tab } = useSelector((state: any) => state.organizationReducer);
  const [dataSchool, setDataSchool] = useState<any>({});
  const dataFromList = _.get(location, "state", {}); 
  
  useEffect(() => {
      setDataSchool(dataFromList); 
      
  }, []);
  //Các tabs
  const itemsTabList: TabsProps["items"] = [
    {
      key: "1",
      label: `Thông tin trường`,
      children: <Rschool dataFromList={dataSchool} />,
    },
    {
      key: "2",
      label: `Lớp`,
      children: <Classroom  form={form} setForm={setForm} setYear={setYear} dataFromList={dataSchool} />,
    },
    {
      key: "3",
      label: `Giáo viên`,
      children: <Teacher />,
    },
  ];
  if (form.isOpen) {
    if (tab === "1" && form.tab === "school") return <CUSchool />;
    return <CUClassroom />;
  }
  function handleExportUserFromSchool() {
    const tokens = JSON.parse(localStorage.getItem("tokens") || "");
    const query = {
      year,
      schoolId: _.get(params, "id", ""),
    };

    let config: AxiosRequestConfig = {
      method: "GET",
      url: `${
        process.env.REACT_APP_BASE_URL
      }schools/exportClassroomAccount?${queryString.stringify(query, {
        skipEmptyString: true,
        skipNull: true,
      })}`,
      headers: {
        Authorization: `Bearer ${tokens?.access?.token}`,
      },
      responseType: "blob",
    };

    axios
      .request(config)
      .then((response: any) => {
        fileDownload(
          response.data,
          `danhsachhocsinh_${moment().format("YYMMDDHHmmss")}.xlsx`
        );
      })
      .catch((error: any) => {
        console.error(error);
      });
  }
  return (
    <Content className="custom-layout-content">
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
        size={"large"}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Title level={4} style={{ marginBottom: -20 }}>
            <HeaderbackTitle
              title="Thông tin chi tiết trường"
              handleBack={() => {
                history.push(`/admin/organization?year=${query.get('year')}`);
              }}
              link={`/admin/organization?year=${query.get('year')}`}
            />
          </Title>
         
        </div>
        <Tabs
          key={"tab-organization"}
          id={"tab-organization"}
          tabBarStyle={{
            margin: 0,
          }}
          defaultActiveKey={query.get("tab") || "1"}
          onChange={(keyActive) => {
            if (keyActive === "1" || keyActive === "2" || keyActive === "3") {
              query.set("tab", keyActive);
              history.replace({
                search: query.toString(),
              });

              dispatch(setTab(keyActive));
            }
          }}
          items={itemsTabList}
        />
      </Space>
    </Content>
  );
}

export default memo(SchoolDetail);
