export const GENDER = {
  "1": "Nam",
  "0": "Nữ",
  "-1": "Khác",
};

export const DEFAULT_PROVICE_FILTER_ID = "34";
export const FIRST_ROUTE = "/admin/organization";
export const FIRST_ROUTE_TECHER = "/admin/users";
export const ADMIN_PERMISSION = [
  "get_course",
  "manage_course",
  "delete_course",
  "get_topic",
  "manage_topic",
  "delete_topic",
  "get_lesson",
  "manage_lesson",
  "delete_lesson",
  "get_question",
  "manage_question",
  "delete_question",
  "get_role",
  "manage_role",
  "delete_role",
  "get_user",
  "manage_user",
  "delete_user",
  "upload_multiple",
  "get_testLesson",
  "manage_testLesson",
  "delete_testLesson",
  "get_testQuestion",
  "manage_testQuestion",
  "delete_testQuestion",
  "get_examLesson",
  "manage_examLesson",
  "delete_examLesson",
  "get_examQuestion",
  "manage_examQuestion",
  "delete_examQuestion",
  "get_subExamLesson",
  "manage_subExamLesson",
  "delete_subExamLesson",
  "get_school",
  "manage_school",
  "delete_school",
  "get_classroom",
  "manage_classroom",
  "delete_classroom",
  "get_paymentPackage",
  "manage_paymentPackage",
  "delete_paymentPackage",
  "get_account",
  "manage_account",
  "delete_account",
  "get_classroomAccount",
  "manage_classroomAccount",
  "delete_classroomAccount",
];
export const TEACHER_PERMISSION = [
  "get_subExamLesson",
  "manage_subExamLesson",
  "get_classroomAccount",
  "get_classroom",
];
