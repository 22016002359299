/**
 * Màn hình danh sách tài khoản quản trị
 *
 */
import _ from "lodash";
import { memo, useState, useRef, useEffect } from "react";
import {
  Button,
  Layout,
  Popconfirm,
  Table,
  Typography,
  notification,
} from "antd";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import type { InputRef } from "antd";
import { Input, Space } from "antd";
import type { ColumnType } from "antd/es/table";
import type { FilterConfirmProps } from "antd/es/table/interface";
import CUManage from "pages/Manage/components/CUManage";
import { styled } from "styled-components";
import { useDispatch } from "react-redux";
import {
  deleteUser,
  getUsers,
  setQuery,
  setStateUser,
  setUser,
} from "features/userSlice";
import { PayloadType } from "type";
import queryString from "query-string";
import { useSelector } from "react-redux";
import { getRoles } from "features/roleSlice";
import moment from "moment";
import { useHistory, useParams } from "react-router";
const { Title } = Typography;
const { Content } = Layout;
interface DataType {
  key: number;
  name: string;
  gender: string;
  dateOfBirth: string;
  email: string;
  phoneNumber: string;
  address: string;
  roleId: string;
}
type DataIndex = keyof DataType;
const CustomPopconfirm = styled(Popconfirm)`
  .ant-popover-inner {
    max-width: 240px !important;
    background-color: red;
  }
`;
function Manage({ navigation }: any) {
  const [displayCU, setDisplayCU] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [filterRoleIdVal, setFilterCol] = useState<any[]>([]);
  const { query, roles, users } = useSelector(
    (state: any) => state?.userReducer
  );
  const roleReducer = useSelector((state: any) => state?.roleReducer);
  const dispatch = useDispatch();
  const searchInput = useRef<InputRef>(null);
  const history = useHistory();
  //Tìm kiếm tên tài khoản
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    dispatch(
      setQuery({
        ...query,
        name: selectedKeys[0],
      })
    );
  };

  //Bỏ tìm kiếm tên tài khoản
  const handleReset = (clearFilters: () => void) => {
    clearFilters();
  };
  //Định nghĩa cột trong bảng
  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Tìm kiếm ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Đặt lại
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => text,
  });
  function  getFilterRole(name: string, label: string) {
    const r = roles.find((role: any) => {
      return role.name === name;
    });
    return {
      text: label,
      value: r?.id ?? "",
    };
  }
  const columns: ColumnsType<DataType> = [
    {
      title: "Tên tài khoản",
      width: 150,
      dataIndex: "name",
      key: "name",
      fixed: "left",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Giới tính",
      width: 50,
      dataIndex: "gender",
      key: "gender",
      fixed: "left",
      render(value, record, index) {
        if (value === "0") {
          return "Nữ";
        }
        if (value === "1") {
          return "Nam";
        }
        if (value === "-1") {
          return "Khác";
        }
        return "";
      },
    },
    {
      title: "Ngày sinh",
      width: 80,
      dataIndex: "dateOfBirth",
      key: "dateOfBirth",
      fixed: "left",
      render(value, record, index) {
        if (!!value) {
          return moment(value).format("DD/MM/YYYY");
        }
        return "";
      },
    },
    {
      title: "Email",
      width: 100,
      dataIndex: "email",
      key: "email",
      fixed: "left",
    },
    {
      title: "Số điện thoại",
      width: 100,
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      fixed: "left",
      align: "center",
    },
    {
      title: "Địa chỉ",
      width: 200,
      dataIndex: "address",
      key: "address",
      fixed: "left",
    },
    {
      title: "Loại tài khoản",
      width: 100,
      dataIndex: "roleId",
      key: "roleId",
      fixed: "left",
      filters: [
        getFilterRole("teacher", "Giáo viên"),
        getFilterRole("admin", "Admin"),
      ],
      render(role, record, index) {
        const r = Array.isArray(roles)
          ? roles.find((r) => _.get(r, "id", "") === role)
          : {};
        if (r?.name === "admin") return "Admin";
        if (r?.name === "teacher") return "Giáo viên";
        return "";
      },
    },
    {
      title: "Hành động",
      width: 100,
      dataIndex: "action",
      key: "action",
      fixed: "left",
      align: "center",
      render: (_, record: any) => (
        <Space size="middle">
          <a
            onClick={() => {
              dispatch(
                setStateUser({
                  user: record,
                })
              );
              setIsUpdate(true);
              setDisplayCU(true);
            }}
          >
            Sửa
          </a>
          <CustomPopconfirm
            title="Bạn có chắc chắn muốn xóa tài khoản này không ?"
            okText={"Có"}
            cancelText={"Không"}
            onConfirm={() => {
              const id = record?.id ?? "";

              if (!!id) {
                const payload: PayloadType = {
                  params: id,
                  callback: {
                    success: () => {
                      notification.success({
                        message:
                          "Xoá tài khoản " +
                          (record?.name ?? "") +
                          " thành công!",
                      });
                      handleGetUsers();
                    },
                    failed(errorMessage) {
                      notification.error({
                        message:
                          "Xoá tài khoản " + record?.name ?? "" + " thất bại!",
                      });
                    },
                  },
                };
                dispatch(deleteUser(payload));
              }
            }}
          >
            <a>Xoá</a>
          </CustomPopconfirm>
        </Space>
      ),
    },
  ];
  //Gọi api lấy role admin và giáo viên
  function handleGetRole() {
    const payload: PayloadType = {
      query: queryString.stringify(_.get(roleReducer, "query", {})),
      callback: {
        success(values) {
          dispatch(
            setStateUser({
              roles: _.get(values, "data", []),
            })
          );
          dispatch(
            setQuery({
              page: 1,
              limit: 10,
            })
          );
        },
        failed(errorMessage) {
          notification.error({
            message: errorMessage,
          });
        },
      },
    };
    dispatch(getRoles(payload));
  }
  useEffect(() => {
    handleGetRole();
  }, [roleReducer?.query]);
  //Gọi api lấy danh sách user
  function handleGetUsers() {
    const payload: PayloadType = {
      query: queryString.stringify({ ...query }),
      body: {},
      callback: {
        success(values) {},
        failed(errorMessage) {
          notification.error({
            message: errorMessage,
          });
        },
      },
    };
    dispatch(getUsers(payload));
  }

  useEffect(() => {
    if (!displayCU) handleGetUsers();
  }, [query]);
  useEffect(() => {
    if (!displayCU) {
      dispatch(
        setQuery({
          page: 1,
          limit: 10,
          ...query,
        })
      );
    }
    return () => {
      //Thoát khỏi route thì set lại pagination hoặc giữ pagination khi quay lại từ màn thêm mới
      if (history.location.pathname !== "/admin/administrations") {
        dispatch(
          setQuery({
            page: 1,
            limit: 10,
          })
        );
      }
    };
  }, [displayCU]);

  useEffect(() => {
    const roleIdVal = filterRoleIdVal.join("|");
    if (!!roleIdVal && filterRoleIdVal.length === 1) {
      // lọc một trong các điều kiện
      dispatch(
        setQuery({
          ...query,
          roleId: roleIdVal,
        })
      );
    } else if (
      Object.keys(query).includes("roleId") ||
      filterRoleIdVal.length > 1
    ) {
      // bỏ chọn hoặc chọn nhiều các điều kiện lọc
      const newQuery = { ...query };
      delete newQuery["roleId"];
      dispatch(setQuery(newQuery));
    }
  }, [filterRoleIdVal]);

  //Hiển thị màn hình thêm và cập nhật tài khoản
  if (displayCU) {
    return (
      <CUManage
        isUpdate={isUpdate}
        setDisplayCU={(e: any) => {
          setDisplayCU(e);
        }}
      />
    );
  }
  return (
    <Content className="custom-layout-content">
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
        size={"large"}
      >
        <Title level={4}>Quản trị tài khoản</Title>
        <div className="space-between">
          <Title level={5}>Danh sách</Title>
          <Button
            size="middle"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setIsUpdate(false);
              setDisplayCU(true);
              dispatch(setUser(null));
            }}
          >
            Thêm mới
          </Button>
        </div>
        <Table
          columns={columns}
          dataSource={
            Array.isArray(users?.data ?? "")
              ? users.data.filter((item: any) => {
                  return _.get(item, "status", "") !== 3;
                })
              : []
          }
          pagination={{
            current: _.get(users, "page", ""),
            total: users.totalResults,
            showTotal: (total, range) => {
              return `Có tất cả ${total} bản ghi`;
            },
            showSizeChanger: true,
            onShowSizeChange(current, size) {
              if (query.limit !== size)
                dispatch(
                  setQuery({
                    ...query,
                    page: 1,
                    limit: size,
                  })
                );
            },
            onChange(page, pageSize) {
              if (query.page !== page)
                dispatch(
                  setQuery({
                    ...query,
                    page,
                  })
                );
            },
          }}
          onChange={(pagination, filters, sorter, extra) => {
            setFilterCol(_.get(filters, "roleId", []) ?? []);
          }}
          scroll={{ x: 1300, y: 500 }}
        />
      </Space>
    </Content>
  );
}

export default memo(Manage);
