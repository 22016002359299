/**
 * Màn hình quản lý thanh toán
 *
 */
import _ from "lodash";
import { memo, useState, useRef, useEffect } from "react";
import {
  Button,
  Layout,
  Popconfirm,
  Table,
  Typography,
  notification,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { Space } from "antd";
import CUManage from "pages/Manage/components/CUManage";
import { styled } from "styled-components";
import { useDispatch } from "react-redux";
import {
  deletePayment,
  getPayment,
  getPayments,
  setQuery,
} from "features/paymentSlice";
import { PayloadType } from "type";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import queryString from "query-string";
import moment from "moment";
const { Title, Text } = Typography;
const { Content } = Layout;
interface DataType {
  code: string;
  name: string;
  enName: string;
  courseId: {
    fullname: string;
    shortname: string;
    summary: string;
    isVisible: string;
    status: string;
    createdAt: string;
    updatedAt: string;
    id: string;
  };
  duration: string;
  price: string;
  isActive: string;
  createdAt: string;
  updatedAt: string;
  id: string;
}
const CustomPopconfirm = styled(Popconfirm)`
  .ant-popover-inner {
    max-width: 240px !important;
    background-color: red;
  }
`;
function Payment() {
  const [displayCU, setDisplayCU] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const { query, payments } = useSelector(
    (state: any) => state?.paymentReducer
  );
  const dispatch = useDispatch();
  const history = useHistory();

  //Định nghĩa cột trong bảng
  const columns: ColumnsType<DataType> = [
    {
      title: "Mã gói",
      width: 20,
      dataIndex: "code",
      key: "code",
      align: "left",
    },
    {
      title: "Tên",
      width: 20,
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "Khóa học",
      width: 150,
      dataIndex: "courseIds",
      key: "courseIds",
      align: "center",
      render(value, record, index) {
        return (
          <Text>
            {Array.isArray(value)
              ? value
                .map((item) => {
                  return _.get(item, "fullname", "");
                })
                .join(", ")
              : ""}
          </Text>
        );
      },
    },
    {
      title: "Thời gian (tháng)",
      width: 20,
      dataIndex: "duration",
      key: "duration",
      align: "center",
      render(value, record, index) {
        return parseInt(value) === -1 ? "Không giới hạn" : value;
      },
    },
    {
      title: "Giá (VNĐ)",
      width: 20,
      dataIndex: "price",
      key: "price",
      align: "center",
    },
    {
      title: "Giá khuyến mãi (VNĐ)",
      width: 20,
      dataIndex: "price",
      key: "price",
      align: "center",
    },

    {
      title: "Ngày tạo",
      width: 20,
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render(value, record, index) {
        return moment(value).format("DD/MM/YYYY");
      },
    },
    {
      title: "Trạng thái",
      width: 20,
      dataIndex: "isActive",
      key: "isActive",
      render(value, record, index) {
        return (
          <p
            style={{
              color: value ? "#52C41A" : "#FF4D4F",
            }}
          >
            {record?.isActive ? "Hoạt động" : "Không hoạt động"}
          </p>
        );
      },
    },
    {
      title: "Hành động",
      width: 10,
      dataIndex: "action",
      key: "action",
      align: "center",
      render: (_, record: any) => (
        <Space size="middle">
          <a
            onClick={() => {
              history.push("/admin/update-payment/" + record?.id ?? "");
            }}
          >
            Sửa
          </a>
          <CustomPopconfirm
            title={`Bạn có chắc chắn muốn xóa thanh toán ${record?.shortname ?? ""
              }  không ?`}
            okText={"Có"}
            cancelText={"Không"}
            onConfirm={() => {
              const id = record?.id ?? "";
              if (!!id) {
                const payload: PayloadType = {
                  params: id,
                  callback: {
                    success: () => {
                      notification.success({
                        message:
                          "Xoá gói thanh toán " +
                          (record?.name ?? "") +
                          " thành công!",
                      });
                      handleGetPayments();
                    },
                    failed(errorMessage) {
                      notification.error({
                        message:
                          "Xoá thanh toán " +
                          (record?.name ?? "") +
                          " thất bại!",
                        description: errorMessage,
                      });
                    },
                  },
                };
                dispatch(deletePayment(payload));
              }
            }}
          >
            <a>Xoá</a>
          </CustomPopconfirm>
        </Space>
      ),
    },
  ];
  //Lấy danh sách các gói thanh toán
  const handleGetPayments = () => {
    const payload: PayloadType = {
      query: queryString.stringify({
        ...query,
        populate: "courseIds",
      }),
      callback: {
        success(values) { },
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra khi lấy danh sách!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getPayments(payload));
  };
  useEffect(() => {
    handleGetPayments();
    return () => {
      //Đặt lại pagination khi focus ra khỏi route CRUD của gói thanh toán
      const pathname = history.location.pathname;
      if (pathname !== '/admin/payment' && pathname !== '/admin/create-payment' && !pathname.includes('/admin/update-payment')) {
        dispatch(setQuery({
          ...query,
          page: 1,
          limit: 10,
        }))
      }
    }
  }, [query]);
  //Hiển thị màn hình thêm và cập nhật tài khoản
  if (displayCU) {
    return (
      <CUManage
        isUpdate={isUpdate}
        setDisplayCU={(e: any) => {
          setDisplayCU(e);
        }}
      />
    );
  }
  return (
    <Content className="custom-layout-content">
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
        size={"large"}
      >
        <Title level={4}>Quản lý gói thanh toán</Title>
        <div className="space-between">
          <Title level={5}>Danh sách</Title>
          <Link
            to={"create-payment"}
            style={{
              color: "white",
            }}
          >
            <Button size="middle" type="primary" icon={<PlusOutlined />}>
              Thêm mới
            </Button>
          </Link>
        </div>
        <Table
          columns={columns}
          dataSource={
            Array.isArray(payments?.data ?? "")
              ? payments.data.filter((item: any) => {
                return _.get(item, "status", "") !== 3;
              })
              : []
          }
          pagination={{

            total: payments.totalResults,
            showTotal: (total, range) => {
              return `Có tất cả ${total} bản ghi`;
            },
            showSizeChanger: true,
            current: _.get(payments, "page", 1),
            onShowSizeChange(current, size) {
              if (query.limit !== size)
                dispatch(
                  setQuery({
                    ...query,
                    page: 1,
                    limit: size,
                  })
                );
            },
            onChange(page, pageSize) {

              dispatch(
                setQuery({
                  ...query,
                  page,
                })
              );
            },
          }}
        //   scroll={{ x: 1300, y: 500 }}
        />
      </Space>
    </Content>
  );
}

export default memo(Payment);
