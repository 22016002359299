/**
 * /auth/login
 * Màn hình đăng nhập
 */
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Space,
  Spin,
  Typography,
  notification,
} from "antd";
import React, { useState, useEffect } from "react";
import banner_footer from "assets/img/login-pages/footer.png";
import logo from "assets/img/login-pages/logo-viete-vuong-2-20210913030905 3.png";
import { AccountType, GetAccountSuccess, PayloadType } from "type";
import { useDispatch } from "react-redux";
import { accountLogin, forgotPass, setRole } from "features/accountSlice";
import { useHistory } from "react-router";
import * as yup from "yup";
import moment from "moment";
import _, { values } from "lodash";
import { useForm } from "antd/lib/form/Form";
import {
  ADMIN_PERMISSION,
  FIRST_ROUTE,
  FIRST_ROUTE_TECHER,
  TEACHER_PERMISSION,
} from "const";
import { useSelector } from "react-redux";
const { Title, Text, Link } = Typography;
interface FormAccountType {
  email: string;
  password: string;
  remember: boolean;
}
const Login = () => {
  const [form] = useForm();
  const [isLoadingLogin, setIsLoading] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const [isLoginForm, setIsLoginForm] = useState(true);
  const dispatch = useDispatch();

  const history = useHistory();
  function checkLogin() {
    try {
      const accountInfoLocal = localStorage.getItem("accountInfo");
      const tokens = localStorage.getItem("tokens");
      if (!!accountInfoLocal && !!tokens) {
        const expires = JSON.parse(tokens).access.expires;
        if (moment(new Date().toISOString()).isBefore(expires)) {
          const accountInfo = JSON.parse(accountInfoLocal);
          const permissions = _.get(accountInfo, "roleId.permission", []);
          const isAdmin =
            _.difference(ADMIN_PERMISSION, permissions).length == 0;
          const isTeacher =
            _.difference(TEACHER_PERMISSION, permissions).length == 0;
          dispatch(
            setRole({
              isAdmin,
              isTeacher: !isAdmin && isTeacher,
            })
          );
          if (!isAdmin && isTeacher) history.push(FIRST_ROUTE_TECHER);
          else history.push(FIRST_ROUTE);
          return;
        }
      }
    } catch (error) {}
    setIsLogin(false);
  }
  useEffect(() => {
    checkLogin();
  }, []);
  let loginSchema = yup.object().shape({
    email: yup
      .string()
      .required("Tài khoản không được để trống!")
      .email("Vui lòng nhập đúng định dạng email!"),
    password: yup.string().required("Mật khẩu không được để trống!"),
  });
  let forgitPassSchema = yup.object().shape({
    email: yup
      .string()
      .required("Tài khoản không được để trống!")
      .email("Vui lòng nhập đúng định dạng email!"),
  });
  const yupSync = {
    async validator({ field }: any, value: any) {
      await loginSchema.validateSyncAt(field, { [field]: value });
    },
  };
  const yupForgotSync = {
    async validator({ field }: any, value: any) {
      await forgitPassSchema.validateSyncAt(field, { [field]: value });
    },
  };
  function handleLogin(values: FormAccountType) {
    setIsLoading(true);
    const payload: PayloadType = {
      body: {
        email: _.get(values, "email", ""),
        password: _.get(values, "password", ""),
      },
      callback: {
        success(values: GetAccountSuccess) {
          setIsLoading(false);
          const permissions = _.get(values, "data.user.roleId.permission", []);
          const isAdmin =
            _.difference(ADMIN_PERMISSION, permissions).length == 0;
          const isTeacher =
            _.difference(TEACHER_PERMISSION, permissions).length == 0;

          localStorage.setItem(
            "accountInfo",
            JSON.stringify(_.get(values, "data.user", {}))
          );
          localStorage.setItem(
            "tokens",
            JSON.stringify(_.get(values, "data.tokens", {}))
          );
          dispatch(
            setRole({
              isAdmin,
              isTeacher: !isAdmin && isTeacher,
            })
          );

          if (!isAdmin && isTeacher) history.push(FIRST_ROUTE_TECHER);
          else history.push(FIRST_ROUTE);
        },
        failed() {
          notification.error({
            message: "Thông tin tài khoản hoặc mật khẩu không chính xác!",
          });
          setIsLoading(false);
        },
      },
    };
    dispatch(accountLogin(payload));
  }
  function handleForgotPass(values: FormAccountType) {
    setIsLoading(true);
    const payload: PayloadType = {
      body: {
        email: _.get(values, "email", ""),
      },
      callback: {
        success(values) {
          notification.success({
            message: "Thành công!",
            description:
              "Vui lòng kiểm tra thư trong địa chỉ email " +
              _.get(values, "email", ""),
          });
          form.resetFields();
          setIsLoading(false);
        },
        failed(errorMessage) {
          notification.error({
            message: "Thất bại",
            description: errorMessage,
          });
          form.resetFields();
          setIsLoading(false);
        },
      },
    };
    dispatch(forgotPass(payload));
  }
  if (!isLogin)
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          backgroundColor: "#F0F2F5",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            zIndex: 1,
            width: "100%",
            height: "100%",
          }}
        >
          <div
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              backgroundImage: `url(${banner_footer})`,
              backgroundPosition: "left bottom",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              width: "100vw",
              height: "30vh",
              minHeight: 300,
            }}
          />
        </div>
        <div
          style={{
            position: "absolute",
            zIndex: 2,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Row>
            <Col span={24}>
              <Space direction="vertical" align="center" size={"small"}>
                <img
                  src={logo}
                  alt="logo"
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
                <Title
                  style={{
                    textTransform: "uppercase",
                    color: "#1890FF",
                  }}
                >
                  Tiếng anh đồng hành
                </Title>
                <Card
                  size="default"
                  style={{
                    width: 430,
                    paddingTop: 0,
                  }}
                  bordered={true}
                >
                  <Form
                    form={form}
                    name="normal_login"
                    className="login-form"
                    size={"large"}
                    onFinish={(values) => {
                      if (isLoginForm) handleLogin(values);
                      else handleForgotPass(values);
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: 16,
                      }}
                    >
                      <Space align="center">
                        <Text type="secondary" style={{ marginTop: 0 }}>
                          {isLoginForm
                            ? "Vui lòng đăng nhập để sử dụng hệ thống"
                            : "Vui lòng nhập email tài khoản của bạn"}
                        </Text>
                      </Space>
                    </div>
                    <Form.Item
                      name="email"
                      rules={[isLoginForm ? yupSync : yupForgotSync]}
                    >
                      <Input
                        // prefix={<UserOutlined className="site-form-item-icon" />}
                        placeholder="Nhập email"
                      />
                    </Form.Item>
                    {isLoginForm && (
                      <Form.Item name="password" rules={[yupSync]}>
                        <Input.Password
                          // prefix={<LockOutlined className="site-form-item-icon" />}
                          type="password"
                          placeholder="Nhập mật khẩu"
                          visibilityToggle={{
                            visible: false,
                          }}
                        />
                      </Form.Item>
                    )}
                    <Form.Item>
                      {isLoginForm ? (
                        <Space
                          direction="horizontal"
                          style={{
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <Form.Item
                            name="remember"
                            valuePropName="checked"
                            noStyle
                          >
                            <Checkbox>Lưu mật khẩu</Checkbox>
                          </Form.Item>
                          <a
                            className="login-form-forgot"
                            href="#"
                            onClick={() => {
                              setIsLoginForm(false);
                            }}
                          >
                            Quên mật khẩu ?
                          </a>
                        </Space>
                      ) : (
                        <Space
                          direction="horizontal"
                          style={{
                            width: "100%",
                            justifyContent: "flex-end",
                          }}
                        >
                          <a
                            className="login-form-forgot"
                            href="#"
                            onClick={() => {
                              setIsLoginForm(true);
                            }}
                          >
                            Đăng nhập ?
                          </a>
                        </Space>
                      )}
                    </Form.Item>

                    <Form.Item
                      style={{
                        marginBottom: 0,
                      }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                        style={{
                          width: "100%",
                        }}
                        loading={isLoadingLogin}
                      >
                        {isLoginForm ? "Đăng nhập" : "Xác nhận"}
                      </Button>
                    </Form.Item>
                  </Form>
                </Card>
                {/* <Card style={{
                width: 430
              }} >
                <Space direction="vertical" size={"middle"} >
                  <Text type="secondary" style={{ textAlign: "center" }}>
                    Vui lòng đăng nhập để sử dụng hệ thống
                  </Text>
                  <Input
                    placeholder="Nhập tài khoản"
                    allowClear
                    onChange={() => {}}
                  />
                  <Input.Password placeholder="Nhập mật khẩu" />
                  <div>
                    <Checkbox onChange={() => {}}>Lưu mật khẩu</Checkbox>
                    <Link href="#">Quên mật khẩu khẩu ?</Link>
                  </div>
                  <div style={{
                    display: "flex",
                    justifyContent: 'space-between'
                  }}>
                    <Button type="default" size="large" style={{width: 150}}  >Hủy bỏ</Button>
                    <Button type="primary" size="large" style={{width: 150}}>Đăng nhập</Button>
                  </div>
                </Space>
              </Card> */}
              </Space>
            </Col>
          </Row>
        </div>
      </div>
    );
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        <Spin tip="Đang tải" size="large">
          <div className="content" />
        </Spin>
      </Space>
    </div>
  );
};

export default Login;
