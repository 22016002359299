/**
 * Màn hình danh sách người dùng
 */
import { memo, useState } from "react";
import { Button, Layout, Tabs, TabsProps, Typography } from "antd";
import { Space } from "antd";
import School from "pages/Organization/School";
import Classroom from "pages/Organization/Classroom";
import CUSchool from "pages/Organization/School/CUSchool";
import CUClassroom from "pages/Organization/Classroom/CUClassroom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setTab } from "features/organizationSlice";
const { Title } = Typography;
const { Content } = Layout;
interface CUFormStateType {
  type: "create" | "update" | "list";
  isOpen: boolean;
  tab: "school" | "classroom";
  idUpdate?: string;
}
function Organization() {
  const dispatch = useDispatch();
  const [form, setForm] = useState<CUFormStateType>({
    type: "list",
    isOpen: false,
    tab: "school",
    idUpdate: "",
  });
  const { tab } = useSelector((state: any) => state.organizationReducer);
  const [tabActive, setTabActive] = useState<"1" | "2">(tab);
  //Các tabs
  if (form.isOpen) {
    if (tabActive === "1" && form.tab === "school") return <CUSchool />;
    return <CUClassroom />;
  }

  return (
    <Content className="custom-layout-content">
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
        size={"large"}
      >
        <div className="space-between">
          <Title level={4} style={{ marginBottom: 0 }}>
            Quản lý tổ chức
          </Title>
          <Space direction="horizontal" size={"middle"} style={{ display: "none"}}>
            <Button
              size="large"
            >
              Nhập danh sách
            </Button>
            <Button
              size="large"
            >
              Xuất danh sách
            </Button>
          </Space>
        </div>
        <School form={form} setForm={setForm} />
      </Space>
    </Content>
  );
}

export default memo(Organization);
