import _ from "lodash";
import React, { useEffect, useState } from "react";
import {
  UserOutlined,
  SettingOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Dropdown, MenuProps, Space, Typography } from "antd";
import { Layout } from "antd";
import Logo from "assets/img/login-pages/logo-viete-vuong-2-20210913030905 3.png";
import AvatarImage from "assets/img/user-avatar-default.jpg";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { accountLoginSuccess, setRole } from "features/accountSlice";
import { ADMIN_PERMISSION, TEACHER_PERMISSION } from "const";
const { Header } = Layout;
const { Title, Text } = Typography;
const AdminHeader: React.FC = () => {
  const dispatch = useDispatch();

  const accountInfo = JSON.parse(localStorage.getItem("accountInfo") ?? "");
  const history = useHistory();
  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <a
          rel="noopener noreferrer"
          href="/admin/account-info"
        >
          <Space direction="horizontal">
            <UserOutlined /> Tài khoản
          </Space>
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          rel="noopener noreferrer"
          href="/admin/account-setting"
        >
          <Space direction="horizontal">
            <SettingOutlined /> Cài đặt
          </Space>
        </a>
      ),
    },
    {
      type: "divider",
    },
    {
      key: "3",
      label: (
        <a
          onClick={() => {
            localStorage.clear();
            history.push("/auth/login");
          }}
        >
          <Space direction="horizontal">
            <LogoutOutlined /> Thoát
          </Space>
        </a>
      ),
    },
  ];
  const [avatarUser, setAvatarUser] = useState<string | null>(null);
  useEffect(() => {
    if (!!accountInfo) {
      if (Object.keys(accountInfo).includes("avatar"))
        setAvatarUser(
          `${process.env.REACT_APP_BASE_URL_IMAGE}/${accountInfo?.avatar ?? ""}`
        );
    }
  }, [accountInfo]);
  function handleInitAccountInfo() {
    const accountItem = localStorage.getItem('accountInfo');
    if (!!accountItem) {
      const accountInfo = JSON.parse(accountItem);
      dispatch(accountLoginSuccess(accountInfo));
      const permissions = _.get(accountInfo,"roleId.permission","");
      const isAdmin = _.difference(ADMIN_PERMISSION, permissions).length == 0;
      const isTeacher = _.difference(TEACHER_PERMISSION, permissions).length == 0;
      dispatch(
        setRole({
          isAdmin,
          isTeacher: !isAdmin && isTeacher,
        })
      );
    }
  }
  useEffect(() => {
    handleInitAccountInfo();
  }, [])
  return (
    <Header
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Link to={"/"}>
          <img alt="logo" className="demo-logo" src={Logo} height={52} />
        </Link>
        <Title
          style={{
            color: "#96C4EF",
            textTransform: "uppercase",
            fontFamily: "'Quicksand', sans-serif",
            marginBottom: 0,
            marginLeft: 12,
          }}
          level={5}
        >
          Quản trị hệ thống phần mềm Tiếng anh đồng hành
        </Title>
      </div>
      <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
        <Dropdown menu={{ items }} placement="bottom" trigger={["click"]}>
          <Button
            style={{
              backgroundColor: "transparent",
              borderWidth: 0,
            }}
          >
            <Avatar
              src={
                <img
                  src={!!avatarUser ? avatarUser : AvatarImage}
                  alt="avatar"
                />
              }
            />
            <Text
              style={{
                color: "#FFFFFF",
                marginBottom: 0,
                marginLeft: 12,
              }}
            >
              {_.get(accountInfo, "name", "")}
            </Text>
          </Button>
        </Dropdown>
      </div>
    </Header>
  );
};

export default AdminHeader;
