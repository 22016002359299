/**
 * Màn hình Quản trị - Thêm/Sửa tài khoản quản trị
 */
import HeaderbackTitle from "pages/components/HeaderBackTitle";
import { Dispatch, SetStateAction } from "react";
import {
  Layout,
  Typography,
  Button,
  Form,
  Space,
  DatePicker,
  Select,
  Upload,
  notification,
  Input,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import * as yup from "yup";
import moment from "moment";
import type { RangePickerProps } from "antd/es/date-picker";
import TextArea from "antd/lib/input/TextArea";
import { styled } from "styled-components";
import { UploadChangeParam, UploadFile } from "antd/lib/upload";
import _ from "lodash";
import { ClassroomType, PayloadType, SchoolType, SelectOptionType } from "type";
import { useDispatch } from "react-redux";
import { uploadUserFile } from "features/userSlice";
import queryString from "query-string";
import { getSchools } from "features/schoolSlice";
import { getClassrooms } from "features/classroomSlice";
import { useForm } from "antd/lib/form/Form";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
const { Content } = Layout;
const { Title } = Typography;
interface TooltipType {
  name: string;
  isOpen: boolean;
}
interface ImportListUserType {
  setDisplay: Dispatch<SetStateAction<"list" | "import">>;
  setTabActive: Dispatch<SetStateAction<"1" | "2" | "3">>;
}
function ImportListUser({ setDisplay, setTabActive }: ImportListUserType) {
  const [fileUpload, setFileUpload] = useState<any>(null);
  const [form] = useForm();
  const [searchSchoolVal, setSearchSchoolVal] = useState<string | null>();
  const [schoolOptions, setSchoolOptions] = useState<SelectOptionType[]>([]);
  const [classOptions, setClassOptions] = useState<SelectOptionType[]>([]);
  const [schoolVal, setSchoolVal] = useState<string>();
  const [searchClassVal, setSearchClassVal] = useState<string | null>();
  const dispatch = useDispatch();
  const history = useHistory();
  let accountSchema = yup.object().shape({
    year: yup.date().required("Vui lòng chọn năm học!"),
    schoolId: yup.string().required("Vui lòng chọn trường học!"),
    classroomId: yup.string().required("Vui lòng chọn lớp học!"),
    expDate: yup.date().required("Vui lòng nhập ngày hết hạn!"),
    file: yup
      .object()
      .required("Vui lòng chọn file!")
      .test("check-file", "Định dạng file không hợp lệ!", (rcFile: any) => {
        const { file } = rcFile;
        if (!!file) {
          return _.get(file, "name", "").includes(".xlsx");
        }
        return false;
      }),
  });
  const yupSync = {
    async validator({ field }: any, value: any) {
      await accountSchema.validateSyncAt(field, { [field]: value });
    },
  };

  const handleUploadFile = (info: UploadChangeParam<UploadFile<any>>) => {
    const { fileList } = info;
    if (!!fileList) {
      setFileUpload(_.get(fileList[0], "originFileObj", null));
    }
  };
  function handleSubmit() {
    const values = form.getFieldsValue();
    let formData = new FormData();
    formData.append("file", _.get(values, "file.fileList[0].originFileObj"));
    formData.set(
      "year",
      moment(_.get(values, "year", moment())).get("year").toString()
    );
    formData.append("schoolId", _.get(values, "schoolId", ""));
    formData.append("classroomId", _.get(values, "classroomId", ""));
    formData.append("expDate", _.get(values, "expDate", ""));
    const payload: PayloadType = {
      // params: _.get(sectionActive, "id", ""),
      data: formData,
      callback: {
        success(values) {
          notification.success({
            message: "Upload File thành công!",
          });
          setDisplay("list");
          setTabActive("2");
        },
        failed(errorMessage) {
          notification.error({
            message: "Upload File thất bại!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(uploadUserFile(payload));
  }
  // Lấy danh sách trường
  function handleGetSchools() {
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          name: searchSchoolVal,
        },
        {
          skipEmptyString: true,
          skipNull: true,
        }
      ),
      skipUpdateReducer: true,
      callback: {
        success(schools) {
          const data = _.get(schools, "data", []);
          setSchoolOptions(
            data.map((item: SchoolType) => {
              return {
                label: `${item.name} -- ${item.award}, ${item.district}, ${item.province}  `,
                value: item.id,
              };
            })
          );
        },
        failed(errorMessage) {
          notification.error({
            message: errorMessage,
          });
        },
      },
    };
    console.log("url: import user")
    dispatch(getSchools(payload));
  }
  useEffect(() => {
    const delayBounce = setTimeout(handleGetSchools, 700);
    return () => clearTimeout(delayBounce);
  }, [searchSchoolVal]);
  //Lấy danh  sách lớp theo trường
  const handleGetClassroomByClassId = () => {
    if (!schoolVal) return;
    const payload: PayloadType = {
      skipUpdateReducer: true,
      query: queryString.stringify(
        {
          schoolId: schoolVal,
          page: 1,
          limit: 10,
          className: searchClassVal,
          year: moment(form.getFieldValue("year")).get("year"),
        },
        {
          skipEmptyString: true,
          skipNull: true,
        }
      ),
      callback: {
        success(values) {
          const arrClass = _.get(values, "data", []);
          setClassOptions(
            arrClass.map((item: ClassroomType) => ({
              label: item.className,
              value: item.id,
            }))
          );
        },
        failed(errorMessage) {
          notification.error({
            message: "Lấy danh sách lớp thất bại!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getClassrooms(payload));
  };
  useEffect(() => {
    handleGetClassroomByClassId();
  }, [schoolVal]);
  useEffect(() => {
    const delayBounce = setTimeout(handleGetClassroomByClassId, 700);
    return () => clearTimeout(delayBounce);
  }, [searchClassVal]);

  //minDate
  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Không được chọn trong khoảng thời gian đc chỉ định
    return current && current < moment();
  };
  return (
    <Content className="custom-layout-content">
      <HeaderbackTitle
        title="Nhập danh sách"
        handleBack={() => {
          setDisplay("list");
        }}
        link="/admin/users"
      />
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 8 }}
        onFinish={handleSubmit}
        autoComplete="off"
        layout="horizontal"
        style={{
          marginTop: 64,
        }}
      >
        <Form.Item
          label="Năm học"
          name="year"
          required
          labelAlign="right"
          rules={[yupSync]}
          initialValue={moment()}
        >
          <DatePicker
            picker="year"
            placeholder="Chọn năm học"
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          label="Trường"
          name="schoolId"
          required
          labelAlign="right"
          rules={[yupSync]}
        >
          <Select
            allowClear
            showSearch
            onSearch={(str: string) => {
              setSearchSchoolVal(str);
            }}
            options={schoolOptions}
            placeholder={"Chọn trường"}
            size="middle"
            onChange={(val) => {
              setSchoolVal(val);
            }}
            onClear={() => {
              form.setFieldValue("classroomId", null);
            }}
            filterOption={false}
          />
        </Form.Item>
        <Form.Item
          label="Lớp"
          name="classroomId"
          required
          labelAlign="right"
          rules={[yupSync]}
        >
          <Select
            options={classOptions}
            value={form.getFieldValue("classroomId")}
            placeholder={"Chọn lớp"}
            disabled={!schoolVal}
            size="middle"
            filterOption={false}
            showSearch
            onSearch={(str: string) => {
              setSearchClassVal(str);
            }}
          />
        </Form.Item>
        <Form.Item
          label="Ngày hết hạn"
          name="expDate"
          required
          labelAlign="right"
          rules={[yupSync]}
        >
          <DatePicker
            format={"DD/MM/YYYY"}
            disabledDate={disabledDate}
            placeholder="Nhập ngày hết hạn"
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          label="File danh sách"
          name="file"
          required
          labelAlign="right"
          rules={[yupSync]}
        >
          <Upload
            name="logo"
            accept=".xlsx"
            multiple={false}
            maxCount={1}
            beforeUpload={() => false}
            onChange={handleUploadFile}
          >
            <Button icon={<UploadOutlined />}>Bấm để tải lên</Button>
          </Upload>
        </Form.Item>
        <Form.Item
          wrapperCol={{
            offset: 8,
          }}
        >
          <Space>
            <Button
              type="default"
              size="middle"
              htmlType="button"
              onClick={() => {
                setTabActive("2");
                setDisplay("list");
              }}
            >
              Hủy
            </Button>
            <Button type="primary" size="middle" htmlType="submit">
              Nhập danh sách
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Content>
  );
}

export default ImportListUser;
