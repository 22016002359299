/**
 * Màn hình danh sách tài khoản quản trị
 *
 */
import _ from "lodash";
import { memo, useState, useEffect } from "react";
import {
  Layout,
  Popconfirm,
  Table,
  Typography,
  notification,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import {  Space } from "antd";
import { styled } from "styled-components";
import { useDispatch } from "react-redux";
import {
  getUsers,
  setQuery,
  setStateUser,
} from "features/userSlice";
import { PayloadType } from "type";
import queryString from "query-string";
import { useSelector } from "react-redux";
import { getRoles } from "features/roleSlice";
import { useHistory, useParams } from "react-router";
const { Title } = Typography;
interface DataType {
  key: number;
  name: string;
  gender: string;
  dateOfBirth: string;
  email: string;
  phoneNumber: string;
  address: string;
  roleId: string;
}
function Teacher({ navigation }: any) {
    const params = useParams();
  const [filterRoleIdVal, setFilterCol] = useState<any[]>([]);
  const { query, users } = useSelector(
    (state: any) => state?.userReducer
  );
  const roleReducer = useSelector((state: any) => state?.roleReducer);
  const dispatch = useDispatch();
  const history = useHistory();
  //Định nghĩa cột trong bảng
  const columns: ColumnsType<DataType> = [
    {
      title: "STT",
      width: 30,
      dataIndex: "name",
      key: "name",
      fixed: "left",
      render(value, record, index) {
        return ((users?.page-1) * 10) + index + 1

      },
    },
    {
      title: "Tên giáo viên",
      width: 100,
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: "Email",
      width: 100,
      dataIndex: "email",
      key: "email",
      fixed: "left",
    },
    {
      title: "Số điện thoại",
      width: 100,
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      fixed: "left",
      align: "center",
    },
    {
      title: "Địa chỉ",
      width: 100,
      dataIndex: "address",
      key: "address",
      fixed: "left",
    },
    {
      title: "Ghi chú",
      width: 100,
      dataIndex: "notes",
      key: "notes",
      fixed: "left",
    },
  ];
  //Gọi api lấy role admin và giáo viên
  function handleGetRole() {
    const payload: PayloadType = {
      query: queryString.stringify(_.get(roleReducer, "query", {})),
      callback: {
        success(values) {
          dispatch(
            setStateUser({
              roles: _.get(values, "data", []),
            })
          );
          dispatch(
            setQuery({
              page: 1,
              limit: 10,
            })
          );
        },
        failed(errorMessage) {
          notification.error({
            message: errorMessage,
          });
        },
      },
    };
    dispatch(getRoles(payload));
  }
  useEffect(() => {
    handleGetRole();
  }, [roleReducer?.query]);
  //Gọi api lấy danh sách user
  function handleGetUsers() {
    const payload: PayloadType = {
      query: queryString.stringify({ ...query,
    schoolId: _.get(params,"id","").substring(0, 24) }),
      body: {},
      callback: {
        success(values) {},
        failed(errorMessage) {
          notification.error({
            message: errorMessage,
          });
        },
      },
    };
    dispatch(getUsers(payload));
  }

  useEffect(() => {
     handleGetUsers();
  }, [query]);
  useEffect(() => {
      dispatch(
        setQuery({
          page: 1,
          limit: 10,
          ...query,
        })
      );
    return () => {
      //Thoát khỏi route thì set lại pagination hoặc giữ pagination khi quay lại từ màn thêm mới
      if (history.location.pathname !== "/admin/administrations") {
        dispatch(
          setQuery({
            page: 1,
            limit: 10,
          })
        );
      }
    };
  }, []);

  useEffect(() => {
    const roleIdVal = filterRoleIdVal.join("|");
    if (!!roleIdVal && filterRoleIdVal.length === 1) {
      // lọc một trong các điều kiện
      dispatch(
        setQuery({
          ...query,
          roleId: roleIdVal,
        })
      );
    } else if (
      Object.keys(query).includes("roleId") ||
      filterRoleIdVal.length > 1
    ) {
      // bỏ chọn hoặc chọn nhiều các điều kiện lọc
      const newQuery = { ...query };
      delete newQuery["roleId"];
      dispatch(setQuery(newQuery));
    }
  }, [filterRoleIdVal]);

  return (
      <Space
        direction="vertical"
        style={{
          width: "100%",
          marginTop: 24,
        }}
        size={"large"}
      >
        <div style={{display: "block"}}>
          <Title level={5} style={{
            fontWeight: 500
          }}>Danh sách </Title>
            <p style={{fontSize: "12px"}}>Tổng số giáo viên: <b>{_.get(users, "totalResults", 0)}</b></p>
        </div>
        <Table
          columns={columns}
          dataSource={
            Array.isArray(users?.data ?? "")
              ? users.data.filter((item: any) => {
                  return _.get(item, "status", "") !== 3;
                })
              : []
          }
          pagination={{
            current: _.get(users, "page", ""),
            total: users.totalResults,
            showTotal: (total, range) => {
              return `Có tất cả ${total} bản ghi`;
            },
            showSizeChanger: true,
            onShowSizeChange(current, size) {
              if (query.limit !== size)
                dispatch(
                  setQuery({
                    ...query,
                    page: 1,
                    limit: size,
                  })
                );
            },
            onChange(page, pageSize) {
              if (query.page !== page)
                dispatch(
                  setQuery({
                    ...query,
                    page,
                  })
                );
            },
          }}
          onChange={(pagination, filters, sorter, extra) => {
            setFilterCol(_.get(filters, "roleId", []) ?? []);
          }}
          scroll={{ x: 1300, y: 500 }}
        />
      </Space>
  );
}

export default memo(Teacher);
