import React from "react";
import Login from "pages/Login";
//icon
import ResetPassword from "pages/ResetPassword";
import AccountSetting from "pages/Account/AccountSetting";
import AccountInfo from "pages/Account/AccountInfo";
import Manage from "pages/Manage";
import {
  UserOutlined,
  GlobalOutlined,
  BookOutlined,
  CreditCardOutlined,
  TeamOutlined,
  OrderedListOutlined,
} from "@ant-design/icons";
import Organization from "pages/Organization";
import Course from "pages/Course";
import Payment from "pages/Payment";
import User from "pages/User";
import CUCourse from "pages/Course/CUCourse";
import CUPayment from "pages/Payment/CUPayment";
import CUSchool from "pages/Organization/School/CUSchool";
import CUClassroom from "pages/Organization/Classroom/CUClassroom";
import UUser from "pages/User/UUser";
import SchoolDetail from "pages/Organization/School/RSchool";
import ViewUser from "pages/User/ViewUser";
import Student from "pages/Student";
import UStudent from "pages/Student/UStudent";

// Route Auth
const routesA = [
  {
    collapse: true,
    name: "Auth",
    state: "authCollapse",
    views: [
      {
        path: "/login",
        name: "Đăng nhập",
        miniName: "L",
        component: Login,
        layout: "/auth",
        roles: [],
      },
      {
        path: "/reset-password",
        name: "Đặt lại mật khẩu",
        miniName: "L",
        component: ResetPassword,
        layout: "/auth",
        roles: [],
      },
      {
        path: "/reset-password",
        name: "Quên mật khẩu",
        miniName: "R",
        component: ResetPassword,
        layout: "/auth",
        roles: [],
      },
    ],
  },
];

// Route Quản trị hệ thống
const routesSM = [
  // {
  //   name: "Tổng quan",
  //   path: "/dashboard",
  //   icon: React.createElement(DashboardOutlined),
  //   component: Dashboard,
  //   layout: "/admin",
  //   roles: [],
  // },
  {
    name: "Quản lý tổ chức",
    path: "/organization",
    icon: React.createElement(GlobalOutlined),
    component: Organization,
    layout: "/admin",
    roles: ["manage_school", "manage_classroom"],
  },
  {
    hidden: true,
    name: "Thêm mới tổ chức trường",
    path: "/create-school-organization",
    icon: React.createElement(GlobalOutlined),
    component: CUSchool,
    layout: "/admin",
    roles: ["manage_school"],
  },
  {
    hidden: true,
    name: "Cập nhật tổ chức trường",
    path: "/update-school-organization/:id",
    icon: React.createElement(GlobalOutlined),
    component: CUSchool,
    layout: "/admin",
    roles: ["manage_school"],
  },
  {
    hidden: true,
    name: "Xem tổ chức trường",
    path: "/school-organization/:id",
    component: SchoolDetail,
    layout: "/admin",
    roles: ["manage_school"],
  },
  {
    hidden: true,
    name: "Thêm mới tổ chức lớp",
    path: "/create-classroom-organization",
    icon: React.createElement(GlobalOutlined),
    component: CUClassroom,
    layout: "/admin",
    roles: ["manage_classroom"],
  },
  {
    hidden: true,
    name: "Cập nhật tổ chức lớp",
    path: "/update-classroom-organization/:id",
    icon: React.createElement(GlobalOutlined),
    component: CUClassroom,
    layout: "/admin",
    roles: ["manage_classroom"],
  },
  {
    name: "Quản lý khóa học",
    path: "/course",
    icon: React.createElement(BookOutlined),
    component: Course,
    layout: "/admin",
    roles: ["get_course"],
  },
  {
    name: "Gói thanh toán",
    path: "/payment",
    icon: React.createElement(CreditCardOutlined),
    component: Payment,
    layout: "/admin",
    roles: ["get_paymentPackage"],
  },
  {
    hidden: true,
    name: "Tạo mới gói thanh toán",
    parent: "/admin/payment",
    path: "/create-payment",
    component: CUPayment,
    layout: "/admin",
    roles: ["manage_paymentPackage"],
  },
  {
    hidden: true,
    name: "Cập nhật gói thanh toán",
    parent: "/admin/payment",
    path: "/update-payment/:id",
    component: CUPayment,
    layout: "/admin",
    roles: ["manage_paymentPackage"],
  },
  {
    name: "Danh sách học sinh",
    path: "/users",
    icon: React.createElement(OrderedListOutlined),
    component: User,
    layout: "/admin",
    roles: ["get_classroomAccount"],
  },
  {
    name: "Danh sách người dùng",
    path: "/student",
    icon: React.createElement(OrderedListOutlined),
    component: Student,
    layout: "/admin",
    roles: ["get_account"],
  },
  {
    hidden: true,
    name: "Thêm tài khoản học sinh",
    path: "/create-user-all",
    icon: React.createElement(OrderedListOutlined),
    component: UUser,
    layout: "/admin",
    roles: ["manage_user"],
  },
  {
    hidden: true,
    name: "Sửa tài khoản học sinh",
    path: "/user-all/:id",
    icon: React.createElement(OrderedListOutlined),
    component: UUser,
    layout: "/admin",
    roles: ["manage_user"],
  },

  {
    hidden: true,
    name: "Sửa tài khoản người dùng",
    path: "/student-update/:id",
    icon: React.createElement(OrderedListOutlined),
    component: UStudent,
    layout: "/admin",
    roles: ["manage_user"],
  },

  {
    hidden: true,
    name: "Thông tin tài khoản học sinh",
    path: "/user-view/:id",
    icon: React.createElement(OrderedListOutlined),
    component: ViewUser,
    layout: "/admin",
    roles: ["manage_user"],
  },
  {
    name: "Quản trị",
    path: "/administrations",
    icon: React.createElement(TeamOutlined),
    component: Manage,
    layout: "/admin",
    roles: ["get_user", "get_role"],
  },
  {
    hidden: true,
    name: "Tạo mới khoá học",
    parent: "/admin/administrations",
    path: "/create-course",
    component: CUCourse,
    layout: "/admin",
    roles: ["manage_course"],
  },
  {
    hidden: true,
    name: "Cập nhật quản trị",
    path: "/update-course/:id",
    layout: "/admin",
    component: CUCourse,
    roles: ["manage_course"],
  },
  {
    collapse: true,
    name: "Thông tin tài khoản",
    icon: React.createElement(UserOutlined),
    state: "adminCollapse",
    roles: [],
    views: [
      {
        path: "/account-info",
        name: "Thông tin tài khoản",
        miniName: "L",
        component: AccountInfo,
        layout: "/admin",
        roles: [],
      },
      {
        path: "/account-setting",
        name: "Thiết lập tài khoản",
        miniName: "R",
        component: AccountSetting,
        layout: "/admin",
        roles: [],
      },
    ],
  },
];

export default {
  routesA,
  routesSM,
};
