export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const BASE_URL_IMAGE = process.env.REACT_APP_BASE_URL_IMAGE;
export const REACT_APP_BASE_GRAPHQL_URL =
  process.env.REACT_APP_BASE_GRAPHQL_URL;
export const BASE_URL_STRAPI = process.env.REACT_APP_BASE_URL_STRAPI;
export const CONVERT_BASE_URL = process.env.REACT_APP_CONVERT_BASE_URL;

const admin = "admin";
const logIn = "auth/login";
const forgot = "auth/forgot-password";
const user = "users";
const role = "roles";
const school = "schools";
const classroom = "classrooms";
const province = "provinces";
const district = "districts";
const award = "awards";
const setpass = "users/set-password";
const changepass = "users/change-password";
const course = "courses";
const payment = "paymentPackages";
const account = "accounts";
const classroomAccount = "classroomAccounts";
const resetpass = "auth/reset-password";
const countSchool = "/schools/account-stats";
const attendances = "attendances/by-account";
const diamonds = "diamonds/by-course";

export default {
  BASE_URL,
  admin,
  logIn,
  user,
  role,
  school,
  classroom,
  province,
  district,
  award,
  setpass,
  changepass,
  course,
  payment,
  account,
  classroomAccount,
  forgot,
  resetpass,
  countSchool, 
  attendances, 
  diamonds
};
