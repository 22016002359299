/* eslint-disable react-hooks/exhaustive-deps */
/**
 * Tab Nhóm tổ chức
 */
import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Empty,
  Popover,
  Select,
  Popconfirm,
  Table,
  Typography,
  notification,
  InputRef,
  Input,
  Space,
  Spin,
  TableProps,
} from "antd";
import {
  SearchOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  PlusSquareOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import type { ColumnsType, ColumnType } from "antd/es/table";
import type { FilterConfirmProps } from "antd/es/table/interface";
import PopoverCourse from "pages/User/OrganizationGroup/PopoverCourse";
import moment from "moment";
import {
  AccountType,
  AwardType,
  ClassroomType,
  DistrictType,
  PayloadType,
  ProviceType,
  SchoolType,
  SelectOptionType,
  TopicType,
} from "type";
import queryString from "query-string";
import {
  deleteClassroomAccount,
  getClassroomAccounts,
  setFilter,
  updateClassroomAccount,
} from "features/accountSlice";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { getProvinces } from "features/provinceSlice";
import { getDistricts } from "features/districtSlice";
import { getAwards } from "features/awardSlice";
import { getSchools } from "features/schoolSlice";
import { getClassrooms } from "features/classroomSlice";
import { DEFAULT_PROVICE_FILTER_ID } from "const";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { styled } from "styled-components";
import ChangeClassModal from "./ChangeClassModal";
const Highlighter = require("react-highlight-words");
const { Title, Text, Paragraph } = Typography;
interface DataType {
  _id: string;
  year: string;
  schoolId: SchoolType;
  classroomId: ClassroomType;
  accountId: AccountType;
  createdAt: string;
  updatedAt: string;
  topics: TopicType;
  accountType: string;
}
type DataIndex = keyof DataType;
interface IOrganizationGroup {
  tabActive: string;
}
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
function OrganizationGroup({ tabActive }: IOrganizationGroup) {
 
  const history = useHistory();
  const searchParams = useQuery();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [changeClass, setChangeClass] = useState(false);
  const [columns, setColumns] = useState<ColumnsType<DataType>>([]);
  const searchInput = useRef<InputRef>(null);
  const dispatch = useDispatch();
  const { filters, isTeacher, isAdmin, currentAccountLogin } = useSelector(
    (state: any) => state.accountReducer
  );

  const { queryAward } = useSelector((state: any) => state?.awardReducer);
  const { queryProvince } = useSelector((state: any) => state?.provinceReducer);
  const { queryDistrict } = useSelector((state: any) => state?.districtReducer);
  const [provinceOptions, setProvinceOptions] = useState<SelectOptionType[]>(
    []
  );
  const [districtOptions, setDistrictOptions] = useState<SelectOptionType[]>(
    []
  );
  const [awardOptions, setAwardOptions] = useState<SelectOptionType[]>([]);
  const [schoolOptions, setSchoolOptions] = useState<SelectOptionType[]>([]);
  const [classOptions, setClassOptions] = useState<SelectOptionType[]>([]);
  const [dataClassroomsAccounts, setDataClassroomsAccounts] = useState<any[]>(
    []
  );
  const [selectedRadio, setSelectedRadio] = useState(null); // State để lưu giá trị radio được chọn
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingBack, setIsLoadingBack] = useState<boolean>(false);

  const [defaultFilter, setDefaultFilter] = useState({});
  const [classSearchVal, setClassSearchVal] = useState<string>("");
  const [tableKey, setTableKey] = useState<number>(100);
  const [pageToClass, setPageToClass] = useState<number>(1);
  const CustomPopconfirm = styled(Popconfirm)`
    .ant-popover-inner {
      max-width: 240px !important;
      background-color: red;
    }
  `;

  //Lấy danh sách tỉnh mặc định
  useEffect(() => {
    handleGetProvinceAddress();
  }, []);

  //lấy danh sách lớp
  function handleGetClassroom() {
    if (tabActive !== "2") return;
    const query = queryString.stringify(
      isTeacher
        ? {
            teacherId: _.get(currentAccountLogin, "id", ""),
            populate: "schoolId,teacherId",
            year: _.get(filters, "year", "")
              ? _.get(filters, "year", "")
              : moment().year(),
            className: classSearchVal,
          }
        : {
            schoolId: _.get(filters, "school.value", filters?.school),
            year: _.get(filters, "year", "")
              ? _.get(filters, "year", "")
              : moment().year(),
            className: classSearchVal,
          },
      {
        skipEmptyString: true,
        skipNull: true,
      }
    );

    const payload: PayloadType = {
      query,
      skipUpdateReducer: false,
      callback: {
        success(values) {
          const data = _.get(values, "data", []);
          setClassOptions(
            data.map((item: ClassroomType) => ({
              label: item.className,
              value: item.id,
            }))
          );
          if (isTeacher && _.get(data, "length", "") > 0) {
            setDefaultFilter({
              ...filters,
              province: {
                label: _.get(data, "[0].schoolId.province"),
                value: null,
              },
              district: {
                label: _.get(data, "[0].schoolId.district"),
                value: null,
              },
              award: {
                label: _.get(data, "[0].schoolId.award"),
                value: null,
              },
              school: {
                label: _.get(data, "[0].schoolId.name"),
                value: null,
              },
            });
          }
        },
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách lớp học!",
            description: errorMessage,
          });
        },
      },
    };

    if (isTeacher || !!_.get(filters, "school", "")) {
      dispatch(getClassrooms(payload));
    }
  }

  useEffect(() => {
    // Nếu có sự thay đổi của lớp thì gọi lại hàm lấy danh sách tài khoản theo lớp
    if (!_.isNull(filters?.classroom) && !_.isUndefined(filters?.classroom)) {
      handleGetAccountByClassroom({});
    }
    console.log("filters", filters);
  }, [filters?.classroom, filters?.page, filters?.limit, filters?.phoneOrName]);

  useEffect(() => {
    //reset filter khi thay đổi tab
    //mà vẫn còn query.
    setTableKey(tableKey + 1);
    if (tabActive !== "0" && !!filters?.phoneOrName) {
      console.log("1");
      dispatch(
        setFilter({
          page: 1,
          limit: 10,
        })
      );
    }
  }, [tabActive]);
  console.log("filterádad", filters)

  useEffect(() => {
    return () => {
      // reset filter select when log out account
      console.log("2");
      dispatch(setFilter(null));
    };
  }, []);

  useEffect(() => {
    // lấy danh sách quận huyện khi có sự thay đổi của tỉnh
    handleGetDistrictAddress();
  }, [filters?.province]);

  useEffect(() => {
    // lấy danh sách xã phường khi có sự  thay đổi của quận huyện
    handleGetAwardAddress();
  }, [filters?.district]);

  useEffect(() => {
    // lấy danh sách trường khi có sự thay đổi của xã phường
    if (!_.isNull(filters?.award)) {
      handleGetSchools();
    }
  }, [filters?.award]);

  useEffect(() => {
    // Lấy danh sách lớp khi có sự thay đổi của năm và trường
    handleGetClassroom();
  }, [filters?.year, filters?.school]);

  useEffect(() => {
    if (
      searchParams.get("classroomId") !== "undefined" &&
      searchParams.get("classroomId")
    ) {
      // Lấy danh sách phường
      if (isAdmin) {
        handleGetDistrict(searchParams.get("province"));
        handleGetAwardAddresss(searchParams.get("district"));
        const provinceNameActive = provinceOptions.find(
          (item: any) => item.value === searchParams.get("province")
        )?.label;
        const districtNameActive = districtOptions.find(
          (item: any) => item.value === searchParams.get("district")
        )?.label;
        const awardNameActive = awardOptions.find(
          (item: any) => item.value === searchParams.get("award")
        )?.label;
        handleGetSchool(
          provinceNameActive,
          districtNameActive,
          awardNameActive
        );
        handleGetClassrooms(
          searchParams.get("province"),
          searchParams.get("district"),
          searchParams.get("award"),
          searchParams.get("schoolId"),
          2024
        );
      }
      console.log("đây", filters)
      if (isTeacher) {
        setDefaultFilter({
          ...filters,
          province: {
            label: searchParams.get("year"),
            value: null,
          },
          district: {
            label: searchParams.get("district"),
            value: null,
          },
          award: {
            label: searchParams.get("award"),
            value: null,
          },
          school: {
            label: searchParams.get("schoolId"),
            value: null,
          },
        });
      }

      dispatch(
        setFilter({
          ...filters,
          province: " Hà Nội",
          district: searchParams.get("district"),
          award: searchParams.get("award"),
          school: searchParams.get("schoolId"),
          classroom: searchParams.get("classroomId"),
          year: searchParams.get("year"),
        })
      );
    }
  }, [searchParams.get("year")]);

  console.log("dataClassroomsAccounts", dataClassroomsAccounts);
  //Searc theo tên lớp học
  useEffect(() => {
    const deBounce = setTimeout(() => {
      if (!!classSearchVal) handleGetClassroom();
    }, 700);
    return () => {
      clearTimeout(deBounce);
    };
  }, [classSearchVal]);

  //Xử lý khi tài khoản đang đăng nhập là giáo viên
  useEffect(() => {
    if (isTeacher) {
      handleGetClassroom();
    }
  }, [tabActive]);

  //cột và định nghĩa ô tìm kiếm
  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Tìm kiếm theo tên hoặc số điện thoại`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Đặt lại
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  //cột và định nghĩa ô checkbox


  const handleRadioChange = (e: any) => {
    const value = e.target.value;
    setSelectedRadio(value);
  };
  // Lấy danh sách lựa chọn cho ô select
  useEffect(() => {
    handleGetAccountByClassroom({});
  }, [filters?.isLogin]);
  const getColumnCheckboxProps = (
    dataIndex: DataIndex
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <input
          type="radio"
          id="html"
          name="fav_language"
          value="true"
          onChange={(e) => {
            handleRadioChange(e);
          }}
        />
        <label> Đã đăng nhập</label>
        <br />
        <input
          type="radio"
          id="css"
          name="fav_language"
          value="false"
          onChange={(e) => {
            handleRadioChange(e);
          }}
        />
        <label> Chưa đăng nhập</label>
        <br />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSelectRadio(confirm)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Tìm
          </Button>
          <Button
            type="primary"
            size="small"
            style={{ width: 120 }}
            onClick={() => {
              clearFilters && handleResetRadio(clearFilters);
              close();
            }}
          >
            Đặt lại bộ lọc
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Đóng
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <FilterOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    console.log("5");
    dispatch(
      setFilter({
        ...filters,
        phoneOrName: selectedKeys[0],
      })
    );
  };

  const handleSelectRadio = (confirm: (param?: FilterConfirmProps) => void) => {
    confirm();
    dispatch(
      setFilter({
        ...filters,
        isLogin: selectedRadio,
      })
    );
    handleGetAccountByClassroom({});
  };

  const handleResetRadio = (clearFilters: () => void) => {
    clearFilters();
    setSelectedRadio(null);
    dispatch(
      setFilter({
        ...filters,
        isLogin: undefined,
      })
    );
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
    dispatch(
      setFilter({
        ...filters,
        phoneOrName: "",
      })
    );
  };

  // Lấy danh sách phường
  function handleGetAwardAddresss(districtId: string | null) {
    const payload: PayloadType = {
      query: queryString.stringify({
        ...queryAward,
        district_id: districtId,
      }),
      callback: {
        success(values) {
          if (_.isArray(values) && values.length > 0) {
            setAwardOptions(
              values.map((item: AwardType) => {
                return {
                  label: _.get(item, "ward_name", ""),
                  value: _.get(item, "ward_id", ""),
                };
              })
            );
          }
        },
        failed(errorMessage) {
          notification.error({
            message: "Lấy danh sách Xã/Phường thất bại!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getAwards(payload));
  }

  // Lấy danh sách trường
  function handleGetSchool(
    provinceId: string | null,
    districtId: string | null,
    awardId: string | null
  ) {
    setIsLoadingBack(true);

    const payload: PayloadType = {
      query: queryString.stringify({
        province: provinceId,
        district: districtId,
        award: awardId,
      }),
      callback: {
        success(values) {
          setSchoolOptions(
            values?.data.map((item: any) => {
              return {
                label: _.get(item, "name", ""),
                value: _.get(item, "id", ""),
              };
            })
          );
          setIsLoadingBack(false);
        },
        failed(errorMessage) {
          notification.error({
            message: "Lấy danh sách trường thất bại!",
            description: errorMessage,
          });
          setIsLoadingBack(false);
        },
      },
    };
    console.log("url: use/roriganizationalgroup");
    dispatch(getSchools(payload));
  }

  //Lấy  danh sách lớp học
  function handleGetClassrooms(
    provinceId: any,
    districtId: any,
    awardId: any,
    schoolId: any,
    yearId: any
  ) {
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          province: provinceId,
          district: districtId,
          award: awardId,
          schoolId: schoolId,
          year: yearId,
        },
        {
          skipEmptyString: true,
          skipNull: true,
        }
      ),
      skipUpdateReducer: true,
      callback: {
        success(values) {
          setClassOptions(
            _.get(values, "data", []).map((course: any) => ({
              label: course?.className,
              value: course?.id,
            }))
          );
        },
        failed(errorMessage) {},
      },
    };
    dispatch(getClassrooms(payload));
  }

  function handleGetDistrict(district_id: string | null) {
    const payload: PayloadType = {
      query: queryString.stringify({
        ...queryDistrict,
        province_id: district_id,
      }),
      skipUpdateReducer: true,
      callback: {
        success(values) {
          if (_.isArray(values) && values.length > 0) {
            setDistrictOptions(
              values.map((item: DistrictType) => {
                return {
                  label: _.get(item, "district_name", ""),
                  value: _.get(item, "district_id", ""),
                };
              })
            );
          }
        },
        failed(errorMessage) {
          notification.error({
            message: "Lấy danh sách Quận/Huyện thất bại!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getDistricts(payload));
  }

  const filterSelect = (input: string, option: any) => {
    return option?.label?.toLowerCase().includes(input.toLowerCase());
  };

  //Lấy danh sách địa chỉ - tỉnh/quận/xã
  function handleGetProvinceAddress() {
    const payload: PayloadType = {
      query: queryString.stringify(queryProvince),
      skipUpdateReducer: true,
      callback: {
        success(values) {
          if (_.isArray(values) && values.length > 0) {
            setProvinceOptions(
              values.map((item: ProviceType) => {
                return {
                  label: _.get(item, "province_name", ""),
                  value: _.get(item, "province_id", ""),
                };
              })
            );
            if (!filters?.province) {
              const defaultValue = _.find(values, {
                province_id: DEFAULT_PROVICE_FILTER_ID,
              });
              if (!searchParams.get("province")) {
                dispatch(
                  setFilter({
                    province: {
                      label: _.get(defaultValue, "province_name", ""),
                      value: _.get(defaultValue, "province_id", ""),
                    },
                  })
                );
              }
            }
          }
        },
        failed(errorMessage) {
          notification.error({
            message: "Lấy danh sách tỉnh thất bại!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getProvinces(payload));
  }

  function handleGetDistrictAddress() {
    dispatch(
      setFilter({
        ...filters,
        province_id: _.get(filters?.province, "value", ""),
      })
    );
    const payload: PayloadType = {
      query: queryString.stringify({
        ...queryDistrict,
        province_id: _.get(filters?.province, "value", ""),
      }),
      skipUpdateReducer: true,
      callback: {
        success(values) {
          if (_.isArray(values) && values.length > 0) {
            setDistrictOptions(
              values.map((item: DistrictType) => {
                return {
                  label: _.get(item, "district_name", ""),
                  value: _.get(item, "district_id", ""),
                };
              })
            );
          }
        },
        failed(errorMessage) {
          notification.error({
            message: "Lấy danh sách Quận/Huyện thất bại!",
            description: errorMessage,
          });
        },
      },
    };
    if (_.get(filters?.province, "value", "")) dispatch(getDistricts(payload));
  }

  function handleGetAwardAddress() {
    dispatch(
      setFilter({
        ...filters,
        district_id: _.get(filters?.district, "value", ""),
      })
    );
    const payload: PayloadType = {
      query: queryString.stringify({
        ...queryDistrict,
        district_id: _.get(filters?.district, "value", ""),
      }),
      skipUpdateReducer: true,
      callback: {
        success(values) {
          if (_.isArray(values) && values.length > 0) {
            setAwardOptions(
              values.map((item: AwardType) => {
                return {
                  label: _.get(item, "ward_name", ""),
                  value: _.get(item, "ward_id", ""),
                };
              })
            );
          }
        },
        failed(errorMessage) {
          notification.error({
            message: "Lấy danh sách Xã/Phường thất bại!",
            description: errorMessage,
          });
        },
      },
    };
    if (!!_.get(filters, "district.value", "")) dispatch(getAwards(payload));
  }

  function handleGetSchools() {
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          page: 1,
          limit: 10,
          province: filters?.province?.label ?? "",
          district: filters?.district?.label ?? "",
          award: filters?.award?.label ?? "",
        },
        {
          skipEmptyString: true,
          skipNull: true,
        }
      ),
      skipUpdateReducer: true,
      callback: {
        success(schools) {
          const data = _.get(schools, "data", []);
          setSchoolOptions(
            data.map((item: any) => {
              return {
                label: _.get(item, "name", ""),
                value: _.get(item, "id"),
              };
            })
          );
        },
        failed(errorMessage) {
          notification.error({
            message: errorMessage,
          });
        },
      },
    };
    console.log("url: /origanizational group 2");
    if (!!filters?.province?.label && !!filters?.district?.label) {
      dispatch(getSchools(payload));
    }
  }

  function handleChangeColumns(data: any[]) {
    if (data.length > 0) {
      const topics = _.get(data, "[0].topics", []);
      const cols: ColumnType<DataType>[] = topics.map((item, i) => ({
        title: _.get(item, "title", "") || _.get(item, "name", ""),
        width: "30px",
        dataIndex: `topics`,
        key: `topics`,
        className: "text-align-center",
        align: "center",
        render: (val: any) => {
          const topic: TopicType | null = _.get(val, `[${i}]`, null);
          if (!topic) return "";
          const questions = _.get(topic, "questions", []);
          if (topic.unanswered !== 0)
            return (
              <Popover
                placement="topLeft"
                content={
                  <PopoverCourse
                    questions={questions}
                    unanswered={_.get(topic, "unanswered", 0)}
                    total={_.get(topic, "totalQuestion", 0)}
                  />
                }
                trigger={"hover"}
              >
                <ClockCircleOutlined
                  style={{
                    color: "#FAAD14",
                    fontSize: 32,
                  }}
                />
              </Popover>
            );
          return (
            <CheckCircleOutlined
              style={{
                color: "#52C41A",
                fontSize: 32,
              }}
            />
          );
        },
      }));
      setColumns(
        isAdmin
          ? [
              {
                title: "STT",
                width: 10,
                dataIndex: "id",
                key: "id",
                fixed: "left",
                className: "text-align-center",
                align: "center",
                render(value, record, index) {
                  return ((pageToClass || 1) - 1) * 10 + index + 1;
                },
              },
              {
                title: "Thông tin học sinh",
                width: 20,
                dataIndex: "accountId",
                key: "accountId",
                fixed: "left",
                ...getColumnSearchProps("accountId"),
                render: (value, record) => {
                  const accountExist = _.get(record, "accountId", null);
                  const username = _.get(value, "username", "");
                  const firstAccess = _.get(value, "firstaccess", -1);
                  return (
                    <>
                      {!_.isNull(accountExist) ? (
                        <>
                          <Text>{_.get(value, "fullname", "")}</Text>
                          <br />
                          <Text type="secondary">
                            <Paragraph type="secondary">
                              {username}
                              {firstAccess > 0
                                ? " - " +
                                  moment.unix(firstAccess).format("DD/MM/YYYY")
                                : ""}
                            </Paragraph>
                          </Text>
                        </>
                      ) : (
                        <Text
                          style={{
                            textAlign: "center",
                            fontSize: "14px",
                            fontWeight: "500",
                          }}
                        >
                          Học sinh không tồn tại hoặc đã bị xóa
                        </Text>
                      )}
                    </>
                  );
                },
              },
              {
                title: "Trạng thái",
                width: 15,
                dataIndex: "accountId",
                key: "accountId",
                fixed: "left",
                className: "text-align-center",
                align: "center",
                ...getColumnCheckboxProps("accountId"),
                render: (value, record) => {
                  const lastlogin = _.get(value, "lastlogin", 0);
                  if (lastlogin > 0)
                    return (
                      <div>
                        <Text>Đã đăng nhập</Text> <br />
                        <Text type="secondary">
                          Lần cuối:{" "}
                          {moment.unix(lastlogin).format("DD/MM/YYYY")}
                        </Text>
                      </div>
                    );
                  return (
                    <div>
                      <Text>Chưa đăng nhập</Text> <br />
                    </div>
                  );
                },
              },
              {
                title: "Tổng số kim cương",
                width: 25,
                dataIndex: ["accountId", "diamond"],
                key: "accountId.diamond",
                fixed: "left",
                className: "text-align-center",
                align: "center",
                defaultSortOrder: undefined,
                sorter: (a: any, b: any) =>
                  a?.accountId?.diamond - b?.accountId?.diamond,
              },
              // {
              //   title: "Số KC theo lớp",
              //   width: 15,
              //   dataIndex: "",
              //   key: "",
              //   fixed: "left",
              //   className: "text-align-center",
              //   align: "center",
              // },
              // {
              //   title: "Số KC theo hệ thống",
              //   width: 15,
              //   dataIndex: "",
              //   key: "",
              //   fixed: "left",
              //   className: "text-align-center",
              //   align: "center",
              // },
              {
                title: "Miễn phí",
                width: 15,
                dataIndex: "accountType",
                key: "accountType",
                fixed: "left",
                className: "text-align-center",
                align: "center",
                render: (value, record) => {
                  return (
                    <div>
                      <Checkbox
                        disabled={_.isNull(record?.accountId) ? true : false}
                        checked={record.accountType == "1" ? true : false}
                        onChange={async () => {
                          setIsLoading(true);
                          const bodyPost: any = {
                            accountType: record.accountType == "1" ? "2" : "1",
                          };
                          const payloadClassAccount: PayloadType = {
                            params: _.get(record, "_id", ""),
                            body: bodyPost,
                            callback: {
                              success(values) {
                                handleGetAccountByClassroom({});
                                notification.success({
                                  message:
                                    "Thay đổi trạng thái tài khoản học sinh" +
                                    _.get(record, "accountId?.fullname", "") +
                                    " thành công!",
                                });
                              },
                              failed(errorMessage) {
                                notification.error({
                                  message:
                                    "Cập nhật thông tin tài khoản học sinh thất bại!",
                                  description: errorMessage,
                                });
                                setIsLoading(false);
                              },
                            },
                          };
                          await dispatch(
                            updateClassroomAccount(payloadClassAccount)
                          );
                        }}
                      />
                    </div>
                  );
                },
              },
              ...cols,
              {
                title: "Hành động",
                width: 25,
                dataIndex: "action",
                key: "action",
                fixed: "right",
                align: "left",
                render: (lodash, record: any) => {
                  return (
                    <Space size="middle">
                      <Link
                        to={
                          !_.isNull(record?.accountId)
                            ? `/admin/user-view/${
                                record?.accountId?._id
                              }?schoolId=${
                                filters?.school?.value || filters?.school
                              }&classroomAccountId=${record?._id}&classroomId=${
                                filters?.classroom?.value || filters?.classroom
                              }&year=${filters?.year || 2024}&district=${
                                filters?.district?.value || filters?.district
                              }&award=${
                                filters?.award?.value || filters?.award
                              }&province=${
                                filters?.province?.value || filters?.province
                              }&courseId=${record?.accountId?.courseid}`
                            : "#"
                        }
                      >
                        Xem
                      </Link>

                      <Link
                        to={
                          !_.isNull(record?.accountId)
                            ? `/admin/user-all/${
                                record?.accountId?._id
                              }?schoolId=${
                                filters?.school?.value || filters?.school
                              }&classroomAccountId=${record?._id}&classroomId=${
                                filters?.classroom?.value || filters?.classroom
                              }&year=${filters?.year || 2024}&district=${
                                filters?.district?.value || filters?.district
                              }&award=${
                                filters?.award?.value || filters?.award
                              }&province=${
                                filters?.province?.value || filters?.province
                              }`
                            : "#"
                        }
                      >
                        Sửa
                      </Link>

                      {/* <a
                        onClick={(event) => {
                          if (_.isNull(record?.accountId)) {
                            event.preventDefault();
                            setChangeClass(false);
                          } else {
                            setChangeClass(true);
                          }
                        }}
                      >
                        Xóa
                      </a> */}

                      <CustomPopconfirm
                        title="Bạn có chắc chắn muốn xóa tài khoản học sinh khỏi lớp không?"
                        okText={"Có"}
                        cancelText={"Không"}
                        onConfirm={() => {
                          const id = record?._id ?? record?.id;
                          if (!!id) {
                            const payload: PayloadType = {
                              params: id,
                              callback: {
                                success: () => {
                                  notification.success({
                                    message:
                                      "Xoá tài khoản học sinh " +
                                      (record?.accountId?.fullname ?? "") +
                                      " khỏi lớp " +
                                      (record?.classroomId?.className ?? "") +
                                      " thành công!",
                                  });
                                  handleGetAccountByClassroom({});
                                },
                                failed(errorMessage) {
                                  notification.error({
                                    message:
                                      "Xoá tài khoản học sinh " +
                                      (record?.accountId?.fullname ?? "") +
                                      " khỏi lớp " +
                                      (record?.classroomId?.className ?? "") +
                                      " thất bại",
                                  });
                                },
                              },
                            };
                            dispatch(deleteClassroomAccount(payload));
                          }
                        }}
                      >
                        <a>Xoá</a>
                      </CustomPopconfirm>
                    </Space>
                  );
                },
              },
            ]
          : [
              {
                title: "STT",
                width: 10,
                dataIndex: "id",
                key: "id",
                fixed: "left",
                className: "text-align-center",
                align: "center",
                render(value, record, index) {
                  return ((pageToClass || 1) - 1) * 10 + index + 1;
                },
              },
              {
                title: "Thông tin học sinh",
                width: 40,
                dataIndex: "accountId",
                key: "accountId",
                fixed: "left",
                ...getColumnSearchProps("accountId"),
                render: (value, record) => {
                  const username = _.get(value, "username", "");
                  const firstAccess = _.get(value, "firstaccess", -1);
                  return (
                    <>
                      <Text>{_.get(value, "fullname", "")}</Text>
                      <br />
                      <Text type="secondary">
                        <Paragraph type="secondary">
                          {username}
                          {firstAccess > 0
                            ? " - " +
                              moment.unix(firstAccess).format("DD/MM/YYYY")
                            : ""}
                        </Paragraph>
                      </Text>
                    </>
                  );
                },
              },
              {
                title: "Trạng thái",
                width: 25,
                dataIndex: "accountId",
                key: "accountId",
                fixed: "left",
                className: "text-align-center",
                align: "center",
                ...getColumnCheckboxProps("accountId"),
                render: (value, record) => {
                  const lastlogin = _.get(value, "lastlogin", 0);
                  if (lastlogin > 0)
                    return (
                      <div>
                        <Text>Đã đăng nhập</Text> <br />
                        <Text type="secondary">
                          Lần cuối:{" "}
                          {moment.unix(lastlogin).format("DD/MM/YYYY")}
                        </Text>
                      </div>
                    );
                  return (
                    <div>
                      <Text>Chưa đăng nhập</Text> <br />
                    </div>
                  );
                },
              },
              {
                title: "Tổng số kim cương",
                width: 25,
                dataIndex: ["accountId", "diamond"],
                key: "accountId.diamond",
                fixed: "left",
                className: "text-align-center",
                align: "center",
                defaultSortOrder: undefined,
                sorter: (a: any, b: any) =>
                  a?.accountId?.diamond - b?.accountId?.diamond,
              },
              ...cols,
              {
                title: "Hành động",
                width: 25,
                dataIndex: "action",
                key: "action",
                fixed: "right",
                align: "left",
                render: (lodash, record: any) => {
                  return (
                    <Space size="middle">
                      <Link
                        to={
                          !_.isNull(record?.accountId)
                            ? `/admin/user-view/${
                                record?.accountId?._id
                              }?schoolId=${
                                record?.schoolId?._id
                              }&classroomAccountId=${record?._id}&classroomId=${
                                filters?.classroom?.value || filters?.classroom
                              }&year=${filters?.year || 2024}&province=${
                                filters?.province?.value || filters?.province
                              }&courseId=${record?.accountId?.courseid}&name=${
                                record?.accountId?.fullname
                              }&phone=${record?.accountId?.username}&diamond=${
                                record?.accountId?.diamond
                              }`
                            : "#"
                        }
                      >
                        Xem
                      </Link>
                    </Space>
                  );
                },
              },
            ]
      );
    } else {
    }
  }

  const onChange: TableProps<DataType>["onChange"] = (
    pagination,
    filter,
    sorter: any,
    extra
  ) => {
    setPageToClass(_.get(pagination, "current", 1));
    setIsLoading(true);
    const sorters: any = sorter?.order;
    console.log("call to here", filters);
    dispatch(
      setFilter({
        ...filters,
        page: !_.isUndefined(pagination)
          ? _.get(pagination, "current", 1)
          : _.get(filters, "page", 1),
        limit: !_.isUndefined(pagination)
          ? _.get(pagination, "pageSize", 1)
          : _.get(filters, "limit", 1),
        accountSort: !_.isUndefined(sorters)
          ? sorters === "ascend"
            ? "diamond:asc"
            : "diamond:desc"
          : undefined,
      })
    );
    if (
      searchParams.get("classroomId") !== "undefined" &&
      searchParams.get("classroomId")
    ) {
      setIsLoadingBack(true);
    }
    const query = {
      year: _.get(filters, "year", moment().year()),
      page: !_.isUndefined(pagination)
        ? _.get(pagination, "current", 1)
        : _.get(filters, "page", 1),
      limit: !_.isUndefined(pagination)
        ? _.get(pagination, "pageSize", 10)
        : _.get(filters, "limit", 10),
      schoolId: _.get(filters, "school.value", filters?.school),
      classroomId: _.get(filters, "classroom.value", filters?.classroom),
      phoneOrName: _.get(filters, "phoneOrName", ""),
      isLogin: selectedRadio,
      accountSort: !_.isUndefined(sorters)
        ? sorters === "ascend"
          ? "diamond:asc"
          : "diamond:desc"
        : undefined,
      // schoolId: "6476f5a128f2d8e065fd826f",
      // classroomId: "6479b0827b7b8c52188085ec",
    };

    const payload: PayloadType = {
      query: queryString.stringify(query, {
        skipEmptyString: true,
        skipNull: true,
      }),
      callback: {
        success(values) {
          const data = _.get(values, "data", []);
          console.log("data", data);
          setDataClassroomsAccounts(values);
          handleChangeColumns(data);
          setIsLoading(false);
          setIsLoadingBack(false);
        },
        failed(errorMessage) {
          notification.error({
            message: "Lấy danh sách tài khoản thất bại!",
            description: errorMessage,
          });
          setIsLoading(false);
          setIsLoadingBack(false);
        },
      },
    };
    dispatch(getClassroomAccounts(payload));
  };

  //Lấy danh sách nhóm người dùng nhóm tổ chức
  function handleGetAccountByClassroom(otherQuery: any) {
    setIsLoading(true);
    if (
      searchParams.get("classroomId") !== "undefined" &&
      searchParams.get("classroomId")
    ) {
      setIsLoadingBack(true);
    }

    const query = {
      year: _.get(filters, "year", moment().year()),
      page: _.get(filters, "page", 1),
      limit: _.get(filters, "limit", 10),
      schoolId: _.get(filters, "school.value", filters?.school),
      classroomId: _.get(filters, "classroom.value", filters?.classroom),
      phoneOrName: _.get(filters, "phoneOrName", ""),
      accountSort: filters?.accountSort || undefined,
      isLogin: selectedRadio,
      ...otherQuery,
      // schoolId: "6476f5a128f2d8e065fd826f",
      // classroomId: "6479b0827b7b8c52188085ec",
    };

    if (!query.schoolId && !query.classroomId) {
      setIsLoading(false);
      return;
    }
    const payload: PayloadType = {
      query: queryString.stringify(query, {
        skipEmptyString: true,
        skipNull: true,
      }),
      callback: {
        success(values) {
          const data = _.get(values, "data", []);
          console.log("oke", values);
          setDataClassroomsAccounts(values);
          handleChangeColumns(data);
          setIsLoading(false);
          setIsLoadingBack(false);
        },
        failed(errorMessage) {
          notification.error({
            message: "Lấy danh sách tài khoản thất bại!",
            description: errorMessage,
          });
          setIsLoading(false);
          setIsLoadingBack(false);
        },
      },
    };
    dispatch(getClassroomAccounts(payload));
  }

  return (
    <>
      {!isLoadingBack ? (
        <>
          <ChangeClassModal
            changeClass={changeClass}
            setChangeClass={setChangeClass}
          />
          <div
            className="space-between"
            style={{
              marginTop: 24,
              marginBottom: 24,
              alignItems: "center",
            }}
          >
            <div style={{ width: "30%" }}>
              <Title
                level={5}
                style={{
                  fontWeight: 500,
                }}
              >
                Danh sách
              </Title>
              {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
                {!isTeacher ? (
                  <>
                    <p style={{ fontSize: "12px" }}>
                      Số KC theo tuần: <b>1000</b>
                    </p>
                    <p style={{ fontSize: "12px" }}>
                      Số KC theo lớp: <b>1000</b>
                    </p>
                    <p style={{ fontSize: "12px" }}>
                      Số KC theo hệ thống: <b>1000</b>
                    </p>
                  </>
                ) : (
                  ""
                )}
              </div> */}
            </div>
            <Space
              style={{ width: "70%", justifyContent: "end" }}
              direction="horizontal"
              size={"small"}
              className="custom-placeholder-select"
            >
              <Space
                direction="horizontal"
                size={"middle"}
                className="custom-placeholder-select"
              >
                <Select
                  placeholder={"Tỉnh"}
                  style={{ width: 150 }}
                  size="middle"
                  showSearch
                  allowClear
                  disabled={isTeacher}
                  options={provinceOptions}
                  filterOption={filterSelect}
                  onChange={(value, option: any) => {
                    setSchoolOptions([]);
                    dispatch(
                      setFilter({
                        ...filters,
                        province: option,
                        district: null,
                        award: null,
                        school: null,
                        classroom: null,
                      })
                    );
                  }}
                  value={
                    isAdmin
                      ? filters?.province
                      : _.get(defaultFilter, "province", null)
                  }
                />
                <Select
                  placeholder={"Quận/Huyện"}
                  style={{ width: 150 }}
                  size="middle"
                  showSearch
                  options={districtOptions}
                  filterOption={filterSelect}
                  onChange={(value, option: any) => {
                    dispatch(
                      setFilter({
                        ...filters,
                        district: option,
                        award: null,
                        school: null,
                        classroom: null,
                      })
                    );
                  }}
                  disabled={isTeacher || !filters?.province}
                  allowClear
                  value={
                    isAdmin
                      ? filters?.district
                      : _.get(defaultFilter, "district", null)
                  }
                />
                <Select
                  placeholder={"Xã/Phường"}
                  style={{ width: 150 }}
                  size="middle"
                  showSearch
                  options={awardOptions}
                  filterOption={filterSelect}
                  onChange={(value, option: any) => {
                    setSchoolOptions([]);
                    dispatch(
                      setFilter({
                        ...filters,
                        award: option,
                        school: null,
                        classroom: null,
                      })
                    );
                  }}
                  disabled={isTeacher || !filters?.district}
                  allowClear
                  value={
                    isAdmin
                      ? filters?.award
                      : _.get(defaultFilter, "award", null)
                  }
                />
                <Select
                  placeholder={"Trường"}
                  style={{ width: 200 }}
                  size="middle"
                  showSearch
                  filterOption={filterSelect}
                  options={schoolOptions}
                  allowClear
                  onChange={(value, option: any) => {
                    setClassOptions([]);
                    dispatch(
                      setFilter({
                        ...filters,
                        school: option,
                        classroom: null,
                      })
                    );
                  }}
                  disabled={isTeacher || !filters?.award}
                  value={
                    isAdmin
                      ? filters?.school
                      : _.get(defaultFilter, "school", null)
                  }
                />
                <DatePicker
                  placeholder={"Năm"}
                  style={{ width: 100 }}
                  size="middle"
                  picker="year"
                  allowClear={false}
                  disabled={isAdmin ? !filters?.school : false}
                  defaultValue={
                    !!_.get(filters, "year")
                      ? moment().set("year", _.get(filters, "year"))
                      : !_.isEmpty(searchParams.get("year"))
                      ? moment(searchParams.get("year"))
                      : moment().startOf("year")
                  }
                  onChange={(e) => {
                    dispatch(
                      setFilter({
                        ...filters,
                        year: !!e
                          ? moment(e).get("year")
                          : moment().get("year"),
                        classroom: null,
                      })
                    );
                  }}
                />
                <Select
                  placeholder={"Lớp"}
                  style={{ width: 100 }}
                  size="middle"
                  showSearch
                  filterOption={false}
                  options={classOptions}
                  allowClear
                  onSearch={(str) => {
                    setClassSearchVal(str);
                  }}
                  onChange={(value, option: any) => {
                    dispatch(
                      setFilter({
                        ...filters,
                        classroom: option,
                      })
                    );
                  }}
                  disabled={isAdmin ? !filters?.school : false}
                  value={filters?.classroom}
                />

                {!isTeacher ? (
                  <Button
                    // disabled={!filters?.school || !filters?.classroom}
                    size="middle"
                    type="primary"
                    icon={<PlusSquareOutlined />}
                    onClick={() => {
                      history.push(
                        `/admin/create-user-all?schoolId=${_.get(
                          filters,
                          "school.value"
                        )}&classroomId=${_.get(
                          filters,
                          "classroom.value"
                        )}&year=${_.get(
                          filters,
                          "year",
                          moment().year()
                        )}&province=${_.get(
                          filters,
                          "province.value"
                        )}&district=${_.get(
                          filters,
                          "district.value"
                        )}&award=${_.get(filters, "award.value")}`
                      );
                    }}
                  >
                    Thêm mới
                  </Button>
                ) : (
                  ""
                )}
              </Space>
            </Space>
          </div>
          <Table
            key={tableKey}
            loading={isLoading}
            onChange={onChange}
            locale={{
              triggerDesc: "Sắp xếp giảm dần",
              triggerAsc: "Sắp xếp tăng dần",
              cancelSort: "Loại bỏ sắp xếp",
              emptyText: (
                <Empty
                  description={
                    <div>
                      <span>Vui lòng chọn lớp học!</span> <br />
                    </div>
                  }
                />
              ),
            }}
            rowClassName={(record: any) =>
              _.isNull(record?.accountId) ? "table-row-close" : ""
            }
            columns={columns}
            dataSource={_.get(dataClassroomsAccounts, "data", [])}
            pagination={{
              total: _.get(dataClassroomsAccounts, "totalResults", 0),
              current: _.get(dataClassroomsAccounts, "page", 1),
              showTotal: (total, range) => {
                return `Có tất cả ${total} bản ghi`;
              },
              showSizeChanger: true,
            }}
            scroll={{ x: 10000, y: 550 }}
          />
        </>
      ) : (
        <Spin tip="Đang cập nhật..." size="small" style={{ marginTop: "20%" }}>
          <div className="content" />
        </Spin>
      )}
    </>
  );
}

export default OrganizationGroup;
