/**
 * Màn hình thêm mới tài khoản học sinh(Tab nhóm tổ chức)
 */
import HeaderbackTitle from 'pages/components/HeaderBackTitle';
import React, { memo, useState, useEffect, useMemo, Fragment } from 'react';
import {
  Layout,
  Button,
  Form,
  Space,
  notification,
  Input,
  DatePicker,
  Radio,
  Select,
} from 'antd';
import * as yup from 'yup';

import {
  CourseType,
  PayloadType,
  AwardType,
  DistrictType,
  ProviceType,
  SelectOptionType,
} from 'type';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { getCourses } from 'features/courseSlice';
import { useHistory, useLocation, useParams } from 'react-router';
import { useForm } from 'antd/lib/form/Form';
import {
  createAccount,
  createUserAccount,
  getAccount,
  getAccounts,
  getClassroomAccounts,
  setQuery,
  updateAccount,
  updateClassroomAccount,
} from 'features/accountSlice';
import { getProvinces } from 'features/provinceSlice';
import { getDistricts } from 'features/districtSlice';
import { getAwards } from 'features/awardSlice';
import queryString from 'query-string';
import moment from 'moment';
import TextArea from 'antd/lib/input/TextArea';
import { getSchools } from 'features/schoolSlice';
import { getClassroom, getClassrooms } from 'features/classroomSlice';
const { Content } = Layout;
interface FormType {
  name: 'create' | 'update';
  id: string | null;
}
function getFormType(history: any, param: any): FormType {
  if (history?.location?.pathname === '/admin/create-user-all') {
    return {
      name: 'create',
      id: null,
    };
  }
  if (history?.location?.pathname.includes('/admin/user-all')) {
    const { id } = param;
    return {
      name: 'update',
      id,
    };
  }
  return {
    name: 'create',
    id: null,
  };
}
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
function UUser() {
  const [formUpdate] = useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const param = useParams();
  const searchParams = useQuery();
  const [form, setForm] = useState<FormType>(getFormType(history, param));
  const { query } = useSelector((state: any) => state.accountReducer);
  const { queryProvince } = useSelector((state: any) => state?.provinceReducer);
  const { queryDistrict } = useSelector((state: any) => state?.districtReducer);
  const { queryAward } = useSelector((state: any) => state?.awardReducer);
  const [courseOptions, setCourseOptions] = useState([]);
  const [paidDataCourse, setPaidDataCourse] = useState([]);
  const [newPass, setNewPass] = useState('');
  const [courseList, setCourseList] = useState([]);
  // const [listShoolById, setListShoolById] = useState<any>([]);
  const [schoolId, setSchoolId] = useState<any>('');
  // const [classroomId, setClassroomId] = useState<any>("");
  const [provinceOptions, setProvinceOptions] = useState<SelectOptionType[]>(
    []
  );
  const [districtOptions, setDistrictOptions] = useState<SelectOptionType[]>(
    []
  );
  const [awardOptions, setAwardOptions] = useState<SelectOptionType[]>([]);
  const [schoolOptions, setSchoolOptions] = useState<SelectOptionType[]>([]);
  const [classOptions, setClassOptions] = useState<SelectOptionType[]>([]);
  const [classNameData, setClassNameData] = useState<SelectOptionType[]>([]);
  const [provinceOptionV, setProvinceOptionV] = useState<SelectOptionType>();
  const [districtOptionV, setDistrictOptionV] = useState<SelectOptionType>();
  const [awardOptionV, setAwardOptionV] = useState<SelectOptionType>();
  const [schoolOptionV, setSchoolOptionV] = useState<SelectOptionType>();
  const [yearOptionV, setYearOptionV] = useState<any>('');
  const [userExists, setUserExists] = useState<any>({});

  const location = useLocation();

  //Lấy danh sách tỉnh

  function handleGetProvinceAddress() {
    const payload: PayloadType = {
      query: queryString.stringify(queryProvince),
      callback: {
        success(values) {
          if (_.isArray(values) && values.length > 0) {
            setProvinceOptions(
              values.map((item: ProviceType) => {
                return {
                  label: _.get(item, 'province_name', ''),
                  value: _.get(item, 'province_id', ''),
                };
              })
            );
          }
        },
        failed(errorMessage) {
          notification.error({
            message: 'Lấy danh sách tỉnh thất bại!',
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getProvinces(payload));
  }

  // Lấy danh sách huyện
  function handleGetDistrictAddress(provinceId: string | null) {
    const payload: PayloadType = {
      query: queryString.stringify({
        ...queryDistrict,
        province_id: provinceId,
      }),
      callback: {
        success(values) {
          if (_.isArray(values) && values.length > 0) {
            setDistrictOptions(
              values.map((item: DistrictType) => {
                return {
                  label: _.get(item, 'district_name', ''),
                  value: _.get(item, 'district_id', ''),
                };
              })
            );
          }
        },
        failed(errorMessage) {
          notification.error({
            message: 'Lấy danh sách Quận/Huyện thất bại!',
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getDistricts(payload));
  }

  // Lấy danh sách phường
  function handleGetAwardAddress(districtId: string | null) {
    const payload: PayloadType = {
      query: queryString.stringify({
        ...queryAward,
        district_id: districtId,
      }),
      callback: {
        success(values) {
          if (_.isArray(values) && values.length > 0) {
            setAwardOptions(
              values.map((item: AwardType) => {
                return {
                  label: _.get(item, 'ward_name', ''),
                  value: _.get(item, 'ward_id', ''),
                };
              })
            );
          }
        },
        failed(errorMessage) {
          notification.error({
            message: 'Lấy danh sách Xã/Phường thất bại!',
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getAwards(payload));
  }

  // Lấy danh sách trường
  function handleGetSchool(
    provinceId: string | null,
    districtId: string | null,
    awardId: string | null
  ) {
    const payload: PayloadType = {
      query: queryString.stringify({
        province: provinceId,
        district: districtId,
        award: awardId,
      }),
      callback: {
        success(values) {
          setSchoolOptions(
            values?.data.map((item: any) => {
              return {
                label: _.get(item, 'name', ''),
                value: _.get(item, 'id', ''),
              };
            })
          );
        },
        failed(errorMessage) {
          notification.error({
            message: 'Lấy danh sách trường thất bại!',
            description: errorMessage,
          });
        },
      },
    };
    console.log('url: /user/úuer');
    dispatch(getSchools(payload));
  }

  //Lấy  danh sách lớp học
  function handleGetClassroom(
    provinceId: string,
    districtId: string,
    awardId: string,
    schoolId: string,
    yearId: any
  ) {
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          province: provinceId,
          district: districtId,
          award: awardId,
          schoolId: schoolId,
          year: yearId,
        },
        {
          skipEmptyString: true,
          skipNull: true,
        }
      ),
      skipUpdateReducer: true,
      callback: {
        success(values) {
          setClassNameData(values);

          setClassOptions(
            _.get(values, 'data', []).map((course: any) => ({
              label: course?.className,
              value: course?.id,
            }))
          );
        },
        failed(errorMessage) {},
      },
    };
    dispatch(getClassrooms(payload));
  }

  function handleGetClassroomById(id: string) {
    const payload: PayloadType = {
      params: id,
      query: queryString.stringify({
        populate: 'schoolId,teacherId,courseId',
      }),
      callback: {
        success(values) {
          formUpdate.setFieldValue('province', values.schoolId.province);
          handleGetDistrictAddress(searchParams.get('province'));
          formUpdate.setFieldValue('district', values.schoolId.district);
          handleGetAwardAddress(searchParams.get('district'));
          formUpdate.setFieldValue('awards', values.schoolId.award);

          handleGetSchool(
            values.schoolId.province,
            values.schoolId.district,
            values.schoolId.award
          );
          handleGetClassroom(
            values.schoolId.province,
            values.schoolId.district,
            values.schoolId.award,
            values.schoolId.id,
            values.year
          );
          formUpdate.setFieldValue('classId', values.className);
          formUpdate.setFieldValue('schoolId', values.schoolId.name);
          formUpdate.setFieldValue('year', moment(values.year));
        },
        failed(errorMessage) {},
      },
    };
    dispatch(getClassroom(payload));
  }

  //Lấy danh sách khóa học
  function handleGetCourse() {
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          isVisible: true,
        },
        {
          skipEmptyString: true,
          skipNull: true,
        }
      ),
      skipUpdateReducer: true,
      callback: {
        success(values) {
          const options = _.get(values, 'data', []).map(
            (course: CourseType) => ({
              label: course.fullname,
              value: [course?.id ?? null, course?.moodleCourseId ?? null],
            })
          );
          setCourseOptions(options);
          handleGetAccount(options);
          setCourseList(_.get(values, 'data', []));
        },
        failed(errorMessage) {},
      },
    };
    dispatch(getCourses(payload));
  }

  useEffect(() => {
    handleGetProvinceAddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryProvince]);

  useEffect(() => {
    if (provinceOptionV) {
      handleGetDistrictAddress(_.get(provinceOptionV, 'value', ''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provinceOptionV]);

  useEffect(() => {
    if (districtOptionV)
      handleGetAwardAddress(_.get(districtOptionV, 'value', ''));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [districtOptionV, provinceOptionV]);

  useEffect(() => {
    if (awardOptionV)
      handleGetSchool(
        _.get(provinceOptionV, 'label', ''),
        _.get(districtOptionV, 'label', ''),
        _.get(awardOptionV, 'label', '')
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [districtOptionV, provinceOptionV, awardOptionV]);

  useEffect(() => {
    if (!_.isEmpty(schoolOptionV) && !_.isEmpty(yearOptionV))
      handleGetClassroom(
        _.get(provinceOptionV, 'value', ''),
        _.get(districtOptionV, 'value', ''),
        _.get(awardOptionV, 'value', ''),
        _.get(schoolOptionV, 'value', ''),
        formUpdate.getFieldValue('year')?.year()
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    districtOptionV,
    provinceOptionV,
    awardOptionV,
    schoolOptionV,
    yearOptionV,
  ]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const classroomIds = searchParams.get('classroomId');
    if (!_.isUndefined(classroomIds) && !_.isNull(classroomIds)) {
      const data = handleGetClassroomById(classroomIds);
    }
  }, [location.search]);

  useEffect(() => {
    handleGetCourse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  function handleGetAccount(options: any) {
    if (form.name === 'update') {
      const payload: PayloadType = {
        params: form?.id ?? '',
        callback: {
          success(account) {
            let paidData = _.get(account, 'paid_data', '[]');
            paidData = paidData ? JSON.parse(paidData) : [];
            //Lấy ra các khoá học có trong danh sách khoá học mặc định
            formUpdate.setFieldValue(
              'paid_data',
              options.map((option: any) => {
                const paid = _.reduce(
                  paidData,
                  (p: any, pI: any) => {
                    const isP = option.value.includes(
                      _.get(pI, 'course_id', '')
                    );
                    // Chưa tìm thấy khoá học
                    // Hoặc tìm thấy khoá học rồi nhưng time lớn hơn
                    if (
                      (isP && !p) ||
                      (isP && _.get(pI, 'time', -1) < p?.time)
                    ) {
                      p = pI;
                    }
                    return p;
                  },
                  null
                );

                const time = _.get(paid, 'time', -1);
                const name = _.get(option, 'label', '');
                const course_id = _.get(option, 'value[0]', '');

                formUpdate.setFieldValue(course_id, {
                  name,
                  time: time > 0 ? moment.unix(time) : undefined,
                });
                return {
                  course_id,
                  name,
                  time: time > 0 ? moment.unix(time) : undefined,
                };
              })
            );
            formUpdate.setFieldValue(
              'fullname',
              _.get(account, 'fullname', '')
            );
            formUpdate.setFieldValue(
              'parent_code',
              _.get(account, 'parent_code', '')
            );
            formUpdate.setFieldValue('gender', _.get(account, 'gender', ''));
            setPaidDataCourse(paidData);
            formUpdate.setFieldValue('phone', _.get(account, 'username', ''));
            const parsedData = JSON.parse(_.get(account, 'paid_data', ''));

            // Lấy giá trị thời gian từ đối tượng dữ liệu đã được phân tích
            const time = parsedData[0].time;
            formUpdate.setFieldValue('expirationDate', moment.unix(time));
          },
          failed(errorMessage) {},
        },
      };
      dispatch(getAccount(payload));
    }
  }
  function checkUserExitst(idUser: string) {
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          phone: idUser,
        },
        {
          skipEmptyString: true,
          skipNull: true,
        }
      ),
      skipUpdateReducer: true,
      callback: {
        success(values) {
          if (_.isEmpty(values.data)) {
            notification.error({
              message:
                'Số điện thoại của quý khách chưa tồn tại trên hệ thống. Vui lòng điền thông tin vào form',
            });
            setUserExists([]);
            formUpdate.setFieldValue('fullname', null);
            formUpdate.setFieldValue('password', null);
            formUpdate.setFieldValue('gender', null);
          } else {
            setUserExists(_.get(values, 'data[0]'));
            notification.success({
              message:
                'Số điện thoại của quý khách đã tồn tại trên hệ thống. Vui lòng kiểm tra lại thông tin của bạn',
            });
          }
        },
        failed(errorMessage) {},
      },
    };
    dispatch(getAccounts(payload));
  }
  function handleGetAccountByClassroom() {
    const query = {
      page: 1,
      limit: 1,
      accountId: form?.id ?? '',
      // schoolId: "6476f5a128f2d8e065fd826f",
      // classroomId: "6479b0827b7b8c52188085ec",
    };

    const payload: PayloadType = {
      query: queryString.stringify(query, {
        skipEmptyString: true,
        skipNull: true,
      }),
      callback: {
        success(values) {
          const data = _.get(values, 'data', []);
          formUpdate.setFieldValue('notes', data[0].note);
        },
        failed(errorMessage) {
          notification.error({
            message: 'Lấy danh sách tài khoản thất bại!',
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getClassroomAccounts(payload));
  }

  let accountUpdateSchema = useMemo(() => {
    if (form.name === 'create') {
      return yup.object().shape({
        fullname: yup.string().required('Tên học sinh không được để trống!'),
        province: yup.string().required('Tỉnh không được để trống!'),
        district: yup.string().required('Quận/huyện không được để trống!'),
        awards: yup.string().required('Phường/xã không được để trống!'),
        schoolId: yup.string().required('Trường không được để trống!'),
        classId: yup.string().required('Lớp không được để trống!'),
        gender: yup.string().required('Vui lòng chọn giới tính!'),
        expirationDate: yup.string().required('Vui lòng chọn ngày hết hạn!'),
        year: yup.string().required('Vui lòng chọn năm học!'),
        parent_code: yup
          .string()
          .required('Mã phụ huynh không được để trống!')
          .max(4, 'Mã phụ huynh phải nhỏ hơn hoặc bằng 4'),
        password: yup
          .string()
          .required('Mật khẩu không được để trống')
          .matches(/^[0-9]+$/, 'Mật khẩu chỉ bao gồm chữ số!')
          .min(8, 'Mật khẩu phải dài ít nhất 8 chữ số!')
          .nullable(),
        phone: yup
          .string()
          .required('Số điện thoại không được để trống!')
          .test(
            'check-phone',
            'Số điện thoại không đúng định dạng!',
            (phone) => {
              const reg = /0[1-9][0-9]{8}/g;
              return reg.test(phone) && phone.length === 10;
            }
          ),
      });
    } else {
      return yup.object().shape({
        fullname: yup.string().required('Tên học sinh không được để trống!'),
        province: yup.string().required('Tỉnh không được để trống!'),
        district: yup.string().required('Quận/huyện không được để trống!'),
        awards: yup.string().required('Phường/xã không được để trống!'),
        schoolId: yup.string().required('Trường không được để trống!'),
        classId: yup.string().required('Lớp không được để trống!'),
        gender: yup.string().required('Vui lòng chọn giới tính!'),
        expirationDate: yup.string().required('Vui lòng chọn ngày hết hạn!'),
        year: yup.string().required('Vui lòng chọn năm học!'),
        parent_code: yup
          .string()
          .required('Mã phụ huynh không được để trống!')
          .max(4, 'Mã phụ huynh phải nhỏ hơn hoặc bằng 4'),
        password: yup
          .string()
          .transform((value, originalValue) => {
            if (originalValue === '') {
              return null;
            }
            return value;
          })
          .matches(/^[0-9]+$/, 'Mật khẩu chỉ bao gồm chữ số!')
          .min(8, 'Mật khẩu phải dài ít nhất 8 chữ số!')
          .nullable(),
        phone: yup
          .string()
          .required('Số điện thoại không được để trống!')
          .test(
            'check-phone',
            'Số điện thoại không đúng định dạng!',
            (phone) => {
              const reg = /0[1-9][0-9]{8}/g;
              return reg.test(phone) && phone.length === 10;
            }
          ),
      });
    }
    return yup.object().shape({
      fullname: yup.string().required('Tên học sinh không được để trống!'),
      password: yup
        .string()
        .matches(/^[0-9]+$/, 'Mật khẩu chỉ bao gồm chữ số!')
        .min(8, 'Mật khẩu phải dài ít nhất 8 chữ số!')
        .nullable(),
      parent_code: yup
        .string()
        .required('Mã phụ huynh không được để trống!')
        .min(4, 'Mã phụ huynh phải dài ít nhất 4 chữ số!')
        .matches(/^[0-9]+$/, 'Mã phụ huynh chỉ bao gồm chữ số!'),
      gender: yup.string().required('Giới tính không được để trống!'),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPass, form.name]);
  const yupSync = useMemo(() => {
    return {
      async validator({ field }: any, value: any) {
        await accountUpdateSchema.validateSyncAt(field, { [field]: value });
      },
    };
  }, [accountUpdateSchema]);

  //reset lại pagination của danh sách
  function resetQuery() {
    dispatch(
      setQuery({
        ...query,
        page: 1,
        limit: 10,
      })
    );
  }
  function onSubmit(values: any) {
    if (form.name === 'create') {
      let bodyPost = {};
      if (_.isEmpty(userExists)) {
        const payload: PayloadType = {
          body: Object.assign(
            {
              fullname: values?.fullname,
              username: values?.phone,
              gender: parseInt(values?.gender),
              year: moment(values?.year).year(),
            },
            _.get(values, 'password', '') !== ''
              ? {
                  password: values.password,
                }
              : {}
          ),
          callback: {
            success(data) {
              bodyPost =
                searchParams.get('classroomId') !== 'undefined'
                  ? {
                      schoolId: schoolOptions.find(
                        (item) => item.label === values.schoolId
                      )?.value,
                      classroomId: classOptions.find(
                        (item) => item.label === values.classId
                      )?.value,
                      year: values?.year?.year()?.toString(),
                      accountId: _.get(data, 'id', ''),
                      note: _.get(values, 'notes', null),

                      expDate: moment(
                        _.get(values, 'expirationDate', '')
                      ).format('YYYY-MM-DD'),
                    }
                  : {
                      schoolId: _.get(values, 'schoolId', ''),
                      classroomId: _.get(values, 'classId', ''),
                      year: moment(values?.year).year().toString(),
                      accountId: _.get(data, 'id', ''),
                      note: _.get(values, 'notes', null),
                      expDate: moment(
                        _.get(values, 'expirationDate', '')
                      ).format('YYYY-MM-DD'),
                    };

              const payload: PayloadType = {
                body: Object.assign(bodyPost),
                callback: {
                  success(values) {
                    notification.success({
                      message:
                        'Thêm mới thông tin lớp học cho tài khoản học sinh thành công!',
                    });
                    resetQuery();
                    history.push(
                      `/admin/users?tabActive=3&schoolId=${searchParams.get(
                        'schoolId'
                      )}&classroomId=${searchParams.get(
                        'classroomId'
                      )}&year=${searchParams.get(
                        'year'
                      )}&province=${searchParams.get(
                        'province'
                      )}&district=${searchParams.get(
                        'district'
                      )}&award=${searchParams.get('award')}`
                    );
                  },
                  failed(errorMessage) {
                    notification.error({
                      message:
                        'Thêm mới thông tin lớp học cho tài khoản học sinh thất bại!',
                      description: errorMessage,
                    });
                  },
                },
              };

              dispatch(createAccount(payload));
            },
            failed(errorMessage) {
              notification.error({
                message: 'Thêm mới thông tin tài khoản người dùng thất bại!',
                description: errorMessage,
              });
            },
          },
        };

        dispatch(createUserAccount(payload));
        return;
      }

      if (searchParams.get('classroomId') !== 'undefined') {
        bodyPost = {
          schoolId: schoolOptions.find((item) => item.label === values.schoolId)
            ?.value,
          classroomId: classOptions.find(
            (item) => item.label === values.classId
          )?.value,
          year: values?.year?.year()?.toString(),
          accountId: !_.isEmpty(userExists) ? userExists?.id : '',
          note: _.get(values, 'notes', null),

          expDate: moment(_.get(values, 'expirationDate', '')).format(
            'YYYY-MM-DD'
          ),
        };
      } else {
        bodyPost = {
          schoolId: _.get(values, 'schoolId', ''),
          classroomId: _.get(values, 'classId', ''),
          year: moment(values?.year).year().toString(),
          accountId: !_.isEmpty(userExists) ? userExists?.id : '',
          note: _.get(values, 'notes', null),
          expDate: moment(_.get(values, 'expirationDate', '')).format(
            'YYYY-MM-DD'
          ),
        };
      }
      const payload: PayloadType = {
        body: Object.assign(bodyPost),
        callback: {
          success(values) {
            notification.success({
              message: 'Thêm mới thông tin tài khoản học sinh thành công!',
            });
            resetQuery();
            history.push(
              `/admin/users?tabActive=3&schoolId=${searchParams.get(
                'schoolId'
              )}&classroomId=${searchParams.get(
                'classroomId'
              )}&year=${searchParams.get('year')}&province=${searchParams.get(
                'province'
              )}&district=${searchParams.get(
                'district'
              )}&award=${searchParams.get('award')}`
            );
          },
          failed(errorMessage) {
            notification.error({
              message: 'Thêm mới thông tin tài khoản học sinh thất bại!',
              description: errorMessage,
            });
          },
        },
      };

      dispatch(createAccount(payload));
      return;
    } else {
      const newPaidData = paidDataCourse
        .map((paid: any) => {
          const courseId = _.get(paid, 'course_id', '');
          //Trường hợp courseId cách nhau bằng dấu phẩy.
          if (courseId.includes(',')) {
            return null;
          }
          const courseOption = _.find(courseOptions, (course: any) => {
            return course.value.includes(courseId);
          });
          const courseOptionId = _.get(courseOption, 'value[0]', '');
          const time = _.get(values, `[${courseOptionId}].time`, -1);

          return {
            // ...paid,
            course_id: courseOptionId,
            time: time > 0 ? moment(time).unix() : null,
          };
        })
        .filter((course: any) => !!_.get(course, 'time', ''));

      //Xử lý nhưng course có ở ds khoá học nhưng
      //không có trong paid_data.
      courseList.map((course: any) => {
        const hasCourse = _.some(newPaidData, {
          course_id: _.get(course, 'id', ''),
        });
        const time = _.get(values, `${course?.id ?? ''}.time`, -1);
        if (!hasCourse && time > 0) {
          newPaidData.push({
            course_id: course?.id ?? '',
            time: moment(time).unix(),
          });
        }
      });
      const fullname = _.get(values, 'fullname', '');
      const password = _.get(values, 'password', '');
      const body: any = {
        firstname: _.get(fullname.split(' '), '[0]', ''),
        lastname: _.get(fullname.split(' '), '[1]', ''),
        fullname,
        parent_code: _.get(values, 'parent_code', ''),
        gender: _.get(values, 'gender', ''),
      };
      const bodyPost: any = {
        year: moment(values?.year).year().toString(),
        note: _.get(values, 'notes', null),
        expDate: moment(_.get(values, 'expirationDate', '')).format(
          'YYYY-MM-DD'
        ),
      };
      if (!!password) {
        body['password'] = password;
      }
      if (!body.firstname) {
        delete body['firstname'];
      }
      if (!body.lastname) {
        delete body['lastname'];
      }
      const payloadClassAccount: PayloadType = {
        params: searchParams.get('classroomAccountId') || '',
        body: bodyPost,
        callback: {
          success(values) {
            resetQuery();
            history.push(
              `/admin/users?tabActive=3&schoolId=${searchParams.get(
                'schoolId'
              )}&classroomId=${searchParams.get(
                'classroomId'
              )}&year=${searchParams.get('year')}&province=${searchParams.get(
                'province'
              )}&district=${searchParams.get(
                'district'
              )}&award=${searchParams.get('award')}`
            );
          },
          failed(errorMessage) {
            notification.error({
              message:
                'Cập nhật thông tin lớp học theo tài khoản học sinh thất bại!',
              description: errorMessage,
            });
          },
        },
      };
      const payload: PayloadType = {
        params: form?.id ?? '',
        body,
        callback: {
          success(values) {
            notification.success({
              message: 'Cập nhật thông tin tài khoản học sinh thành công!',
            });
            resetQuery();
            history.push(
              `/admin/users?tabActive=3&schoolId=${searchParams.get(
                'schoolId'
              )}&classroomId=${searchParams.get(
                'classroomId'
              )}&year=${searchParams.get('year')}&province=${searchParams.get(
                'province'
              )}&district=${searchParams.get(
                'district'
              )}&award=${searchParams.get('award')}`
            );
          },
          failed(errorMessage) {
            notification.error({
              message: 'Cập nhật thông tin tài khoản học sinh thất bại!',
              description: errorMessage,
            });
          },
        },
      };
      dispatch(updateClassroomAccount(payloadClassAccount));
      dispatch(updateAccount(payload));
    }
  }

  useEffect(() => {
    if (form.name === 'update') {
      formUpdate.setFieldValue(
        'province',
        _.get(classNameData, 'data[0].schoolId.province', '')
      );
    }
  }, []);

  useEffect(() => {
    if (userExists && !_.isEmpty(userExists)) {
      formUpdate.setFieldValue('fullname', _.get(userExists, 'fullname', ''));
      formUpdate.setFieldValue('gender', _.get(userExists, 'gender', ''));
      formUpdate.setFieldValue('password', '0000000000');
    }
  }, [userExists]);

  useEffect(() => {
    formUpdate.setFieldValue('year', moment().startOf('year'));
    handleGetAccountByClassroom();
  }, []);

  return (
    <Content className="custom-layout-content">
      <HeaderbackTitle
        title={
          form.name === 'create'
            ? 'Thêm mới tài khoản học sinh'
            : 'Sửa tài khoản học sinh'
        }
        handleBack={() => {}}
        link={`/admin/users?tabActive=3&schoolId=${searchParams.get(
          'schoolId'
        )}&classroomId=${searchParams.get(
          'classroomId'
        )}&year=${searchParams.get('year')}&province=${searchParams.get(
          'province'
        )}&district=${searchParams.get('district')}&award=${searchParams.get(
          'award'
        )}`}
      />
      <Form
        colon={false}
        form={formUpdate}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 10 }}
        onFinish={(values) => {
          onSubmit(values);
        }}
        initialValues={{}}
        onFinishFailed={() => {}}
        autoComplete={'off'}
        layout="horizontal"
        style={{
          marginTop: 64,
        }}
      >
        <Form.Item
          label="Số điện thoại"
          name="phone"
          required
          labelAlign="right"
          rules={[yupSync]}
        >
          <Input
            disabled={form.name == 'create' ? false : true}
            placeholder="Nhập số điện thoại"
          />
        </Form.Item>
        {form.name == 'create' ? (
          <div
            style={{
              textAlign: 'center',
              marginBottom: '20px',
              marginLeft: '50px',
            }}
          >
            <i>Vui lòng kiểm tra SDT đã tồn tại trên hệ thống hay chưa</i>
            <Button
              type="primary"
              size="middle"
              style={{ marginLeft: '20px' }}
              onClick={async () => {
                const dataExists = await checkUserExitst(
                  formUpdate.getFieldValue('phone')
                );
              }}
            >
              {' Tại đây'}
            </Button>
          </div>
        ) : (
          <div />
        )}
        <Form.Item
          label="Tên học sinh"
          name="fullname"
          required
          labelAlign="right"
          rules={[yupSync]}
        >
          <Input
            placeholder="Nhập tên học sinh"
            disabled={!_.isEmpty(userExists) ? true : false}
          />
        </Form.Item>

        <Form.Item
          label="Tỉnh"
          name="province"
          required
          labelAlign="right"
          rules={[yupSync]}
        >
          <Select
            filterOption={(input, option) => {
              const label: string = option?.label ?? '';
              return label.toLowerCase().includes(input.toLowerCase());
            }}
            showSearch
            options={provinceOptions}
            placeholder="Chọn tỉnh"
            allowClear
            onChange={(values, option: any) => {
              setProvinceOptionV(option);
              formUpdate.setFieldValue('district', null);
              formUpdate.setFieldValue('awards', null);
              formUpdate.setFieldValue('schoolId', null);
              formUpdate.setFieldValue('classId', null);
            }}
            disabled={form.name == 'create' ? false : true}
          />
        </Form.Item>

        <Form.Item
          label="Quận/huyện"
          name="district"
          required
          labelAlign="right"
          rules={[yupSync]}
        >
          <Select
            showSearch
            filterOption={(input, option) => {
              const label: string = option?.label ?? '';
              return label.toLowerCase().includes(input.toLowerCase());
            }}
            onChange={(values, option: any) => {
              setDistrictOptionV(option);
              formUpdate.setFieldValue('award', null);
            }}
            options={districtOptions}
            placeholder="Chọn quận/huyện"
            allowClear
            disabled={_.isEmpty(provinceOptionV) ? true : false}
          />
        </Form.Item>

        <Form.Item
          label="Xã/phường"
          name="awards"
          required
          labelAlign="right"
          rules={[yupSync]}
        >
          <Select
            showSearch
            filterOption={(input, option) => {
              const label: string = option?.label ?? '';
              return label.toLowerCase().includes(input.toLowerCase());
            }}
            options={awardOptions}
            onChange={(values, option: any) => {
              setAwardOptionV(option);
              formUpdate.setFieldValue('schoolId', null);
            }}
            placeholder="Chọn xã/phường"
            allowClear
            disabled={_.isEmpty(districtOptionV) ? true : false}
          />
        </Form.Item>

        <Form.Item
          label="Trường"
          name="schoolId"
          required
          labelAlign="right"
          rules={[yupSync]}
        >
          <Select
            showSearch
            filterOption={(input, option) => {
              const label: string = option?.label ?? '';
              return label.toLowerCase().includes(input.toLowerCase());
            }}
            options={schoolOptions}
            onChange={(values, option: any) => {
              handleGetClassroom(
                formUpdate.getFieldValue('province'),
                formUpdate.getFieldValue('district'),
                formUpdate.getFieldValue('awards'),
                values,
                moment(formUpdate.getFieldValue('year')).year()
              );
              setSchoolOptionV(option);
              formUpdate.setFieldValue('classId', null);
            }}
            placeholder="Chọn trường"
            allowClear
            disabled={_.isEmpty(awardOptionV) ? true : false}
          />
        </Form.Item>

        {/* {_.isArray(formUpdate.getFieldValue("paid_data")) &&
          formUpdate.getFieldValue("paid_data").map((paid: any) => {
            const course_id = _.get(paid, "course_id", "");

            return (
              <div>
                {paid.time !== undefined ? (
                  <Form.Item
                    label={<span></span>}
                    wrapperCol={{
                      span: 10,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        position: "relative",
                      }}
                    >
                      <label
                        style={{
                          position: "absolute",
                          right: "100%",
                          top: "20%",
                          verticalAlign: "center",
                          minWidth: "100px",
                          marginBottom: 0,
                          paddingRight: 8,
                          textAlign: "end",
                        }}
                      >
                        Khoá học{" "}
                        <span
                          style={{
                            color: "red",
                          }}
                        >
                          *
                        </span>
                      </label>
                      <Form.Item
                        noStyle
                        name={[course_id, "name"]}
                        label="Khoá học"
                        required
                        labelAlign="right"
                        style={{
                          display: "inline-block",
                          // width: "calc(50% - 8px)",
                        }}
                      >
                        <Input disabled placeholder="Nhập khoá học" />
                      </Form.Item>
                      <label
                        style={{
                          minWidth: "100px",
                          marginBottom: 0,
                          paddingRight: 4,
                          textAlign: "end",
                        }}
                      >
                        Ngày hết hạn{" "}
                        <span
                          style={{
                            color: "red",
                          }}
                        >
                          *
                        </span>
                      </label>
                      <Form.Item
                        noStyle
                        name={[course_id, "time"]}
                        required
                        labelAlign="right"
                        style={{
                          display: "inline-block",
                          // width: "calc(50% - 8px)",
                          margin: "0 16px",
                        }}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          disabled={false}
                          // disabledDate={disabledDate}
                          format={"DD/MM/YYYY"}
                          placeholder="Chọn ngày hết hạn"
                        />
                      </Form.Item>
                    </div>
                  </Form.Item>
                ) : (
                  <></>
                )}
              </div>
            );
          })} */}

        <Form.Item
          label="Năm học"
          name="year"
          required
          labelAlign="right"
          rules={[yupSync]}
        >
          <DatePicker
            placeholder={'Năm'}
            style={{ width: '100%' }}
            size="middle"
            disabled={_.isEmpty(schoolOptionV) ? true : false}
            picker="year"
            allowClear={false}
            onChange={(values: any, option: any) => {
              setYearOptionV(values);
              handleGetClassroom(
                _.get(provinceOptionV, 'label', ''),
                _.get(districtOptionV, 'label', ''),
                _.get(awardOptionV, 'label', ''),
                _.get(schoolId, 'label', ''),
                formUpdate.getFieldValue('year')?.year()
              );
              formUpdate.setFieldValue('classId', null);
            }}
          />
        </Form.Item>

        <Form.Item
          label={<span></span>}
          wrapperCol={{
            span: 10,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              position: 'relative',
            }}
          >
            <label
              style={{
                position: 'absolute',
                right: '100%',
                top: '20%',
                verticalAlign: 'center',
                minWidth: '100px',
                marginBottom: 0,
                paddingRight: 8,
                textAlign: 'end',
              }}
            >
              Lớp{' '}
              <span
                style={{
                  color: 'red',
                }}
              >
                *
              </span>
            </label>
            <Form.Item
              noStyle
              name={'classId'}
              required
              labelAlign="right"
              style={{
                display: 'block',
                width: '50%',
              }}
              rules={[yupSync]}
            >
              <Select
                placeholder="Chọn lớp"
                options={classOptions}
                style={{ width: '40%' }}
                disabled={_.isEmpty(schoolOptionV) ? true : false}
              />
            </Form.Item>
            <label
              style={{
                minWidth: '100px',
                marginBottom: 0,
                paddingRight: 4,
                textAlign: 'end',
              }}
            >
              Ngày hết hạn{' '}
            </label>
            <Form.Item
              noStyle
              name={'expirationDate'}
              labelAlign="right"
              style={{
                display: 'block',
                width: '50%',
              }}
              rules={[yupSync]}
            >
              <DatePicker
                placeholder="Chọn ngày hết hạn"
                style={{ width: '40%' }}
              />
            </Form.Item>
          </div>
        </Form.Item>

        <Form.Item
          label="Mật khẩu "
          required={form.name == 'create' ? true : false}
          name="password"
          labelAlign="right"
          rules={[yupSync]}
        >
          <Input.Password
            placeholder={
              form.name == 'create'
                ? 'Nhập mật khẩu '
                : 'Bỏ qua nếu không muốn cập nhật mật khẩu mới'
            }
            disabled={!_.isEmpty(userExists) ? true : false}
          />
        </Form.Item>

        <Fragment>
          {form.name === 'update' && (
            <Form.Item
              required
              label="Mã phụ huynh"
              name="parent_code"
              labelAlign="right"
              rules={[yupSync]}
            >
              <Input placeholder="Nhập mã phụ huynh" />
            </Form.Item>
          )}
          <Form.Item name="gender" label="Giới tính" required rules={[yupSync]}>
            <Radio.Group disabled={!_.isEmpty(userExists) ? true : false}>
              <Radio value="0">Nam</Radio>
              <Radio value="1">Nữ</Radio>
              <Radio value="-1">Khác</Radio>
            </Radio.Group>
          </Form.Item>
        </Fragment>

        <Form.Item label="Ghi chú" name="notes">
          <TextArea
            showCount={true}
            maxLength={100}
            allowClear
            placeholder="Nhập ghi chú"
          />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 8,
          }}
        >
          <Space>
            <Button
              type="default"
              size="middle"
              onClick={() => {
                history.push(
                  `/admin/users?tabActive=3&schoolId=${searchParams.get(
                    'schoolId'
                  )}&classroomId=${searchParams.get(
                    'classroomId'
                  )}&year=${searchParams.get(
                    'year'
                  )}&province=${searchParams.get(
                    'province'
                  )}&district=${searchParams.get(
                    'district'
                  )}&award=${searchParams.get('award')}`
                );
              }}
            >
              Hủy
            </Button>
            <Button type="primary" size="middle" htmlType="submit">
              {form.name === 'create' ? 'Thêm mới' : 'Lưu lại'}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Content>
  );
}

export default memo(UUser);
