/**
 * Màn hình Thông tin tài khoản > Thông tin tài khoản
 */
import { useEffect, useState } from "react";
import {
  Avatar,
  Col,
  Image,
  Row,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import _ from "lodash";
import moment from "moment";
import CustomLabelValue from "pages/components/CustomLabelValue";
const { Title } = Typography;
const UserAvatarDefault = require("assets/img/user-avatar-default.jpg");
function AccountInfo() {
  const info = localStorage.getItem("accountInfo");
  const accountInfo = !!info ? JSON.parse(info) : null;
  // function handleGetUser(id: string) {
  //   const payload: PayloadType = {
  //     params: id,
  //     body: {},
  //     callback: {
  //       success(values) {},
  //       failed(errorMessage) {
  //         notification.error({
  //           message: "Lấy thông tin người dùng thất bại!",
  //           description: errorMessage,
  //         });
  //       },
  //     },
  //   };
  //   dispatch(getUser(payload));
  // }
  // useEffect(()=>{

  // },[])
  const [avatarUser, setAvatarUser] = useState<string | null>(null);
  useEffect(() => {
    if (!!accountInfo) {
      if (Object.keys(accountInfo).includes("avatar"))
        setAvatarUser(
          `${process.env.REACT_APP_BASE_URL_IMAGE}/${accountInfo?.avatar ?? ""}`
        );
    }
  }, [accountInfo]);
  return (
    <Content className="custom-layout-content">
      <Row>
        <Col span={24}>
          <Title level={4}>Thông tin tài khoản</Title>
        </Col>
      </Row>
      <Row
        style={{
          paddingTop: 26,
          paddingLeft: 26,
          width: "100%",
        }}
      >
        <Col span={12}>
          <CustomLabelValue
            label={"Tên tài khoản"}
            value={_.get(accountInfo, "name", "")}
            alignX={120}
            isLabelStart={true}
          />
          <CustomLabelValue
            label={"Giới tính"}
            value={
              _.get(accountInfo, "gender", "-1") === "0"
                ? "Nữ"
                : _.get(accountInfo, "gender", "-1") === "1"
                ? "Nam"
                : "Khác"
            }
            alignX={120}
          />
          <CustomLabelValue
            label={"Ngày sinh"}
            value={
              !!_.get(accountInfo, "dateOfBirth", false)
                ? moment(_.get(accountInfo, "dateOfBirth", "")).format(
                    "DD/MM/YYYY"
                  )
                : ""
            }
            alignX={120}
          />
          <CustomLabelValue
            label={"Email"}
            value={_.get(accountInfo, "email", "")}
            alignX={120}
          />
          <CustomLabelValue
            label={"Số điện thoại"}
            value={_.get(accountInfo, "phoneNumber", "")}
            alignX={120}
          />
          <CustomLabelValue
            label={"Địa chỉ"}
            value={_.get(accountInfo, "address", "")}
            alignX={120}
            isLabelEnd={true}
          />
        </Col>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Avatar src={<Image src={!!avatarUser ? avatarUser :UserAvatarDefault} />} size={250} />
        </Col>
      </Row>
      {/* <div>
        <Button
          type="primary"
          size="large"
          style={{
            position: "absolute",
            top: 26,
            right: 26,
          }}
        >
          Chỉnh sửa
        </Button>
      </div> */}
    </Content>
  );
}

export default AccountInfo;
