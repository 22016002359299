/**
 * Màn hình danh sách người dùng
 */
import React, { Fragment, memo, useEffect, useRef, useState } from "react";
import { Button, Input, Layout, Table, Typography, notification } from "antd";
import { Space } from "antd";
import type { ColumnType } from "antd/es/table";
import { SearchOutlined } from "@ant-design/icons";

import type { ColumnsType } from "antd/es/table";
import { useSelector } from "react-redux";
import _ from "lodash";

import type { InputRef } from "antd";

import { useHistory, useLocation } from "react-router";
import moment from "moment";
import { useDispatch } from "react-redux";
import { setQuery, getAccounts } from "features/accountSlice";
import { CourseType, PayloadType } from "type";
import { getCourses } from "features/courseSlice";
import queryString from "query-string";
import { FilterConfirmProps } from "antd/lib/table/interface";
const Highlighter = require("react-highlight-words");

const { Title } = Typography;
const { Content } = Layout;

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const { Text } = Typography;
function UStudent() {
  const { filters, query, accounts } = useSelector(
    (state: any) => state.accountReducer
  );
  const history = useHistory();
  const [searchText, setSearchText] = useState("");

  const dispatch = useDispatch();

  const searchInput = useRef<InputRef>(null);
  const [searchedColumn, setSearchedColumn] = useState("");

  const [tableKey, setTableKey] = useState(0);
  const [courseOptions, setCourseOptions] = useState([]);
  interface DataType {
    code: string;
    username: string;
    fullname: string;
    gender: string;
    personage: string;
    diamond: string;
    courseid: string;
    membership: string;
    parent_code: string;
    paid_expired: string;
    confirmed: string;
    avatar: string;
    createdAt: string;
    updatedAt: string;
    email: string;
    belongToSchool: boolean;
    id: string;
  }
  type DataIndex = keyof DataType;

  //Các tabs

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  function handleGetCourse() {
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          isVisible: true,
        },
        {
          skipEmptyString: true,
          skipNull: true,
        }
      ),
      skipUpdateReducer: true,
      callback: {
        success(values) {
          setCourseOptions(
            _.get(values, "data", []).map((course: CourseType) => ({
              label: course.fullname,
              value: [course?.moodleCourseId ?? null, course?.id ?? null],
            }))
          );
        },
        failed(errorMessage) {},
      },
    };
    dispatch(getCourses(payload));
  }

  useEffect(() => {
    getAccountsOtherGroup({});
  }, [query]);

  function getAccountsOtherGroup(otherQuery: any = {}) {
    handleGetCourse();
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          ...query,
          year: "",
          ...otherQuery,
        },
        {
          skipEmptyString: true,
          skipNull: true,
        }
      ),
      skipUpdateReducer: false,
      callback: {
        success(values) {},
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getAccounts(payload));
  }

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    let newQuery = {};
    switch (dataIndex) {
      case "fullname":
        newQuery = { fullname: selectedKeys[0] };
        break;
      case "username":
        newQuery = { phoneOrName: selectedKeys[0] };
        break;
      default:
        break;
    }
    dispatch(
      setQuery({
        ...query,
        ...newQuery,
        page: 1,
      })
    );
    setSearchedColumn(dataIndex);
  };

  const getPlaceholder = (key: DataIndex) => {
    if (key === "fullname") return "tên học sinh";
    if (key === "username") return "số điện thoại";
    return "";
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Tìm kiếm theo ${getPlaceholder(dataIndex)}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Đặt lại
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns: ColumnsType<DataType> = [
    {
      title: "Tên học sinh ",
      width: 150,
      dataIndex: "fullname",
      key: "fullname",
      fixed: "left",
      ...getColumnSearchProps("fullname"),
      render(value, record, index) {
        return (
          <span
            className="hover-decoration"
            style={{
              color: "rgb(24, 144, 255)",
              cursor: "pointer",
            }}
            onClick={() => {
              history.push(`/admin/student-update/${_.get(record, "id", "")}`);
            }}
          >
            {value}
          </span>
        );
      },
    },
    {
      title: "Số điện thoại",
      width: 100,
      dataIndex: "username",
      key: "username",
      fixed: "left",
      ...getColumnSearchProps("username"),
      render: (val, record) => {
        return (
          <>{!!val ? val.replace(/(\d{4})(\d{3})(\d{3})/, "$1 $2 $3") : ""}</>
        );
      },
    },
    {
      title: "Ngày tạo",
      width: 200,
      dataIndex: "createdAt",
      key: "createdAt",
      fixed: "left",
      render(value, record, index) {
        return moment(value).format("DD/MM/YYYY");
      },
    },
    {
      title: "Lớp",
      width: 200,
      dataIndex: "paid_data",
      key: "paid_data",
      fixed: "left",
      render(value, record, index) {
        const paid_data = _.isString(value) ? JSON.parse(value) : null;
        if (_.isArray(paid_data)) {
          return paid_data.map((paidData) => {
            try {
              const courseIds = _.get(paidData, "course_id", "");
              const courses = _.isString(courseIds)
                ? (courseIds.split(",") as string[]).map((courseId: string) => {
                    return _.find(courseOptions, (courseOption) => {
                      return (
                        _.get(courseOption, "value", []) as string[]
                      ).includes(courseId);
                    });
                  })
                : [];
              const exDate = _.get(paidData, "time", "");
              return (
                <div>
                  <Text strong>
                    {courses
                      .map((course) => _.get(course, "label", ""))
                      .join(",")}{" "}
                  </Text>
                  (
                  <Text type="secondary">
                    Hết hạn:{" "}
                    {exDate > 0 ? moment.unix(exDate).format("DD/MM/YYYY") : ""}{" "}
                  </Text>
                  ) <br />
                </div>
              );
            } catch (error) {
              console.error(error);
              return <Fragment></Fragment>;
            }
          });
        }
        return "";
      },
    },
  ];

  return (
    <Content className="custom-layout-content">
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
        size={"large"}
      >
        <div className="space-between">
          <Title level={4}>Tài khoản người dùng</Title>
        </div>

        <Table
          key={tableKey}
          columns={columns}
          dataSource={_.get(accounts, "data", [])}
          pagination={{
            current: _.get(accounts, "page", 1),
            total: _.get(accounts, "totalResults", 0),
            showTotal: (total, range) => {
              return `Có tất cả ${total} bản ghi`;
            },
            onChange(page, pageSize) {
              dispatch(
                setQuery({
                  ...query,
                  page: page,
                  limit: pageSize,
                })
              );
            },
          }}
          scroll={{ x: 1300, y: 900 }}
        />
      </Space>
    </Content>
  );
}

export default memo(UStudent);
