/**
 * Màn hình danh sách người dùng
 */
import React, { memo, useEffect, useState } from "react";
import { Button, Layout, Tabs, TabsProps, Typography } from "antd";
import { Space } from "antd";
import OrganizationGroup from "pages/User/OrganizationGroup";
import OtherGroup from "pages/User/OtherGroup";
import ImportListUser from "pages/User/ImportListUser";
import queryString from "query-string";
import axios, { AxiosRequestConfig } from "axios";
import { useSelector } from "react-redux";
import _ from "lodash";
import AllUser from "pages/User/AllUser";
import { useHistory, useLocation } from "react-router";
const { Title } = Typography;
const { Content } = Layout;
var fileDownload = require("js-file-download");
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
function User() {
  const { filters, isTeacher, isAdmin } = useSelector(
    (state: any) => state.accountReducer
  );
  const history = useHistory();
  const location = useLocation();
  const searchParams = useQuery();
  const isDisabledExport = !filters?.classroom;
  const [display, setDisplay] = useState<"list" | "import">("list");
  const [tabActive, setTabActive] = useState<"1" | "2" | "3">(
    isTeacher ? "2" : "3"
  );
  //Các tabs
  const itemsTabList: TabsProps["items"] = [
    {
      key: "3",
      label: `Tất cả`,
      children: <AllUser tabActive={tabActive} />,
    },
    {
      key: "1",
      label: `Nhóm khác`,
      children: <OtherGroup tabActive={tabActive} />,
    },
    {
      key: "2",
      label: `Nhóm tổ chức`,
      children: <OrganizationGroup tabActive={tabActive} />,
    },
  ];

  function handleExportOrganizationGroupUser() {
    const tokens = JSON.parse(localStorage.getItem("tokens") || "");
    console.log("filter", filters)
    const query = {
      year: _.get(filters, "year", 2023),
      page: _.get(filters, "page", 1),
      limit: _.get(filters, "limit", 10),
      schoolId: _.get(filters, "school.value", filters?.school),
      classroomId: _.get(filters, "classroom.value", filters?.classroom),
    };

    let config: AxiosRequestConfig = {
      method: "GET",
      url: `${
        process.env.REACT_APP_BASE_URL
      }classroomAccounts/exportClassroomAccount?${queryString.stringify(query, {
        skipEmptyString: true,
        skipNull: true,
      })}`,
      headers: {
        Authorization: `Bearer ${tokens?.access?.token}`,
      },
      responseType: "blob",
    };

    axios
      .request(config)
      .then((response: any) => {
        fileDownload(response.data, "filename.xlsx");
      })
      .catch((error: any) => {});
  }
  useEffect(() => {
    if (isTeacher) {
      setTabActive("2");
    }
  }, [isTeacher]);
  useEffect(() => {
    if (searchParams.get("tabActive") == "3") {
      setTabActive("2");
    }
  }, []);
  if (display === "import") {
    return (
      <ImportListUser setDisplay={setDisplay} setTabActive={setTabActive} />
    );
  }
  const removeAllSearchParams = () => {
    history.replace({
        pathname: location.pathname,
        search: '',
    })
}


  return (
    <Content className="custom-layout-content">
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
        size={"large"}
      >
        <div className="space-between">
          <Title level={4}>Tài khoản học sinh</Title>
          {tabActive === "2" ? (
            <Space direction="horizontal" size={"middle"}>
              {!isTeacher && (
                <Button
                  onClick={() => {
                    setDisplay("import");
                  }}
                  size="large"
                >
                  Nhập danh sách
                </Button>
              )}
              <Button
                disabled={isDisabledExport}
                onClick={() => {
                  handleExportOrganizationGroupUser();
                }}
                size="large"
              >
                Xuất danh sách
              </Button>
            </Space>
          ) : (
            ""
          )}
        </div>
        <Tabs
          key={"tab-organization"}
          id="tab-organization"
          tabBarStyle={{
            margin: 0,
            display: !isAdmin ? "none" : undefined,
          }}
          activeKey={tabActive}
          onChange={(keyActive) => {
            if (keyActive === "1" || keyActive === "2" || keyActive === "3")
              setTabActive(keyActive);
            removeAllSearchParams(); 
          }}
          items={itemsTabList}
        />
      </Space>
    </Content>
  );
}

export default memo(User);
