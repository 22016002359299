import { createSlice } from "@reduxjs/toolkit";
import { DEFAULT_PROVICE_FILTER_ID } from "const";
import _ from "lodash";
const initialState : any = {
  filters: null,
}
export const userScreenSlice = createSlice({
  name: "userScreenSlice",
  initialState: initialState,
  reducers: {
    createuserScreen: (state, action) => {
      state.userScreen = action.payload;
    },
    setFilter: (state, action) => {
      state.filters = action.payload;
    },
    resetFilter: (state, action) => {
      state.filters = {
        province_id : DEFAULT_PROVICE_FILTER_ID
      }
    },
  },
});
export const {
  setFilter,
  createuserScreen,
  resetFilter
} = userScreenSlice.actions;
export default userScreenSlice.reducer;
