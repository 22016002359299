import { Typography } from "antd";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
const { Title } = Typography;
interface HeaderbackTitleType {
  handleBack: () => void;
  title: string;
  link: string;
}
function HeaderbackTitle({ handleBack, title,link }: HeaderbackTitleType) {
  return (
    <Title level={4}>
      <Link
        to={link}
        onClick={() => {
          handleBack();
        }}
      >
        <ArrowLeftOutlined
          style={{ marginRight: 12, color: "black" }}
          size={13}
        />
      </Link>
      {title}
    </Title>
  );
}

export default HeaderbackTitle;
