interface CustomLabelValueType {
  label: string;
  value: string;
  alignX?: number;
  isLabelStart?: boolean;
  isLabelEnd?: boolean;
  color?: string; 
  fontW?: string; 
}
function CustomLabelValue({
  fontW, 
  color, 
  label,
  value,
  alignX,
  isLabelStart = false,
  isLabelEnd = false,
}: CustomLabelValueType) {
  return (
    <div
      style={{
        display: 'flex',
        marginTop: isLabelStart ? 0 : 28,
        marginBottom: isLabelEnd ? 0 : 28,
      }}
    >
      <label
        style={{
          display: "inline-block",
          minWidth: alignX ? `${alignX}px` : undefined,
          textAlign: "left",
        }}
      >
        {label}
      </label>
      <span>:</span>
      <span
        style={{
          display: "inline-block",
          marginLeft: alignX ? alignX / 2 : undefined,
          color: color? color:  "#000", 
          fontWeight: fontW ? fontW : "400"
        }}
      >
        {value}
      </span>
    </div>
  );
}

export default CustomLabelValue;
